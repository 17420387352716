export default {
	setPageready: 'SET_PAGE_READY',
	setEmployeeData: 'SET_EMPLOYEE_DATA',
	setCandidateData: 'SET_CANDIDATE_DATA',
	showLoader: 'SHOW_LOADER',
	setEmployeeId: 'SET_EMPLOYEE_ID',
	setSeniority: 'SET_SENIORITY',
	setDocuments: 'SET_DOCUMENTS',
	setResults: 'SET_RESULTS'
};

import React from 'react';
import {Popover, OverlayTrigger, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faComment} from '@fortawesome/free-solid-svg-icons';
import './observation.css';

const Observations = ({observation, variant = 'success', type = 'Trabajador'}) => {
	const popover = (
		<Popover id="popover-basic">
			<Popover.Title as="h3">Antecedentes del {type}</Popover.Title>
			<Popover.Content>
				<span className="observation-span">{observation}</span>
			</Popover.Content>
		</Popover>
	);

	if (observation !== '' && observation !== null) {
		return (
			<OverlayTrigger trigger={['hover', 'focus']} placement="auto" flip overlay={popover}>
				<Button
					title={`Antecedentes del ${type}`}
					className="observation-button"
					variant={variant}
					size="sm">
					<FontAwesomeIcon color="white" icon={faComment}/>
				</Button>
			</OverlayTrigger>
		);
	} else {
		return (
			<div>

			</div>
		);
	}
};

export default Observations;

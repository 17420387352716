import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	capitalize: {
		textTransform: 'capitalize'
	},
	rootContainer: {
		minHeight: '60vh',
		padding: 20
	},
	cardContent: {
		minHeight: 350
	},
	files: {
		width: '69%',
		margin: '0 auto',
		marginTop: 5,
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.3rem',
			width: '91%',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.3rem',
			width: '93%',
		},
		[theme.breakpoints.up('lg')]: {
			width: '53%',
		}
	},
	card: {
		'& .MuiCardHeader-root': {
			position: 'relative',
			borderBottom: '2px solid #ebebeb',
			backgroundColor: '#e0deda'
		},
		'& .MuiCardHeader-avatar': {
			position: 'absolute',
			right: 0
		},
		'& .MuiCardHeader-title': {
			fontSize: '1.5rem',
			fontWeight: '600'
		},
		width: '80%',
		margin: '0 auto',
		marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			width: '95%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '95%',
		}
	},
	labelKey: {
		fontSize: '1.0rem',
		display: 'inline-block',
		margin: 5,
		[theme.breakpoints.down('xs')]: {
			fontSize: '0.9rem'
		}
	},
	labelValue: {
		fontSize: '1.0rem',
		display: 'inline-block',
		[theme.breakpoints.down('xs')]: {
			fontSize: '0.9rem'
		}
	},
	headerTitle: {
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.1rem'
		}
	},
	footer: {
		padding: 50,
		backgroundColor: '#ece376',
		[theme.breakpoints.down('xs')]: {
			padding: 5,
		}
	}
}));

export default useStyles;

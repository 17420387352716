import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute/PrivateRoute';

import Employee from 'pages/Employee/Employee';
import history from '../history';
import Candidates from 'pages/Candidate/Candidate';
import User from 'pages/User';
import Documents from 'pages/Document';
import Results from 'pages/Results';
import Requirement from 'pages/Requirement/Requirement';
import Guest from 'pages/Guest/Guest';
import JobPositions from 'pages/JobPositions';
import Emails from 'pages/Emails';
import LayoutGuest from 'components/LayoutGuest';
import Layout from 'components/LayoutPrimary';
import LayoutJobPositions from 'components/LayoutJobPositions';
import SignIn from 'pages/SignIn';
import Logout from 'components/Logout';
// change here


export default () =>
	<Router forceRefresh={true} history={history}>
		<Switch>
			<Route exact path="/" render={(props) => <SignIn />} />
			<Route exact path="/logout" component={Logout} />
			<Route
				exact
				path="/medicoscoahuila"
				render={(props) =>
					<LayoutJobPositions component={<JobPositions {...props} />} />
				} />
			<PrivateRoute
				roles={[1, 2]}
				exact
				path="/trabajadores-activos"
				render={(props) =>
					<Layout {...props} component={<Employee type="active" {...props} />} />
				}
			/>

			<PrivateRoute
				roles={[1, 4]}
				exact
				path="/trabajadores-inactivos"
				render={(props) =>
					<Layout {...props} component={<Employee type="inactive" {...props} />} />
				}
			/>

			<PrivateRoute
				roles={[1, 4]}
				exact
				path="/documentos"
				render={(props) =>
					<Layout {...props} component={<Documents />} />
				}
			/>

			<PrivateRoute
				roles={[1]}
				exact
				path="/usuarios"
				render={(props) =>
					<Layout {...props} component={<User />} />
				}
			/>

			<PrivateRoute
				roles={[3]}
				exact
				path="/trabajador"
				render={(props) =>
					<LayoutGuest {...props} component={<Guest />} />
				}
			/>

			<PrivateRoute
				roles={[1, 2]}
				exact
				path="/aspirantes-activos"
				render={(props) =>
					<Layout {...props} component={<Candidates type="active" {...props} />} />
				}
			/>

			<PrivateRoute
				roles={[1, 4]}
				exact
				path="/aspirantes-inactivos"
				render={(props) =>
					<Layout {...props} component={<Candidates type="inactive" {...props} />} />
				}
			/>

			<PrivateRoute
				roles={[1]}
				exact
				path="/resultados"
				render={(props) =>
					<Layout {...props} component={<Results {...props} />} />
				}
			/>

			<PrivateRoute
				roles={[1, 2, 4]}
				exact
				path="/requerimientos"
				render={(props) =>
					<Layout {...props} component={<Requirement {...props} />} />
				}
			/>

			<PrivateRoute
				roles={[1, 2, 4]}
				exact
				path="/requerimientos/:id/:candidate_id"
				render={(props) =>
					<Layout {...props} component={<Requirement {...props} />} />
				}
			/>

			<PrivateRoute
				roles={[1, 2, 4]}
				exact
				path="/trabajador/informacion/:id"
				render={(props) =>
					<Layout {...props} component={<Guest {...props} />} />
				}
			/>
			<PrivateRoute
				roles={[1, 5]}
				exact
				path="/plazas"
				render={(props) =>
					<Layout {...props} component={<JobPositions {...props} isUser />} />
				}
			/>
			<PrivateRoute
				roles={[1, 2]}
				exact
				path="/correos"
				render={(props) =>
					<Layout {...props} component={<Emails {...props} />} />
				}
			/>
		</Switch>
	</Router>;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: 10,
		[theme.breakpoints.down('xs')]: {
			width: '96%'
		},
		backgroundColor: 'antiquewhite'
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	cardContent: {
		minHeight: 194,
		[theme.breakpoints.down('xs')]: {
			minHeight: 'auto',
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: 148,
		},
		[theme.breakpoints.down('md')]: {
			minHeight: 148,
		},
		[theme.breakpoints.down('lg')]: {
			minHeight: 149,
		}
	}
}));

export default function SimpleCard({ region, cellphone, name }) {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardContent className={classes.cardContent}>
				<Typography variant="h5" component="h5">
					{region}
				</Typography>
				<Typography className={classes.pos} color="textSecondary">
					{cellphone}
				</Typography>
				<Typography variant="body1" component="p">
					{name}
				</Typography>
			</CardContent>
			<CardActions>
				<a href={`tel:${cellphone}`}>
					<Button size="small">Click para llamar</Button>
				</a>
			</CardActions>
		</Card>
	);
}

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 500,
		'& > *': {
			margin: theme.spacing(1),
		},
	},
	title: {
		color: '#1a1c20ab',
		fontWeight:'bold'
	},
	paragraphError: {
		margin: 0
	},
	input: {
		display: 'none',
	},
}));

export default useStyles;

import React, {Fragment, useState, useEffect, useCallback} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CustomCheckbox from 'components/CustomCheckbox';
import { JobPositionService } from 'services/api/job-positions';
import Loader from 'components/Loader';
import Pagination from '@material-ui/lab/Pagination';
import { useAuthDataContext } from 'context/AuthContext';
import RegionCard from '../Guest/RegionCard';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import useScrollTop from 'hooks/useScrollTop'
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 90,
        }
    },
    footer: {
        left: 0,
        bottom: 0,
        width: '100%',
        padding: 50,
        backgroundColor: '#4ca746',
        [theme.breakpoints.down('xs')]: {
            padding: 5,
        }
    },
    positionFixed: {
        position: 'fixed',
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'grey',
        color: theme.palette.common.white,
    }
}))(TableCell);

const regionInformation = [
    {
        id: 1,
        region: 'Coahuila',
        cellphone: '844-362-66-88',
        name: 'Lic. Maria Ferrer Velez'
    },
    {
        id: 2,
        region: 'Coahuila',
        cellphone: '844-283-74-74',
        name: 'C. Marcia Isabel Coronado Lopez'
    }
];

export const JobPositions = ({ isUser = false }) => {
    const classes = useStyles();
    const { user } = useAuthDataContext();
    const [pageReady, setPageReady] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [jobPositions, setJobPositions] = useState([]);
    const [term, setTerm] = useState('');
    const [alertProps, setAlertProps] = useState({
        isOpen: false,
        severity: '',
        message: ''
    });
    const isAdmin = user ? [1, 5].includes(user.role) : false;

    const handleSetTerm = event => {
        const { value } = event.target;
        setTerm(value);
    }

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    }

    const getPages = useCallback(async () => {
        const status = isUser ? null : 1;
        const { meta, error } = await JobPositionService.getPages(status);

        if (meta) {
            setPages(meta.pages);
            return true;
        }

        if (error) {
            return false;
        }
    }, [isUser]);

    const getJobPositions = useCallback(async (page) => {
        const { data, error } = isUser
            ? await JobPositionService.all(page)
            : await JobPositionService.allActive(page);

        if (data) {
            setJobPositions(data);
        }

        if (error) {
            console.log(error);
        }
    }, [isUser]);

    const handleStatusChange = (id, status) => {
        (async () => {
            try {
                await JobPositionService.changeStatus(id, { status });

                setAlertProps({
                    isOpen: true,
                    severity: 'success',
                    message: status ? 'Se ha habilitado la plaza': 'Se ha deshabilitado la plaza'
                })
            } catch (e) {
                console.log(e);
            }
        })();
    }

    const handleCloseAlert = () => {
        setAlertProps({
            isOpen: false,
            severity: '',
            message: ''
        })
    }

    useEffect(() => {
        (async () => {
            await getJobPositions();
            await getPages();
            setPageReady(true);
        })();
    }, [getJobPositions, getPages]);

    useEffect(() => {
        (async () => {
            setShowLoader(true);
            if (term !== '') {
                const status = isUser ? 3 : 1;
                const page = currentPage;
                const response = isUser
                    ? await JobPositionService.search(term.trim(), status, page)
                    : await JobPositionService.searchNoSession(term.trim(), status, page)
                if (response.data) {
                    setJobPositions(response.data);
                }

                if (response.meta) {
                    setPages(response.meta.pages);
                }

                if (response.error) {
                    alert(response.error);
                }
                setShowLoader(false);
            } else {
                await getJobPositions(currentPage);
                setShowLoader(false);
            }
        })();
        // eslint-disable-next-line
    }, [currentPage, getJobPositions, isUser])

    useEffect(() => {
        (async () => {
            if (pageReady) {
                if (currentPage > 1) {
                    setCurrentPage(1);
                }
                const status = isUser ? 3 : 1;
                const page = currentPage;
                const response = isUser
                    ? await JobPositionService.search(term.trim(), status, page)
                    : await JobPositionService.searchNoSession(term.trim(), status, page)
                if (response.data) {
                    setJobPositions(response.data);
                }

                if (response.meta) {
                    setPages(response.meta.pages);
                }

                if (response.error) {
                    alert(response.error);
                }
            }
        })();
        // eslint-disable-next-line
    }, [term, isUser, pageReady]);

    useScrollTop(pageReady);

    if (!pageReady) {
        return <Loader />
    }

    return (
        <Fragment>
            <Container className={classes.root}>
                {showLoader && <Loader />}
                <Typography component="p">Listado de plazas Médicos Coahuila</Typography>
                <br />

                <Grid container>
                    <Grid item lg={6} xs={12}>
                        <Pagination
                            onChange={handlePageChange}
                            count={pages}
                            page={currentPage}
                            variant="outlined"
                            color="primary"
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <TextField
                            id="standard-full-width"
                            label="Buscar"
                            style={{ margin: 8 }}
                            placeholder=""
                            helperText="Busca por especialidad o zona"
                            fullWidth
                            margin="normal"
                            onChange={handleSetTerm}
                            value={term || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        onClick={(f) => f}
                                    >
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>

                <br />

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Especialidad</StyledTableCell>
                                <StyledTableCell>Zona</StyledTableCell>
                                <StyledTableCell>Marca Ocupación</StyledTableCell>
                                {!isUser && <StyledTableCell>Total</StyledTableCell>}
                                {isUser && user && isAdmin &&
                                    <StyledTableCell>&nbsp;</StyledTableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jobPositions.map((row) => {
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.speciality}</TableCell>
                                        <TableCell>{row.zone}</TableCell>
                                        <TableCell>{row.position_type}</TableCell>
                                        {!isUser && <TableCell>{row.total}</TableCell>}
                                        {isUser && user && isAdmin &&
                                        <TableCell align="center" style={{ display: 'flex' }}>
                                            <CustomCheckbox
                                                checked={row.status >= 1}
                                                onStatusChange={handleStatusChange}
                                                jobPositionId={row.id}
                                            />
                                        </TableCell>
                                        }
                                    </TableRow>
                                )
                            })}

                            {jobPositions.length <= 0 && (
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        No se encontraron resultados
                                </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <br />

                <Pagination
                    onChange={handlePageChange}
                    count={pages}
                    page={currentPage}
                    variant="outlined"
                    color="primary"
                />
                <br/>
            </Container>

            {alertProps.isOpen &&
            <Snackbar
                open={alertProps.isOpen}
                autoHideDuration={3000}
                onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertProps.severity}>
                    <p className={classes.paragraphError}>
                        {alertProps.message}
                    </p>
                </Alert>
            </Snackbar>
            }

            {isUser === false &&
            <footer className={clsx(classes.footer, jobPositions.length <= 4 ? classes.positionFixed : '')}>
                <Fragment>
                    <Grid container alignContent="center" justify="center">
                        {regionInformation.map(region => (
                            <Grid item lg={3} xs={12} md={4} sm={6} key={region.id}>
                                <RegionCard
                                    region={region.region}
                                    cellphone={region.cellphone}
                                    name={region.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Fragment>
            </footer>
            }
        </Fragment>
    )
}

export default JobPositions

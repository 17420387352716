import actions from './actions';
import { form } from './constants';

export const initialState = {
	showLoader: false,
	showModalLoader: false,
	showForm: false,
	showModalDelete: false,
	isUpdating: false,
	pageReady: false,
	isFetch: false,
	userId: 0,
	users: [],
	roles: [],
	regions: [],
	form: form,
	userToDelete: {},
	alertProps: {
		message: '',
		severity: '',
		show: false
	}
};

const reducer = (state, action) => {
	switch (action.type) {
	case actions.showLoader: {
		return {
			...state,
			showLoader: action.payload
		};
	}
	case actions.showModalLoader: {
		return {
			...state,
			showModalLoader: action.payload
		};
	}
	case actions.showModalDelete: {
		return {
			...state,
			showModalDelete: action.payload
		};
	}
	case actions.setUsers: {
		return {
			...state,
			users: action.payload
		};
	}
	case actions.setRegions: {
		return {
			...state,
			regions: action.payload
		};
	}
	case actions.setIsFetch: {
		return {
			...state,
			isFetch: action.payload
		};
	}
	case actions.setRoles: {
		return {
			...state,
			roles: action.payload
		};
	}
	case actions.setUserId: {
		return {
			...state,
			userId: action.payload
		};
	}
	case actions.setPageReady: {
		return {
			...state,
			pageReady: action.payload
		};
	}
	case actions.setUserToDelete: {
		return {
			...state,
			userToDelete: action.payload
		};
	}
	case actions.setForm: {
		return {
			...state,
			form: action.payload
		};
	}
	case actions.showForm: {
		return {
			...state,
			showForm: action.payload
		};
	}
	case actions.setAlertProps: {
		return {
			...state,
			alertProps: action.payload
		};
	}
	default: {
		return {
			...state
		};
	}
	}
};

export default reducer;

import React, { Fragment, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Email from '@material-ui/icons/Email';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'react-quill/dist/quill.snow.css';
import { green } from '@material-ui/core/colors';
import { useAuthDataContext } from 'context/AuthContext';
import SystemNotifications from 'components/SystemNotifications';
import { CustomEmailsService } from 'services/api/custom-emails';

import CustomQuillEditor from 'components/CustomQuillEditor';

const ColorButton = withStyles((theme) => ({
    root: {
        color: 'white',
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
        '&:disabled': {
            backgroundColor: '#d2caca2e',
        },
        margin: theme.spacing(1),
        position: 'absolute',
        right: 0
    },
}))(Button);

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
    title: {
        color: '#1a1c20ab',
        fontWeight: 'bold'
    },
    margin: {
        margin: theme.spacing(1),
    },
    root: {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: 'green',
        color: 'white'
    },
    textEditor: {
        height: 350,
        position: 'relative',
        marginBottom: 30
    },
    addressesLabel: {
        fontStyle: 'italic',
        fontSize: 12,
        color: 'green'
    },
    autocomplete: {
        display: 'contents',
        '& .MuiTextField-root': {
            width: '27ch',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                margin: 5
            },
        },
        '& .MuiInputBase-input': {
            textTransform: 'uppercase',
        },
    },
}));


export const Component = (props) => {
    const classes = useStyles();
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [show, setShow] = useState(false);
    const [regions, setRegions] = useState([]);
    const [value, setValue] = useState('');
    const [sendRegion, setSendRegion] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState(0);
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [emails, setEmails] = useState([]);
    const { user } = useAuthDataContext();

    const handleAddEmail = event => {
        if (event.key === 'Enter') {
            const { value } = event.target;

            setEmails(prevChips => [...prevChips, value]);
            setValue('');
        }
    }

    const handleDelete = (chipToDelete) => () => {
        setEmails((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleInputChange = event => {
        setValue(event.target.value);
    }

    const handleQuillChange = html => {
        setBody(html)
    }

    const handleSubjectChange = event => {
        const { value } = event.target;
        setSubject(value)
    }

    const handleCheckboxChange = event => {
        if (!event.target.checked) {
            setSelectedRegion(0);
        }
        if (+user.role > 1 && event.target.checked) {
          setSelectedRegion(1);
        }
        setSendRegion(event.target.checked);
    }

    const handleFiltersAutocompleteChange = (event, value) => {
        if (!value) {
          setSelectedRegion(0);
        } else {
          setSelectedRegion(value.id);
        }
    }

    const displayMessage = (m, s, sh) => {
        setMessage(m);
        setSeverity(s);
        setShow(sh);
    }

    const sendEmail = () => {
        CustomEmailsService.sendEmail({
            region: selectedRegion,
            emails,
            subject,
            body
        }).then(response => {
            if (response.data) {
              handleResetForm();
                displayMessage(
                    response.meta.message,
                    'success',
                    true
                );
            }
        })
    };

    const handleValidateForm = () => {
      if (user.role === 1) {
        if (!sendRegion) {
          return subject.trim() === '' || body.replace(/<[^>]*>?/gm, '').trim()  === '' || emails.length <= 0
        } else {
          return subject.trim()  === '' || body.replace(/<[^>]*>?/gm, '').trim() === '' || selectedRegion === 0
        }
      } else {
        if (!sendRegion) {
          return subject.trim()  === '' || body.replace(/<[^>]*>?/gm, '').trim()  === '' || emails.length <= 0
        } else {
          return subject.trim()  === '' || body.replace(/<[^>]*>?/gm, '').trim()  === ''
        }
      }
    }

    const handleResetForm = () => {
      setValue('');
      setSubject('');
      setSelectedRegion(0);
      setSendRegion(false);
      setBody('');
      setEmails([]);
    }

    React.useEffect(() => {
        CustomEmailsService.getRegions()
            .then(response => {
                if (response.data) {
                    setRegions(response.data);
                }
            });
    }, []);

    return (
        <Fragment>
            <Container>
                <Typography
                    className={classes.title}
                    variant="h5"
                    gutterBottom>
                    Envío de correos
                </Typography>

                <br />

                <div style={{ position: 'relative' }}>
                    <TextField
                        style={{ marginRight: 10 }}
                        value={value}
                        id="outlined-full-width"
                        label="Correo electrónico"
                        size="small"
                        placeholder="email@ejemplo.com"
                        helperText="Selecciona los correos a los cuales se les enviara el correo"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        onKeyPress={handleAddEmail}
                        onChange={handleInputChange}
                    />

                    {emails.length > 0 &&
                        <div>
                            <p className={classes.addressesLabel}>El correo se enviará a la siguente lista de direcciones:</p>
                            <Paper component="ul" className={classes.root}>
                                {emails.map((data, key) => {
                                    let icon = <Email />;

                                    return (
                                        <li key={key}>
                                            <Chip
                                                size="small"
                                                icon={icon}
                                                label={data}
                                                onDelete={handleDelete(data)}
                                                className={classes.chip}
                                            />
                                        </li>
                                    );
                                })}
                            </Paper>
                        </div>
                    }

                    <TextField
                        style={{ marginRight: 10 }}
                        value={subject}
                        id="outlined-full-width"
                        label="Asunto"
                        size="small"
                        placeholder=""
                        helperText="Escribe el asunto que lleva el correo"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        variant="outlined"
                        onChange={handleSubjectChange}
                    />
                    <FormControlLabel
                        style={{
                            marginTop: 5,
                            color: 'gba(0, 0, 0, 0.54) !import',
                            fontSize: '0.75rem !import'
                        }}
                        control={<GreenCheckbox checked={sendRegion} onChange={handleCheckboxChange} name="checkedG" />}
                        label={user.role === 1 ? 'Enviar el correo a todos los empleados de una determinada región' : 'Enviar el correo a todos los empleados de la región'}
                    />
                    <br />
                    {sendRegion && user.role === 1 &&
                        <Autocomplete
                            className={classes.autocomplete}
                            disabled={user.role > 1}
                            name="region"
                            options={regions.filter(region => region.name !== 'N/A')}
                            onChange={(event, value) => handleFiltersAutocompleteChange(event, value)}
                            getOptionLabel={(option) => option.name.toUpperCase()}
                            value={regions.filter(region => region.id === selectedRegion)[0] || null}
                            renderInput={(params) =>
                                <TextField
                                    variant="outlined"
                                    required
                                    {...params}
                                    label="Región"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                    style={{ marginRight: 10, marginTop: 20 }}
                                    helperText="Selecciona la región de los empleados a los que se les enviará el correo"
                                />
                            }
                        />
                    }
                </div>

                <br />

                <div>
                    <p>Contenido del correo</p>
                    <CustomQuillEditor html={body} handleChange={handleQuillChange} />
                </div>

                <br />

                <div style={{ position: 'relative' }}>
                    <ColorButton
                        onClick={sendEmail}
                        variant="outlined"
                        color="primary"
                        endIcon={<Icon>send</Icon>}
                        disabled={handleValidateForm()}
                    >
                        Enviar
                    </ColorButton>
                </div>
            </Container>
            <SystemNotifications
                message={message}
                severity={severity}
                show={show}
            />
        </Fragment>
    )
}

export default Component

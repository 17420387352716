import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Delete, NoteAdd, GetApp, Email } from '@material-ui/icons';
import { RequirementService } from 'services/api/requirements';
import Loader from 'components/CustomLoader'

import useStyles from './styles';

import { useAuthDataContext } from 'context/AuthContext';
import CustomModal from 'components/CustomModal/CustomModal';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert/Alert';
import SelectResult from './SelectResult/SelectResult';
import useScrollTop from '../../hooks/useScrollTop'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Row(props) {
    const rowRef = useRef(null);
    let query = useQuery();
    const candidateId = query.get('aspirante');
    const { row, onDeleteReq, onDeleteCandidate, onSendEmail, onChangeResult, onGeneratePdf } = props;
    const [open, setOpen] = React.useState(query.get('requerimiento') ? true : false);
    const classes = useStyles();

    const scrollToRow = () => {
        rowRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest"
        });
    };

    useEffect(() => {
        if (rowRef.current) {
            scrollToRow();
        }
    }, [rowRef]);

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" className={classes.textUppercase}>
                    {row.period}
                </TableCell>
                <TableCell className={classes.textUppercase}>{row.Region.region}</TableCell>
                <TableCell style={{ display: 'flex' }}>
                    <IconButton
                        title="Descargar PDF"
                        aria-label="descargar pdf"
                        className={classes.margin}
                        onClick={() => onGeneratePdf(row.id)}
                    >
                        <GetApp fontSize="small" />
                    </IconButton>
                    <IconButton
                        title="Enviar correo"
                        aria-label="enviar correo"
                        className={classes.margin}
                        onClick={() => onSendEmail(row.id)}
                    >
                        <Email fontSize="small" />
                    </IconButton>
                    <IconButton
                        title="Eliminar"
                        aria-label="delete"
                        className={classes.margin}
                        onClick={() => onDeleteReq(row.id)}
                    >
                        <Delete fontSize="small" />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Aspirantes
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Nombre</StyledTableCell>
                                        <StyledTableCell>Categoría</StyledTableCell>
                                        <StyledTableCell>Trabajador</StyledTableCell>
                                        <StyledTableCell>Parentesco</StyledTableCell>
                                        <StyledTableCell>Resultado</StyledTableCell>
                                        <StyledTableCell> </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.Candidates.map((candidate) => (
                                        +candidateId === +candidate.id
                                            ? (<StyledTableRow key={candidate.id} ref={rowRef}>
                                                <TableCell component="th" scope="row" className={classes.textUppercase}>
                                                    {candidate.first_name} {candidate.last_name_f} {candidate.last_name_m}
                                                </TableCell>
                                                <TableCell>{candidate.Category.category}</TableCell>
                                                {candidate.Employee &&
                                                    <TableCell component="th" scope="row" className={classes.textUppercase}>
                                                        {candidate.Employee.first_name} {candidate.Employee.last_name_f} {candidate.Employee.last_name_m}
                                                    </TableCell>
                                                }
                                                {!candidate.Employee &&
                                                    <TableCell component="th" scope="row">
                                                        S/N
                                                </TableCell>
                                                }
                                                <TableCell className={classes.textUppercase}>
                                                    {candidate.Relationship.relationship}
                                                </TableCell>
                                                <TableCell>
                                                    <SelectResult
                                                        requirement={candidate.candidateRequirement.id}
                                                        option={candidate.candidateRequirement.result}
                                                        onChange={onChangeResult}
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.textUppercase}>
                                                    <IconButton
                                                        title="Eliminar"
                                                        aria-label="delete"
                                                        className={classes.margin}
                                                        onClick={() => onDeleteCandidate(row.id, candidate.id)}
                                                    >
                                                        <Delete fontSize="small" />
                                                    </IconButton>
                                                </TableCell>
                                            </StyledTableRow>)
                                            : (<TableRow key={candidate.id}>
                                                <TableCell component="th" scope="row" className={classes.textUppercase}>
                                                    {candidate.first_name} {candidate.last_name_f} {candidate.last_name_m}
                                                </TableCell>
                                                <TableCell>{candidate.Category.category}</TableCell>
                                                {candidate.Employee &&
                                                    <TableCell component="th" scope="row" className={classes.textUppercase}>
                                                        {candidate.Employee.first_name} {candidate.Employee.last_name_f} {candidate.Employee.last_name_m}
                                                    </TableCell>
                                                }
                                                {!candidate.Employee &&
                                                    <TableCell component="th" scope="row">
                                                        S/N
                                                </TableCell>
                                                }
                                                <TableCell className={classes.textUppercase}>
                                                    {candidate.Relationship.relationship}
                                                </TableCell>
                                                <TableCell>
                                                    <SelectResult
                                                        requirement={candidate.candidateRequirement.id}
                                                        option={candidate.candidateRequirement.result}
                                                        onChange={onChangeResult}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        title="Eliminar"
                                                        aria-label="delete"
                                                        className={classes.margin}
                                                        onClick={() => onDeleteCandidate(row.id, candidate.id)}
                                                    >
                                                        <Delete fontSize="small" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>)
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#88b489',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgb(85 207 106 / 19%)',
    },
}))(TableRow);

export default function CollapsibleTable() {
    let query = useQuery();
    const [requirements, setRequirements] = useState([]);
    const [regions, setRegions] = useState([]);
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [requirementName, setRequirementName] = useState('');
    const [requirement, setRequirement] = useState(null);
    const [candidate, setCandidate] = useState(null);
    const [pageReady, setPageReady] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showAddRequirementModal, setShowAddRequirementModal] = useState(false);
    const [showDeleteRequirementModal, setShowDeleteRequirementModal] = useState(false);
    const [showDeleteCandidateFromReqModal, setShowDeleteCandidateFromReqModal] = useState(false);
    const [showSendEmailModal, setShowSendEmailModal] = useState(false);
    const [showModalLoader, setShowModalLoader] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState(0);
    const [alertProps, setAlertProps] = useState({
        show: false,
        message: '',
        severity: ''
    });
    const { user } = useAuthDataContext();
    const classes = useStyles();
    const handleOpenDeleteModal = (id) => {
        setRequirement(id);
        setShowDeleteRequirementModal(true);
    }
    const handleOpenDeleteCandidateModal = (requirementId, candidateId) => {
        setCandidate(candidateId);
        setRequirement(requirementId);
        setShowDeleteCandidateFromReqModal(true);
    }
    const handleCloseAddRequirementModal = () => {
        const region = user.role > 1
            ? user.region
            : 0;
        setRequirementName('');
        setSelectedRegion(region);
        setShowAddRequirementModal(false)
    }
    const handleCloseDeleteRequirementModal = () => {
        setRequirement(null);
        setShowDeleteRequirementModal(false);
    }
    const handleCloseDeleteCandidateFromReqModal = () => {
        setCandidate(null);
        setShowDeleteCandidateFromReqModal(false);
    }
    const handleCloseSendEmailModal = () => {
        setShowSendEmailModal(false);
    }
    const handleFiltersAutocompleteChange = (event, value, name) => {
        if (!value) {
            setSelectedRegion(0)
        } else {
            setSelectedRegion(value.id);
        }
    };
    const handleTextboxChange = (event) => {
        setRequirementName(event.target.value);
    }
    const handleCloseAlert = () => {
        setAlertProps({
            show: false,
            message: '',
            severity: ''
        })
    };
    const handleCreateRequirement = () => {
        setShowModalLoader(true);
        const body = { period: requirementName, region_id: selectedRegion };
        RequirementService.create(body)
            .then(async response => {
                if (response.data) {
                    setShowModalLoader(false);
                    handleCloseAddRequirementModal();
                    setAlertProps({
                        show: true,
                        message: response.meta.message,
                        severity: 'success'
                    });
                    const { data } = await RequirementService.index();
                    if (data) {
                        setRequirements(data);
                    }
                }
                if (response.error) {
                    setShowModalLoader(false);
                    setAlertProps({
                        show: true,
                        message: response.error,
                        severity: 'error'
                    });
                }
            })
    }
    const handleDeleteRequirement = () => {
        setShowModalLoader(true);
        RequirementService.remove(requirement)
            .then(async response => {
                if (response.data) {
                    setShowModalLoader(false);
                    handleCloseDeleteRequirementModal();
                    setAlertProps({
                        show: true,
                        message: response.meta.message,
                        severity: 'success'
                    });
                    const { data } = await RequirementService.index();
                    if (data) {
                        setRequirements(data);
                    }
                }
                if (response.error) {
                    setShowModalLoader(false);
                    setAlertProps({
                        show: true,
                        message: response.error,
                        severity: 'error'
                    });
                }
            })
    }
    const handleDeleteCandidateFromReq = () => {
        setShowModalLoader(true);
        RequirementService.removeCandidate(requirement, candidate)
            .then(async response => {
                if (response.data) {
                    setShowModalLoader(false);
                    handleCloseDeleteCandidateFromReqModal();
                    setAlertProps({
                        show: true,
                        message: response.meta.message,
                        severity: 'success'
                    });
                    const { data } = await RequirementService.index(currentPage);
                    if (data) {
                        setRequirements(data);
                    }
                }
                if (response.error) {
                    setShowModalLoader(false);
                    setAlertProps({
                        show: true,
                        message: response.error,
                        severity: 'error'
                    });
                }
            })
    }
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    }
    const handleOpenSendEmailModal = (id) => {
        setRequirement(id);
        setShowSendEmailModal(true);
    }
    const handleSendEmail = () => {
        setShowModalLoader(true);
        RequirementService.sendEmail(requirement)
            .then(response => {
                if (response.data) {
                    console.log(response.data);
                    setShowModalLoader(false);
                    setShowSendEmailModal(false);
                    setAlertProps({
                        show: true,
                        message: response.meta.message,
                        severity: 'success'
                    })
                }
                if (response.error) {
                    setAlertProps({
                        show: true,
                        message: response.error,
                        severity: 'error'
                    })
                }
            })
    }
    const handleChangeResult = (requirement, result) => {
        RequirementService.changeResult(requirement, result)
            .then(response => {
                if (response.data) {
                    setAlertProps({
                        show: true,
                        message: 'Se ha cambiado el resultado',
                        severity: 'success'
                    })
                }
                if (response.error) {
                    setAlertProps({
                        show: true,
                        message: 'Algo ha ocurrido, no se ha podido cambiar el resultado, por favor intente de nuevo',
                        severity: 'success'
                    })
                }
            })
            .catch(error => console.log(error));
    }
    const handleGeneratePdf = (id) => {
        setShowLoader(true);
        RequirementService.generatePdf(id)
            .then(response => {
                if (response) {
                    setShowLoader(false);
                    // Create a Blob from the pdf Stream
                    const file = new Blob([response], {
                        type: 'application/pdf'
                    });
                    // Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    // Open the URL on new Window
                    window.open(fileURL);
                }
            })
    }

    useEffect(() => {
        (async () => {
            const { data: pages } = await RequirementService.getPages();
            const { data: regions } = await RequirementService.getRegions();
            if (pages) {
                if (query.get('requerimiento')) {
                    setPages(1);
                } else {
                    setPages(pages);
                }
            }
            if (regions) {
                const filtered = regions.filter(region => region.name !== 'N/A');
                setRegions(filtered);
            }
            if (user.role > 1) {
                setSelectedRegion(user.region);
            }
            setPageReady(true);
        })()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        (async () => {
            const requirementId = query.get('requerimiento');
            const {
                data: requirements,
                meta
            } = requirementId
                    ? await RequirementService.fetch(requirementId)
                    : await RequirementService.index(currentPage);
            if (requirements) {
                setRequirements(requirements);
                setPages(meta.pages);
            }
        })()
        //eslint-disable-next-line
    }, [currentPage]);

    useScrollTop(pageReady);

    if (!pageReady) {
        return <Loader backdrop />
    }

    return (
        <Fragment>
            <Container>
                <Typography
                    className={classes.title}
                    variant="h5"
                    gutterBottom>
                    Lista de requerimientos
					<IconButton
                        aria-label="delete"
                        className={classes.margin}
                        onClick={() => setShowAddRequirementModal(true)}
                    >
                        <NoteAdd fontSize="default" />
                    </IconButton>
                </Typography>
                <div className={classes.pagination}>
                    {+pages > 1 &&
                        <Pagination
                            onChange={handlePageChange}
                            count={pages}
                            page={currentPage}
                        />
                    }
                </div>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell />
                                <StyledTableCell>Periodo</StyledTableCell>
                                <StyledTableCell>Región</StyledTableCell>
                                <StyledTableCell> </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {requirements.map((row) => (
                                <Row
                                    key={row.id}
                                    row={row}
                                    onDeleteReq={handleOpenDeleteModal}
                                    onDeleteCandidate={handleOpenDeleteCandidateModal}
                                    onSendEmail={handleOpenSendEmailModal}
                                    onChangeResult={handleChangeResult}
                                    onGeneratePdf={handleGeneratePdf}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.pagination}>
                    {+pages > 1 &&
                        <Pagination
                            onChange={handlePageChange}
                            count={pages}
                            page={currentPage}
                        />
                    }
                </div>
                <CustomModal
                    fullWidth
                    title="Crear nuevo requerimiento"
                    open={showAddRequirementModal}
                    confirm={handleCreateRequirement}
                    close={handleCloseAddRequirementModal}
                    buttonText="Aceptar"
                    disabled={requirementName === '' || selectedRegion === 0}
                    showLoader={showModalLoader}
                >
                    <div className={classes.root}>
                        <Box>
                            <Autocomplete
                                className={classes.autocomplete}
                                disabled={user.role > 1}
                                name="region"
                                options={regions}
                                onChange={(event, value) => handleFiltersAutocompleteChange(event, value, 'region')}
                                getOptionLabel={(option) => option.name.toUpperCase()}
                                value={
                                    user.role !== 1 ?
                                        regions.filter(region => region.id === user.region)[0] || null :
                                        regions.filter(region => region.id === selectedRegion)[0] || null
                                }
                                renderInput={(params) =>
                                    <TextField
                                        required
                                        {...params}
                                        label="Región"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                }
                            />
                        </Box>

                        <Box style={{ display: 'block' }}>
                            <TextField
                                style={{ width: '100%' }}
                                onChange={handleTextboxChange}
                                value={requirementName}
                                id="requirement"
                                name="requirement"
                                label="Nombre del requerimiento"
                                type="text"
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </div>
                </CustomModal>
                <CustomModal
                    title="Eliminar requerimiento"
                    open={showDeleteRequirementModal}
                    confirm={handleDeleteRequirement}
                    close={handleCloseDeleteRequirementModal}
                    buttonText="Aceptar"
                    disabled={!requirement}
                    showLoader={showModalLoader}
                >
                    Deseas eliminar el requerimiento?
			    </CustomModal>
                <CustomModal
                    title="Eliminar aspirante"
                    open={showDeleteCandidateFromReqModal}
                    confirm={handleDeleteCandidateFromReq}
                    close={handleCloseDeleteCandidateFromReqModal}
                    buttonText="Aceptar"
                    disabled={!candidate}
                    showLoader={showModalLoader}
                >
                    Deseas eliminar el aspirante del requerimiento?
			    </CustomModal>
                <CustomModal
                    title="Enviar correo"
                    open={showSendEmailModal}
                    confirm={handleSendEmail}
                    close={handleCloseSendEmailModal}
                    buttonText="Enviar"
                    disabled={false}
                    showLoader={showModalLoader}
                >
                    Se enviará un correo a todos los empleados notificando que
                    su aspirante ha sido seleccionado para
                    presentar examen
                    <br />
                    <br />
                    Presione <b>ENVIAR</b> para confirmar
			    </CustomModal>
                <CustomModal
                    title=""
                    open={showLoader}
                    confirm={(f) => f}
                    close={(f) => f}
                    buttonText="Aceptar"
                    disabled={!requirement}
                    showLoader={showLoader}
                >
                    Generando PDF por favor espere...
			    </CustomModal>
                <Snackbar
                    open={alertProps.show}
                    autoHideDuration={3000}
                    onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity={alertProps.severity}>
                        <p className={classes.paragraphError}>
                            {alertProps.message}
                        </p>
                    </Alert>
                </Snackbar>
            </Container>
        </Fragment>
    );
}

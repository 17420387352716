import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: '25ch',
			textTransform: 'uppercase'
		},
		'& .MuiInputBase-input': {
			textTransform: 'uppercase',
		},
		'& .MuiInputLabel-root': {
			fontSize: '0.8rem'
		}
	},
	select: {
		margin: theme.spacing(1),
		width: '25ch',
	},
	seniority: {
		width: '5ch !important',
	},
	autocomplete: {
		width: '5ch !important',
		display: 'contents'
	},
	actionsContainer: {
		flexDirection: 'row-reverse'
	},
	actionButtons: {
		margin: 5
	},
	saveButton: {
		color: 'green'
	},
	upperTextLabel: {
		textTransform: 'uppercase'
	},
	eyeIcon: {
		cursor: 'pointer',
		color: 'grey'
	},
	title: {
		width: '100%'
	},
	upper: {
		'& .MuiInputBase-input': {
			textTransform: 'uppercase',
		}
	},
	textCutEllipsis: {
		position: 'absolute',
		whiteSpace: 'nowrap',
		overflowY: 'visible',
		overflowX: 'hidden',
		textOverflow: 'ellipsis',
		'-ms-text-overflow': 'ellipsis',
		'-o-text-overflow': 'ellipsis',
		maxWidth: '100%',
		minWidth: 0,
		width: '98%',
		top: 7,
	},
}));

export default useStyles;

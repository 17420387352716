import React, { Fragment } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	iconDelete: {
		fontSize: '1.2rem'
	},
	span: {
		fontSize: '0.86rem',
		textDecoration: 'underline',
		cursor: 'pointer'
	},
	hide: {
		display: 'none'
	}
}));

const ClearFilters = ({
	show = false,
	onClearFilters = (f) => f
}) => {
	const classes = useStyles();

	return (
		<Fragment>
			<IconButton
				size="small"
				aria-label="delete"
				disableRipple
				onClick={onClearFilters}
				className={!show ? classes.hide : ''}
			>
				<DeleteIcon className={classes.iconDelete} />
			</IconButton>
			<span onClick={onClearFilters} className={clsx(classes.span, !show ? classes.hide : '')}>
				Limpiar filtros
			</span>
		</Fragment>
	);
};

export default ClearFilters;

export default {
	showLoader: 'SHOW_LOADER',
	setForm: 'SET_FORM',
	setFormField: 'SET_FORM_FIELD',
	setIsUpdate: 'SET_IS_UPDATE',
	clearFormData: 'CLEAR_FORM_DATA',
	setHasDifferences: 'SET_HAS_DIFFERENCES',
	setDifferences: 'SET_DIFFERENCES',
	showModalLoader: 'SHOW_MODAL_LOADER',
	setOriginalData: 'SET_ORIGINAL_DATA',
};

import actions from './actions';

const form = {
	id: '',
	first_name: '',
	last_name: '',
	email: '',
	password: '',
	phone_number: '',
	region: '',
	role: '',
	resend_email: false
};

export const initialState = {
	showLoader: false,
	showModalLoader: false,
	isUpdate: false,
	form: form
};

const reducer = (state, action) => {
	switch (action.type) {
	case actions.showLoader: {
		return {
			...state,
			showLoader: action.payload
		}
	}
	case actions.showModalLoader: {
		return {
			...state,
			showModalLoader: action.payload
		}
	}
	case actions.setIsUpdate: {
		return {
			...state,
			isUpdate: action.payload
		}
	}
	case actions.setForm: {
		return {
			...state,
			form: action.payload
		}
	}
	case actions.clearFormData: {
		return {
			...state,
			form: form
		}
	}
	case actions.setFormField: {
		return {
			...state,
			form: {
				...state.form,
				[action.payload.key]: action.payload.value
			}
		}
	}

	default: {
		return {
			...state
		}
	}
	}
};

export default reducer;

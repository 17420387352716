import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    paragraphError: ''
}));

export const Component = ({
    show,
    message,
    severity
}) => {
    const classes = useStyles();

    const [displayMessage, setMessage] = React.useState('');
    const [displaySeverity, setSeverity] = React.useState('success');
    const [displayShow, setShow] = React.useState(false);

    React.useEffect(() => {
        setMessage(message);
    }, [message]);

    React.useEffect(() => {
        setSeverity(severity);
    }, [severity]);

    React.useEffect(() => {
        setShow(show);
    }, [show]);

    const handleCloseAlert = () => {
        setShow(false);
        setSeverity('success');
        setMessage('');
    };

    return (
        <div>
            <Snackbar
                open={displayShow}
                autoHideDuration={3000}
                onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={displaySeverity}>
                    <p className={classes.paragraphError}>
                        {displayMessage}
                    </p>
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Component
import actions from './actions';
import { form, order_by, validations } from './constants';

export const initialState = {
	hasDifferences: false,
	differences: [],
	showLoader: true,
	showModalLoader: false,
	isFetching: false,
	employeeId: 0,
	employeeToRemove: {},
	isFirstRender: false,
	employees: [],
	filters: {
		region: 0,
		affiliation: 0,
		category: 0,
		seniority: 0,
	},
	search: {
		type: 1,
		enrollment: '',
		first_name: '',
		last_name_f: '',
		last_name_m: '',
	},
	searchType: 'enrollment',
	filter: '',
	format: '',
	alert_title: '',
	alert_body: '',
	totalResults: 0,
	results: 0,
	isUpdating: false,
	pages: null,
	regions: [],
	affiliations: [],
	filterAffiliations: [],
	categories: [],
	seniority: order_by,
	currentPage: 1,
	isSearching: false,
	form: form,
	originalData: form,
	validations: validations,
	showForm: false,
	showModalDelete: false,
	show: true,
	showAlert: false,
	formErrors: false,
	disablePassword: false,
	response_socket: '',
	status: 'active',
	alertProps: {
		message: '',
		severity: '',
		show: false
	}
};

const reducer = (state, action) => {
	switch (action.type) {
		case actions.setFormItem: {
			return {
				...state,
				form: {
					...state.form,
					[action.payload.key]: {
						...state.form[action.payload.key],
						error: '',
						value: action.payload.value
					}
				}
			};
		}
		case actions.setIsPageReady: {
			return {
				...state,
				ready: action.value
			};
		}
		case actions.setHasDifferences: {
			return {
				...state,
				hasDifferences: action.payload
			};
		}
		case actions.setDifferences: {
			return {
				...state,
				differences: action.payload
			};
		}
		case actions.showLoader: {
			return {
				...state,
				showLoader: action.value
			};
		}
		case actions.showModalLoader: {
			return {
				...state,
				showModalLoader: action.payload
			};
		}
		case actions.setIsUpdating: {
			return {
				...state,
				isUpdating: action.payload
			};
		}
		case actions.showForm: {
			return {
				...state,
				showForm: action.payload
			};
		}
		case actions.setResults: {
			return {
				...state,
				results: action.value
			};
		}
		case actions.setAlertProps: {
			return {
				...state,
				alertProps: action.payload
			};
		}
		case actions.setCurrentPage: {
			return {
				...state,
				currentPage: action.value
			};
		}
		case actions.setPages: {
			return {
				...state,
				pages: action.value
			};
		}
		case actions.setFilters: {
			return {
				...state,
				filters: {
					...state.filters,
					[action.payload.key]: action.payload.value
				}
			};
		}
		case actions.setTerm: {
			return {
				...state,
				filter: action.value
			};
		}
		case actions.setFormat: {
			return {
				...state,
				format: action.value
			};
		}
		case actions.setFormData: {
			return {
				...state,
				form: {
					...state.form,
					[action.payload.key]: action.payload.value
				}
			};
		}
		case actions.setForm: {
			return {
				...state,
				form: action.value
			};
		}
		case actions.setOriginalData: {
			return {
				...state,
				originalData: action.payload
			};
		}
		case actions.setEmployeeData: {
			return {
				...state,
				formData: action.value
			};
		}
		case actions.setEmployeeId: {
			return {
				...state,
				employeeId: action.value
			};
		}
		case actions.setEmployeeToRemove: {
			return {
				...state,
				employeeToRemove: action.payload
			};
		}
		case actions.setEmployees: {
			return {
				...state,
				employees: action.value
			};
		}
		case actions.setRegions: {
			return {
				...state,
				regions: action.value
			};
		}
		case actions.setAffiliations: {
			return {
				...state,
				affiliations: action.value
			};
		}
		case actions.setFilterAffiliations: {
			return {
				...state,
				filterAffiliations: action.value
			};
		}
		case actions.setCategories: {
			return {
				...state,
				categories: action.value
			};
		}
		case actions.setDeleteMessage: {
			return {
				...state,
				deleteMessage: action.value
			};
		}
		case actions.showDialog: {
			return {
				...state,
				showDialog: action.value
			};
		}
		case actions.setCurrentRow: {
			return {
				...state,
				employeeRowToRemove: action.value
			};
		}
		case actions.showAlert: {
			return {
				...state,
				showAlert: {
					type: action.value.type,
					status: action.value.status,
					message: action.value.message
				}
			};
		}
		case actions.setFirstRender: {
			return {
				...state,
				isFirstRender: action.value
			};
		}
		case actions.setTotal: {
			return {
				...state,
				total: action.value
			};
		}
		case actions.showModalDelete: {
			return {
				...state,
				showModalDelete: action.payload
			};
		}
		case actions.setTotalPages: {
			return {
				...state,
				totalPages: action.value
			};
		}
		case actions.clearFilters: {
			return {
				...state,
				filters: {
					filter: '',
					region: 0,
					affiliation: 0,
					category: 0,
					status: 'active',
					order_by: 0,
					page: 1,
					searchType: 'enrollment'
				}
			};
		}
		case actions.showCreateFrom: {
			return {
				...state,
				showCreateFrom: action.value
			};
		}
		case actions.clearFormData: {
			return {
				...state,
				form: {
					id: '',
					enrollment: '',
					first_name: '',
					last_name_f: '',
					last_name_m: '',
					email: '',
					password: '',
					seniority_years: '',
					seniority_fortnights: '',
					seniority_days: '',
					telephone: '',
					cell_phone: '',
					status: '',
					region: '',
					category: '',
					affiliation: '',
					observations: '',
					membership: '',
					resend_email: false
				}
			};
		}
		case actions.clearOriginalData: {
			return {
				...state,
				originalData: {
					id: '',
					enrollment: '',
					first_name: '',
					last_name_f: '',
					last_name_m: '',
					email: '',
					password: '',
					seniority_years: '',
					seniority_fortnights: '',
					seniority_days: '',
					telephone: '',
					cell_phone: '',
					status: '',
					region: '',
					category: '',
					affiliation: '',
					observations: '',
					membership: '',
					resend_email: false
				}
			};
		}
		case actions.clearTerm: {
			return {
				...state,
				search: {
					type: 1,
					enrollment: '',
					first_name: '',
					last_name_f: '',
					last_name_m: ''
				}
			};
		}
		case actions.setFieldRequired: {
			return {
				...state,
				validations: {
					...state.validations,
					[action.value.key]: {
						...state.validations[action.value.key],
						required: action.value.value
					}
				}
			};
		}
		case actions.resetFilters: {
			return {
				...state,
				filters: {
					region: (action.payload === 1 ? 0 : state.filters.region),
					affiliation: 0,
					category: 0,
					seniority: 0
				}
			};
		}
		case actions.setSearchType: {
			return {
				...state,
				search: {
					...state.search,
					[action.payload.key]: action.payload.value
				}
			}
		}
		default:
			return state;
	}
};

export default reducer;

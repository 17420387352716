import { get, post, put, destroy, viewFile } from 'config/api'

export const ResultService = {
    index: () => get(`/results`),
    fetch: (id) => get(`/results/${id}`),
    view: (id) => viewFile(`/results/view/${id}`),
    create: (data) => post('/results', data),
    update: (id, data) => put(`/results/${id}`, data),
    remove: (id) => destroy(`/results/${id}`),
    getRegions: () => get('/regions'),
}
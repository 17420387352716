import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
            margin: theme.spacing(1),
        },
    },
    textUppercase: {
        textTransform: 'uppercase'
    },
    title: {
        color: '#1a1c20ab',
        fontWeight: 'bold'
    },
    pagination: {
        textAlign: 'end',
        marginTop: 10,
        marginBottom: 10,
        '& .MuiPagination-root': {
            display: 'inline-grid'
        }
    },
}));

export default useStyles;
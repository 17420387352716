import { get, post, put, destroy } from 'config/api'

export const CandidateService = {
    index: (status, page) => get(`/candidates?status=${status}&page=${page}`),
    search: (params) => get(`/candidates${params}`),
    fetch: (id) => get(`/candidates/${id}`),
    create: (data) => post('/candidates', data),
    update: (id, data) => put(`/candidates/${id}`, data),
    remove: (id) => destroy(`/candidates/${id}`),
    getRegions: () => get('/regions'),
    getAffiliations: () => get('/affiliations'),
    getCategories: () => get('/categories'),
    getRelationships: () => get('/relationships'),
    getLevelOfStudy: () => get('/level-of-study'),
    getRequirements: (candidateId) => get(candidateId
        ? `/requirements?candidateId=${candidateId}`
        : '/requirements'),
    getPages: (status) => get(`/candidates/pages?status=${status}`),
    addToNextReq: (candidate_id) => post('/next-requirement', { candidate_id }),
    removeFromNextReq: (candidate_id) => destroy(`/next-requirement/${candidate_id}`),
    addToRequirement: (data) => post('/candidate-requirements', data),
    getCandidatesInRequirements: () => get('/candidates/in-requirement')
}
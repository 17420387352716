import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
	search: {
		marginTop: 10,
		marginBottom: 10
	},
	searchbox: {
		width: '50% !important'
	},
	enrollment: {
		width: '50% !important',
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
			margin: 5
		},
	},
	name: {
		width: '20% !important',
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
			margin: 5
		},
	},
	inputSearch: {
		textTransform: 'uppercase',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: 5
		},
	},
	eyeIcon: {
		cursor: 'pointer',
		color: 'grey'
	},
	radio: {
		color: green[400],
		'&$checked': {
			color: green[600],
		},
		checked: {},
	}
}));

const GreenRadio = withStyles({
	root: {
		color: green[400],
		'&$checked': {
			color: green[600],
		},
	},
	checked: {},
})((props) => <Radio color="default" {...props} />);

const Searchbox = ({
	enrollment,
	firstName,
	lastNameF,
	lastNameM,
	searchPerform,
	handleChange,
	handleRadio,
	searchType
}) => {
	const classes = useStyles();
	const [search, setSearch] = useState(searchType);

	useEffect(() => {
		setSearch(+searchType)
		return () => {
			return false;
		}
	}, [searchType])

	return (
		<form className={classes.search} onSubmit={searchPerform}>
			<RadioGroup
				row
				aria-label="position"
				name="position"
				defaultValue="top"
			>
				<FormControlLabel value={1} onChange={handleRadio} control={<GreenRadio size="small" checked={search === 1} />} label="Matricula" />
				<FormControlLabel value={2} onChange={handleRadio} control={<GreenRadio size="small" checked={search === 2} />} label="Nombre" />
			</RadioGroup>
			{search === 1 &&
				<TextField
					type="search"
					onChange={handleChange}
					className={classes.enrollment}
					fullWidth
					margin="dense"
					id="enrollment"
					label="Bucar por matricula"
					value={enrollment || ''}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment
								position="end"
								onClick={searchPerform}
								className={classes.eyeIcon}>
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			}
			{search === 2 &&
				<Fragment>
					<TextField
						type="search"
						onChange={handleChange}
						className={classes.name}
						fullWidth
						margin="dense"
						id="first_name"
						label="Buscar por nombre"
						value={firstName || ''}
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment
									position="end"
									onClick={searchPerform}
									className={classes.eyeIcon}>
									<SearchIcon />
								</InputAdornment>
							),
						}}
						onKeyPress={event => {
							if (event.key === 'Enter') {
								event.preventDefault();
								searchPerform(event);
							}
						}}
					/>
					<TextField
						type="search"
						onChange={handleChange}
						className={classes.name}
						fullWidth
						margin="dense"
						id="last_name_f"
						label="Apellido paterno"
						value={lastNameF || ''}
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment
									position="end"
									onClick={searchPerform}
									className={classes.eyeIcon}>
									<SearchIcon />
								</InputAdornment>
							),
						}}
						onKeyPress={event => {
							if (event.key === 'Enter') {
								event.preventDefault();
								searchPerform(event);
							}
						}}
					/>
					<TextField
						type="search"
						onChange={handleChange}
						className={classes.name}
						fullWidth
						margin="dense"
						id="last_name_m"
						label="Apellido materno"
						value={lastNameM || ''}
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment
									position="end"
									onClick={searchPerform}
									className={classes.eyeIcon}>
									<SearchIcon />
								</InputAdornment>
							),
						}}
						onKeyPress={event => {
							if (event.key === 'Enter') {
								event.preventDefault();
								searchPerform(event);
							}
						}}
					/>
				</Fragment>
			}
		</form>
	);
};

export default Searchbox;

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	buttonsPadding: {
		padding: '8px 24px 15px',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	buttonWidth: {
		width: '50%',
	},
}));

const Modal = ({
	title,
	open,
	close,
	showLoader,
	disabled,
	confirm,
	buttonText,
	fullWidth = false,
	children,
	overwrite = '',
	maxWidth = 'sm',
	classes = {},
	onExited
}) => {
	const styles = useStyles();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Dialog
			className={overwrite}
			fullWidth={fullWidth || false}
			open={open}
			onClose={close}
			maxWidth={maxWidth}
			fullScreen={fullScreen}
			classes={classes}
			onExited={onExited}
		>
			<DialogTitle>
				{title}
				<IconButton
					aria-label="close"
					className={styles.closeButton}
					onClick={close}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>{children}</DialogContent>
			<DialogActions className={styles.buttonsPadding}>
				<Button
					overwrite={styles.buttonWidth}
					disabled={disabled}
					onClick={confirm}
				>
					{!showLoader && buttonText}
					{showLoader && <CircularProgress />}
				</Button>

			</DialogActions>
		</Dialog>
	);
};

const noop = () => { };

Modal.defaultProps = {
	onExited: noop,
};

export default Modal;

import { get, post, put, destroy, viewFile } from 'config/api'

export const DocumentService = {
    index: () => get(`/files`),
    fetch: (id) => get(`/files/${id}`),
    view: (id) => viewFile(`/files/view/${id}`),
    create: (data) => post('/files', data),
    update: (id, data) => put(`/files/${id}`, data),
    remove: (id) => destroy(`/files/${id}`),
    getRegions: () => get('/regions'),
}
import React, { Fragment, useEffect, useReducer } from 'react';
import DocumentList from 'components/DocumentList/DocumentList';
import Container from '@material-ui/core/Container';
import useStyles from './styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EditOutlined from '@material-ui/icons/EditOutlined';
import Grid from '@material-ui/core/Grid';
import { useAuthDataContext } from 'context/AuthContext';
import reducer, { initialState } from './reducer';
import actions from './actions';
import Loader from 'components/CustomLoader';
import clsx from 'clsx';
import getSeniorityFormat from 'utils/getSeniorityFormat';
import { getDestructuredAge, getNumberMask } from 'utils/common';
import moment from 'moment';
import 'moment/locale/es';
import Box from '@material-ui/core/Box';
import download from "js-file-download";
import RegionCard from './RegionCard';
import { GuestService } from 'services/api/guest'
import useScrollTop from 'hooks/useScrollTop';

moment.locale('es');

const results = ['Seleccionado', 'No Seleccionado', 'Reserva'];
const regionInformation = [
	{
		id: 1,
		region: 'Saltillo',
		cellphone: '844-252-8337',
		name: 'Enf. Israel Salas Martinez'
	},
	{
		id: 2,
		region: 'Torreón',
		cellphone: '871-717-9696',
		name: 'Lic. Daniel Omar Sánchez Martínez'
	},
	{
		id: 3,
		region: 'Monclova',
		cellphone: '866-631-2440',
		name: 'T.R.X. Manuel Ramón González Zapata'
	},
	{
		id: 4,
		region: 'Nueva Rosita',
		cellphone: '861 614 2952',
		name: 'Lic. Enrique Javier Garcia Aguilar'
	},
	{
		id: 5,
		region: 'Cuidad Acuña',
		cellphone: '877-772-3435',
		name: 'Hist. Maria Luisa Cruz Valencia'
	},
	{
		id: 6,
		region: 'Piedras Negras',
		cellphone: '878-783-0558',
		name: 'Ayte. Farm. Julio Cesar Hernandez Alcocer'
	},
	{
		id: 7,
		region: 'Parras',
		cellphone: '842-422-1777',
		name: 'Dra. Irma Aracely Beltrán González'
	}
];

const Guest = (props) => {
	const classes = useStyles();
	const { user } = useAuthDataContext();
	const [state, dispatch] = useReducer(reducer, initialState);

  useScrollTop(state.pageReady);

	useEffect(() => {
		(async () => {
			try {
				const { role, id } = user;

				const { data: documents } = await GuestService.getDocs();
				const { data: results } = await GuestService.getResults();

				if (documents) {
					dispatch({ type: actions.setDocuments, payload: documents });
				}
        if (results) {
          dispatch({ type: actions.setResults, payload: results });
        }

				let employeeId = id;
				if (role !== 3) {
					if (typeof props.match.params.id !== 'undefined') {
						employeeId = props.match.params.id;
					}
				}
				const response = await GuestService.fetch(employeeId);

				if (response.data) {
					const employee = response.data;
					const seniority = getSeniorityFormat(employee.seniority);
					dispatch({ type: actions.setSeniority, payload: seniority });
					dispatch({ type: actions.setEmployeeData, payload: employee });
					dispatch({ type: actions.setPageready, payload: true });
				}
			} catch (e) {
				console.log(e);
			}
		})();
		//eslint-disable-next-line
	}, []);

	const redirectToEditEmployee = () => {
		const status = state.employeeData.status === 1 ? 'activos' : 'inactivos';
		props.history.push(`/trabajadores-${status}`, {
			employeeId: state.employeeData.id
		});
	};

	const redirectToEditCandidate = () => {
		const status = state.employeeData.status === 1 ? 'activos' : 'inactivos';
		props.history.push(`/aspirantes-${status}`, {
			candidateId: state.employeeData.Candidate.id
		});
	};

	const downloadDocument = (document) => {
		(async () => {
			const response = await GuestService.viewDoc(document.id);

			if (document.ext !== 'application/pdf') {
				download(response, document.path);
			} else {
				// Create a Blob from the pdf Stream
				const file = new Blob([response], {
					type: 'application/pdf'
				});
				// Build a URL from the file
				const fileURL = URL.createObjectURL(file);
				// Open the URL on new Window
				window.open(fileURL);
			}
		})();
	};

	const downloadResult = (document) => {
		(async () => {
			const response = await GuestService.viewResult(document.id);

			if (document.ext !== 'application/pdf') {
				download(response, document.path);
			} else {
				// Create a Blob from the pdf Stream
				const file = new Blob([response], {
					type: 'application/pdf'
				});
				// Build a URL from the file
				const fileURL = URL.createObjectURL(file);
				// Open the URL on new Window
				window.open(fileURL);
			}
		})();
	};

	if (!state.pageReady) {
		return <Loader fullWidth />
	}

	return (
		<Fragment>
			{user.role === 3 &&
				<header>
					<Typography variant="h4" gutterBottom className={classes.headerTitle}>
						Dr Alejandro Cantú López Secretario General Secc. XII, Coahuila
					</Typography>
				</header>
			}
			{user.role === 3 &&
				<Box className={classes.files}>
					{state.documents.length > 0 &&
						<DocumentList
							label="Descarga de documentos"
							documents={state.documents}
							onDownload={downloadDocument}
						/>
					}
					{state.results.length > 0 &&
						<DocumentList
							label="Descarga de resultados"
							documents={state.results}
							onDownload={downloadResult}
						/>
					}
				</Box>
			}

			<Container className={classes.rootContainer}>
				<Card className={classes.card}>
					<CardHeader
						title="Información del trabajador"
						subheader={`Fecha de inscripción: ${moment(state.employeeData.created_date).format('LL') || ''}`}
						avatar={user.role !== 3 ?
							<IconButton aria-label="add to favorites" onClick={redirectToEditEmployee}>
								<EditOutlined titleAccess="Editar información del trabajador" />
							</IconButton>
							: ''
						}
					/>

					<CardContent className={classes.cardContent}>
						<Grid container>
							<Grid item lg={6} style={{ textAlign: 'left' }}>
								<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
									Nombre - <Typography className={clsx(classes.labelValue, classes.capitalize)} variant="body2" color="textSecondary" component="span">
										{state.employeeData.first_name || ''} {state.employeeData.last_name_f || ''} {state.employeeData.last_name_m || ''}
									</Typography>
								</Typography>
							</Grid>
							<Grid item lg={6} style={{ textAlign: 'left' }}>
								<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
									Matrícula - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
										{state.employeeData.enrollment || ''}
									</Typography>
								</Typography>
							</Grid>
							<Grid item lg={6} style={{ textAlign: 'left' }}>
								<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
									Correo Electrónico - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
										{state.employeeData.email || ''}
									</Typography>
								</Typography>
							</Grid>
							<Grid item lg={6} style={{ textAlign: 'left' }}>
								<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
									Teléfono - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
										{getNumberMask(state.employeeData.telephone) || ''}
									</Typography>
								</Typography>
							</Grid>

							<Grid item lg={6} style={{ textAlign: 'left' }}>
								<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
									Célular - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
										{getNumberMask(state.employeeData.cell_phone) || ''}
									</Typography>
								</Typography>
							</Grid>
							<Grid item lg={6} style={{ textAlign: 'left' }}>
								<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
									Región - <Typography className={clsx(classes.labelValue, classes.capitalize)} variant="body2" color="textSecondary" component="span">
										{state.employeeData.Region.region_name || ''}
									</Typography>
								</Typography>
							</Grid>
							<Grid item lg={6} style={{ textAlign: 'left' }}>
								<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
									Categoría - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
										{state.employeeData.Category.category || ''}
									</Typography>
								</Typography>
							</Grid>

							<Grid item lg={6} style={{ textAlign: 'left' }}>
								<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
									Antigüedad Laboral - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
										{state.seniority.years > 1 ? `${state.seniority.years} años` : `${state.seniority.years} año` || ''} {state.seniority.fortnights > 1 ? `${state.seniority.fortnights} quincenas` : `${state.seniority.fortnights} quincena` || ''} {state.seniority.days > 1 ? `${state.seniority.days} dias` : `${state.seniority.days} día` || ''}
									</Typography>
								</Typography>
							</Grid>
							<Grid item lg={6} style={{ textAlign: 'left' }}>
								<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
									Status - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
										{state.employeeData.status === 1 ? 'Activo' : (state.employeeData.status > 2 ? 'Pensionado' : 'Jubilado') || ''}
									</Typography>
								</Typography>
							</Grid>
							<Grid item lg={6} style={{ textAlign: 'left' }}>
								<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
									Adscripción - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
										{state.employeeData.status > 1 ? 'N/A' : state.employeeData.Affiliation.affiliation_name || ''}
									</Typography>
								</Typography>
							</Grid>
							<Grid item lg={6} style={{ textAlign: 'left' }}>
								<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
									Fecha de inscripción - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
										{moment(state.employeeData.created_date).format('LL') || ''}
									</Typography>
								</Typography>
							</Grid>
							{user.role !== 3 &&
								<Grid item lg={12} style={{ textAlign: 'left' }}>
									<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
										Antecedentes - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
											{state.employeeData.observations || 'S/N'}
										</Typography>
									</Typography>
								</Grid>
							}

						</Grid>
					</CardContent>
				</Card>

				<Card className={classes.card}>
					<CardHeader
						title="Información del Aspirante"
						avatar={(user.role !== 3 && state.employeeData.Candidate) ?
							<IconButton aria-label="add to favorites" onClick={redirectToEditCandidate}>
								<EditOutlined titleAccess="Editar información del trabajador" />
							</IconButton>
							: ''
						}
					/>
					<CardContent className={classes.cardContent}>
						{state.employeeData.Candidate &&
							<Grid container>
								<Grid item lg={6} style={{ textAlign: 'left' }}>
									<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
										Nombre - <Typography className={clsx(classes.labelValue, classes.capitalize)} variant="body2" color="textSecondary" component="span">
											{state.employeeData.Candidate.first_name.toLowerCase() || ''} {state.employeeData.Candidate.last_name_f.toLowerCase() || ''} {state.employeeData.Candidate.last_name_m.toLowerCase() || ''}
										</Typography>
									</Typography>
								</Grid>
                <Grid item lg={6} style={{ textAlign: 'left' }}>
                  <Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
                    Escolaridad - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
                    {state.employeeData.Candidate.LevelOfStudy.level || ''}
                  </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} style={{ textAlign: 'left' }}>
                  <Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
                    Edad - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
                    {getDestructuredAge(state.employeeData.Candidate.birthday).years} años {getDestructuredAge(state.employeeData.Candidate.birthday).months} meses {getDestructuredAge(state.employeeData.Candidate.birthday).days} dias
                  </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} style={{ textAlign: 'left' }}>
                  <Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
                    Documento de acreditación - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
                    {state.employeeData.Candidate.Document.document_name || ''}
                  </Typography>
                  </Typography>
                </Grid>
								<Grid item lg={6} style={{ textAlign: 'left' }}>
									<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
										Fecha de nacimiento - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
											{moment.utc(state.employeeData.Candidate.birthday).format('LL')}
										</Typography>
									</Typography>
								</Grid>
                <Grid item lg={6} style={{ textAlign: 'left' }}>
                  <Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
                    Es incondicional? - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
                    {state.employeeData.Candidate.unconditional ? 'Si' : 'No' || ''}
                  </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} style={{ textAlign: 'left' }}>
                  <Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
                    Categoría solicitada - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
                    {state.employeeData.Candidate.Category.category || ''}
                  </Typography>
                  </Typography>
                </Grid>
                {user.role !== 3 &&
                <Grid item lg={6} style={{ textAlign: 'left' }}>
                  <Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
                    Antecedentes - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
                    {state.employeeData.Candidate.observations || 'S/N'}
                  </Typography>
                  </Typography>
                </Grid>
                }
								<Grid item lg={6} style={{ textAlign: 'left' }}>
									<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
										Parentesco - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
											{state.employeeData.Candidate.Relationship.relationship}
										</Typography>
									</Typography>
								</Grid>
                <Grid item lg={6} style={{ textAlign: 'left' }}>
                  <Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
                    Estudia actualente? - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
                    {state.employeeData.Candidate.is_studying ? 'Si' : 'No' || ''}
                  </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} style={{ textAlign: 'left' }}>
                  <Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
                    Región - <Typography className={clsx(classes.labelValue, classes.capitalize)} variant="body2" color="textSecondary" component="span">
                    {state.employeeData.Candidate.Region.region_name || ''}
                  </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} style={{ textAlign: 'left' }}>
                  <Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
                    Carrera que estudia - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
                    {state.employeeData.Candidate.career || 'N/A'}
                  </Typography>
                  </Typography>
                </Grid>
								<Grid item lg={6} style={{ textAlign: 'left' }}>
									<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
										Célular - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
											{getNumberMask(state.employeeData.Candidate.cell_phone) || ''}
										</Typography>
									</Typography>
								</Grid>
								<Grid item lg={6} style={{ textAlign: 'left' }}>
									<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
										Ya ha sido evaluado anteriormente? - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
											{state.employeeData.Candidate.has_been_evaluated ? 'Si' : 'No' || ''}
										</Typography>
									</Typography>
								</Grid>
                <Grid item lg={6} style={{ textAlign: 'left' }}>
                  <Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
                    Teléfono - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
                    {getNumberMask(state.employeeData.Candidate.telephone) || 'S/N'}
                  </Typography>
                  </Typography>
                </Grid>
								{state.employeeData.Candidate.has_been_evaluated &&
									<Fragment>
										<Grid item lg={6} style={{ textAlign: 'left' }}>
											<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
												Fecha de evaluación - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
													{moment.utc(state.employeeData.Candidate.evaluation_date).format('LL') || 'N/A'}
												</Typography>
											</Typography>
										</Grid>
										<Grid item lg={6} style={{ textAlign: 'left' }}>
											<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
												Resultado obtenido - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
													{results[state.employeeData.Candidate.result - 1] || 'N/A'}
												</Typography>
											</Typography>
										</Grid>
										<Grid item lg={6}></Grid>
										<Grid item lg={6} style={{ textAlign: 'left' }}>
											<Typography className={classes.labelKey} variant="caption" color="textPrimary" component="p">
												Categoría a la que aplicó - <Typography className={classes.labelValue} variant="body2" color="textSecondary" component="span">
													{state.employeeData.Candidate.CategoryRequested.category || 'N/A'}
												</Typography>
											</Typography>
										</Grid>
									</Fragment>
								}

							</Grid>
						}
						{!state.employeeData.Candidate &&
							<Typography variant="h5" gutterBottom>
								No se ha registrado aspirante aun
						</Typography>
						}
					</CardContent>
				</Card>
			</Container>
			{user.role === 3 &&
				<footer className={classes.footer}>
					<Fragment>
						<Grid container alignContent="center" justify="center">
							{regionInformation.map(region => (
								<Grid item lg={3} xs={12} md={4} sm={6} key={region.id}>
									<RegionCard
										region={region.region}
										cellphone={region.cellphone}
										name={region.name}
									/>
								</Grid>
							))}
						</Grid>
					</Fragment>
				</footer>
			}
		</Fragment>
	);
};

export default Guest;

const onlyLetterPatter = /^[ñA-Za-z _]*[ñÑA-Za-z][ñA-Za-z _]*$/;

module.exports = {
	validations: {
		enrollment: {
			required: true
		},
		first_name: {
			required: true,
			validator: {
				regEx: onlyLetterPatter,
				error: 'Solo de admiten letras.'
			}
		},
		last_name_f: {
			required: true,
			validator: {
				regEx: onlyLetterPatter,
				error: 'Solo de admiten letras.'
			}
		},
		last_name_m: {
			required: true,
			validator: {
				regEx: onlyLetterPatter,
				error: 'Solo de admiten letras.'
			}
		},
		email: {
			required: true,
			validator: {
				regEx: /^([a-zA-Z0-9._+-]+)@((\[?)[a-zA-Z0-9\-.]+\.([a-zA-Z]{2,7}|[0-9]{1,3})(]?))$/,
				error: 'Formato de correo invalido.'
			}
		},
		password: {
			required: true,
			validator: {
				regEx: /^(?=.{8,})/,
				error: 'La contraseña debe contener 8 caracteres.',
			}
		},
		years: {
			required: true
		},
		fortnights: {
			required: true
		},
		days: {
			required: true
		},
		telephone: {
			required: false
		},
		cell_phone: {
			required: true
		},
		status: {
			required: true
		},
		region: {
			required: true
		},
		category: {
			required: true
		},
		affiliation: {
			required: true
		},
		observations: {
			required: false
		},
		membership: {
			required: false
		},
		resend_email: {
			required: false
		}
	},
	form: {
		id: '',
		enrollment: '',
		first_name: '',
		last_name_f: '',
		last_name_m: '',
		email: '',
		password: '',
		seniority_years: '',
		seniority_fortnights: '',
		seniority_days: '',
		telephone: '',
		cell_phone: '',
		status: '',
		region: 0,
		category: 0,
		affiliation: 0,
		observations: '',
		membership: '',
		resend_email: false
	},
	order_by: [
		{
			id: 0,
			name: 'ID'
		},
		{
			id: 1,
			name: 'Mayor Antiguedad'
		},
		{
			id: 2,
			name: 'Menor Antiguedad'
		},
		{
			id: 3,
			name: 'Nombre A-Z'
		},
		{
			id: 4,
			name: 'Nombre Z-A'
		},
		{
			id: 5,
			name: 'Categoria A-Z'
		},
		{
			id: 6,
			name: 'Categoria Z-A'
		},
		{
			id: 7,
			name: 'Antecedentes'
		}
	],
	employee_status: [
		{
			id: 1,
			name: 'Activo'
		},
		{
			id: 2,
			name: 'Jubilado'
		},
		{
			id: 3,
			name: 'Pensionado'
		}
	]
};

import actions from './actions';
import { form, orderBy, results, validations } from './constants';

export const initialState = {
	showLoader: false,
	showModalLoader: false,
	pageReady: false,
	filters: {
		region: 0,
		relationship: 0,
		category: 0,
		order_by: 0
	},
	search: {
		type: 1,
		enrollment: '',
		first_name: '',
		last_name_f: '',
		last_name_m: '',
	},
	pages: null,
	currentPage: 1,
	filter: '',
	format: '',
	isFetching: false,
	isUpdating: false,
	form: form,
	validations: validations,
	affiliations: [],
	candidates: [],
	candidateId: 0,
	categories: [],
	documents: [],
	regions: [],
	relationships: [],
	relationship_order: [],
	requirements: [],
	scholarships: [],
	results: results,
	order_by: orderBy,
	showForm: false,
	disablePassword: false,
	status: 'active',
	alertProps: {
		message: '',
		severity: '',
		show: false
	},
	showModalDelete: false,
	showModalRequirement: false,
	candidateToRemove: {},
	candidateToAssoc: {},
	requirementToAdd: 0
};

const reducer = (state, action) => {
	switch (action.type) {
		case actions.showLoader: {
			return {
				...state,
				showLoader: action.payload
			};
		}
		case actions.showModalLoader: {
			return {
				...state,
				showModalLoader: action.payload
			};
		}
		case actions.setPageReady: {
			return {
				...state,
				pageReady: action.payload
			};
		}
		case actions.setIsUpdating: {
			return {
				...state,
				isUpdating: action.payload
			};
		}
		case actions.setRequirementToAdd: {
			return {
				...state,
				requirementToAdd: action.payload
			};
		}
		case actions.setTerm: {
			return {
				...state,
				filter: action.value
			};
		}
		case actions.setFormat: {
			return {
				...state,
				format: action.value
			};
		}
		case actions.setForm: {
			return {
				...state,
				form: action.value
			};
		}
		case actions.showForm: {
			return {
				...state,
				showForm: action.payload
			};
		}
		case actions.showModalDelete: {
			return {
				...state,
				showModalDelete: action.payload
			};
		}
		case actions.showModalRequirement: {
			return {
				...state,
				showModalRequirement: action.payload
			};
		}
		case actions.setFormItem: {
			return {
				...state,
				form: {
					...state.form,
					[action.payload.key]: {
						...state.form[action.payload.key],
						error: '',
						value: action.payload.value
					}
				}
			};
		}
		case actions.setCandidateId: {
			return {
				...state,
				candidateId: action.payload
			};
		}
		case actions.setCandidateToRemove: {
			return {
				...state,
				candidateToRemove: action.payload
			};
		}
		case actions.setCandidateToAssoc: {
			return {
				...state,
				candidateToAssoc: action.payload
			};
		}
		case actions.clearTerm: {
			return {
				...state,
				search: {
					type: 1,
					enrollment: '',
					first_name: '',
					last_name_f: '',
					last_name_m: ''
				}
			};
		}
		case actions.setFilters: {
			return {
				...state,
				filters: {
					...state.filters,
					[action.payload.key]: action.payload.value
				}
			};
		}
		case actions.setCurrentPage: {
			return {
				...state,
				currentPage: action.payload
			};
		}
		case actions.setRegions: {
			return {
				...state,
				regions: action.payload
			};
		}
		case actions.setCategories: {
			return {
				...state,
				categories: action.payload
			};
		}
		case actions.setRelationships: {
			return {
				...state,
				relationships: action.payload
			};
		}
		case actions.setRelationshipOrder: {
			return {
				...state,
				relationship_order: action.payload
			};
		}
		case actions.setCandidates: {
			return {
				...state,
				candidates: action.payload
			};
		}
		case actions.setDocuments: {
			return {
				...state,
				documents: action.payload
			};
		}
		case actions.setRequirements: {
			return {
				...state,
				requirements: action.payload
			};
		}
		case actions.setScholarships: {
			return {
				...state,
				scholarships: action.payload
			};
		}
		case actions.setPage: {
			return {
				...state,
				pages: action.payload
			};
		}
		case actions.resetFilters: {
			return {
				...state,
				filters: {
					region: (action.payload === 1 ? 0 : state.filters.region),
					relationship: 0,
					category: 0,
					order_by: 0
				}
			};
		}
		case actions.setAlertProps: {
			return {
				...state,
				alertProps: action.payload
			};
		}
		case actions.setSearchType: {
			return {
				...state,
				search: {
					...state.search,
					[action.payload.key]: action.payload.value
				}
			}
		}

		default: {
			return {
				...state
			};
		}
	}
};

export default reducer;

import React, { Fragment, useReducer, useEffect } from 'react';
import Loader from 'components/Loader';

import actions from './actions';
import reducer, { initialState } from './reducer';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import PersonAdd from '@material-ui/icons/PersonAdd';

import CustomUserForm from 'components/CustomUserForm';

import useStyles from './styles';
import Collapse from '@material-ui/core/Collapse';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CustomModal from 'components/CustomModal/CustomModal';
import { UserService } from 'services/api/users'
import useScrollTop from '../../hooks/useScrollTop'

const User = () => {
    /* Constants*/
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initialState);

    /* Functions*/
    const loadUsers = async () => {
        dispatch({ type: actions.showLoader, payload: true });
        await UserService.index()
            .then((response) => {
                if (response.data) {
                    dispatch({ type: actions.setUsers, payload: response.data });
                }
            });

        dispatch({ type: actions.showLoader, payload: false });
    };

    const handleResponse = response => {
        if (response.data) {
            dispatch({
                type: actions.setAlertProps,
                payload: {
                    show: true,
                    message: response.meta.message,
                    severity: 'success'
                }
            });
            loadUsers();
        }
        if (response.error) {
            dispatch({
                type: actions.setAlertProps,
                payload: {
                    show: true,
                    message: response.error,
                    severity: 'error'
                }
            });
        }
    };

    const handleEditUser = id => {
        dispatch({ type: actions.setUserId, payload: id });
        dispatch({
            type: actions.showForm,
            payload: true
        });
    };

    const handleCancelForm = () => {
        dispatch({ type: actions.setUserId, payload: 0 });
        dispatch({
            type: actions.showForm,
            payload: false
        });
    };

    const toggleShowForm = () => {
        dispatch({
            type: actions.showForm,
            payload: !state.showForm
        });
    };

    const handleCloseAlert = () => {
        dispatch({
            type: actions.setAlertProps,
            payload: {
                show: false,
                message: '',
                severity: ''
            }
        });
    };

    const handleOpenDeleteModal = user => {
        dispatch({
            type: actions.setUserToDelete,
            payload: user
        });
        dispatch({
            type: actions.showModalDelete,
            payload: true
        });
    };

    const handleCloseDeleteModal = () => {
        dispatch({
            type: actions.setUserToDelete,
            payload: {}
        });
        dispatch({
            type: actions.showModalDelete,
            payload: false
        });
    };

    const handleDeleteUser = () => {
        dispatch({ type: actions.showModalLoader, payload: true });
        UserService.remove(state.userToDelete.id)
            .then((response) => {
                if (response.data) {
                    dispatch({
                        type: actions.setAlertProps,
                        payload: {
                            show: true,
                            message: response.meta.message,
                            severity: 'success'
                        }
                    });
                    handleCloseDeleteModal();
                    loadUsers();
                    dispatch({ type: actions.showModalLoader, payload: false });
                }
                if (response.error) {
                    dispatch({
                        type: actions.setAlertProps,
                        payload: {
                            show: true,
                            message: response.error,
                            severity: 'warning'
                        }
                    });
                    dispatch({ type: actions.showModalLoader, payload: false });
                    handleCloseDeleteModal();
                }
            })
    };

    /* Effects*/
    useEffect(() => {
        if (!state.isFetch) {
            UserService.getRegions()
                .then(response => {
                    if (response.data) {
                        dispatch({ type: actions.setRegions, payload: response.data.filter(region => region.name !== 'N/A') });
                    }
                })
            UserService.getRoles()
                .then(response => {
                    if (response.data) {
                        dispatch({ type: actions.setRoles, payload: response.data });
                    }
                })
            loadUsers().then();
        } // eslint-disable-next-line
    }, []);

    useScrollTop(state.showLoader);

    if (state.showLoader) {
        return <Loader />;
    }

    return (
        <Fragment>
            <Container>
                <Typography
                    className={classes.title}
                    variant="h5"
                    gutterBottom>
                    Usuarios
					<IconButton
                        aria-label="delete"
                        className={classes.margin}
                        onClick={toggleShowForm}
                    >
                        <PersonAdd fontSize="default" />
                    </IconButton>
                </Typography>

                <Collapse in={state.showForm} timeout="auto" unmountOnExit component="div">
                    <CustomUserForm
                        userId={state.userId}
                        onResponse={handleResponse}
                        onCancel={handleCancelForm}
                        regions={state.regions}
                        roles={state.roles}
                    />
                </Collapse>

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Correo</TableCell>
                                <TableCell>Teléfono</TableCell>
                                <TableCell>Rol</TableCell>
                                <TableCell>Región</TableCell>
                                <TableCell align="center">Opciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.users.map((row) => {
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell className={classes.uppercase}>{row.first_name} {row.last_name}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.phone_number && row.phone_number.length > 10 ? row.phone_number.substring(2, row.phone_number.length) : row.phone_number}</TableCell>
                                        <TableCell className={classes.uppercase}>{row.Role.role}</TableCell>
                                        <TableCell className={classes.uppercase}>{row.Region.region_name}</TableCell>
                                        <TableCell align="center" style={{ display: 'flex' }}>
                                            <IconButton
                                                title="Actualizar información"
                                                aria-label="update"
                                                className={classes.margin}
                                                onClick={() => handleEditUser(row.id)}
                                            >
                                                <Edit fontSize="default" />
                                            </IconButton>
                                            <IconButton
                                                title="Eliminar"
                                                aria-label="delete"
                                                className={classes.margin}
                                                onClick={() => handleOpenDeleteModal(row)}
                                            >
                                                <Delete fontSize="default" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}

                            {state.users.length <= 0 && (
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        No se encontraron resultados
									</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>

            <CustomModal
                title="Eliminar usuario"
                open={state.showModalDelete}
                confirm={handleDeleteUser}
                close={() => console.log('')}
                buttonText="Aceptar"
                disabled={false}
                showLoader={state.showModalLoader}
            >
                Se eliminará el usuario
				<span className={classes.employeeName} style={{ textTransform: 'capitalize' }}> {state.userToDelete.first_name || ''} {state.userToDelete.last_name || ''} </span>
            </CustomModal>

            <Snackbar
                open={state.alertProps.show}
                autoHideDuration={3000}
                onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={state.alertProps.severity}>
                    <p className={classes.paragraphError}>
                        {state.alertProps.message}
                    </p>
                </Alert>
            </Snackbar>
        </Fragment>
    );
};

export default User;

import { get, post, put, destroy } from 'config/api'

export const UserService = {
    index: () => get(`/users`),
    fetch: (id) => get(`/users/${id}`),
    create: (data) => post('/users', data),
    update: (id, data) => put(`/users/${id}`, data),
    remove: (id) => destroy(`/users/${id}`),
    getRegions: () => get('/regions'),
    getRoles: () => get('/roles'),
}
import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	appBar: {
		backgroundColor: '#4ca746'
	},
	divContainerComponent: {
		marginTop: 110,
		[theme.breakpoints.down('sm')]: {
			marginTop: 65,
		},
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		padding: 0,
		marginRight: theme.spacing(2),
	},
	title: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
	mobileTitle: {
		display: 'block',
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	},
	searchIcon: {
		width: theme.spacing(7),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 200,
		},
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	logo: {
		width: 80,
		height: 'auto',
		[theme.breakpoints.down('sm')]: {
			width: 50,
		},
	},
	paragraph: {
		textTransform: 'capitalize',
		margin: 0
	},
	headerTitle: {
		textAlign: 'center'
	},
}));

export default useStyles;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Logo from '../../logo2.png';
import Imss from '../../imss2.png';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import history from '../../history';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: '#4ca746'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textAlign: 'start'
    },
    logo: {
        width: 50,
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: 50,
        },
    },
    mobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    desktop: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    button: {
        backgroundColor: 'inherit !important'
    }
}));

export default function MenuAppBar() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="home"
                        onClick={() => history.push('/')}
                    >
                        <img src={Logo} className={classes.logo} alt="logo" />
                    </IconButton>
                    <div style={{ paddingRight: 15 }}>
                        <Avatar alt="Imss" src={Imss} />
                    </div>
                    <Typography variant="h6" className={clsx(classes.title, classes.mobile)}>
                        SIATSS - SNTSS sección XII, Coahuila
                    </Typography>
                    <Typography variant="h6" className={clsx(classes.title, classes.desktop)}>
                        SIATSS
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}

import React from 'react';
import {CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Backdrop from "@material-ui/core/Backdrop/Backdrop";

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	rootFullWidth: {
		background: 'transparent',
		width: '100%',
		height: '100%'
	},
	progress: {
		color: '#00aab4'
	},
	progressFullWidth: {
		color: '#00aab4',
		position: 'fixed',
		top: '50%',
		left: '50%'
	},
	progressButton: {
		color: 'white',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	loaderCenter: {
		position: 'absolute',
		justifyContent: 'center',
		zIndex: 1,
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
	},
}));

export const ButtonLoader = ({ overwrite = {}, ...props }) => {
	const classes = useStyles();
	return <CircularProgress className={clsx(classes.progressButton, overwrite)} {...props} />
};

export const CardLoader = ({ overwrite = {}, ...props }) => {
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, classes.rootFullWidth, classes.loaderCenter, overwrite)}>
			<CircularProgress className={classes.progress} {...props} />
		</div>
	);
};

const Loader = ({ fullWidth = false, backdrop = false, ...props }) => {
	const classes = useStyles();

	if (backdrop) {
		return (
			<Backdrop className={classes.backdrop} open={true}>
				<CircularProgress color="inherit" />
			</Backdrop>
		);
	}

	return (
		<div className={(fullWidth ? classes.rootFullWidth : classes.root)}>
			<CircularProgress className={(fullWidth ? classes.progressFullWidth : classes.progress)} {...props} />
		</div>
	);
};
export default Loader;

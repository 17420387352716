export default {
	showLoader: 'SHOW_LOADER',
	showModalDelete: 'SHOW_MODAL_DELETE',
	showModalLoader: 'SHOW_MODAL_LOADER',
	showForm: 'SHOW_FORM',
	setUsers: 'SET_USERS',
	setRegions: 'SET_REGIONS',
	setRoles: 'SET_ROLES',
	setUserId: 'SET_USER_ID',
	setPageReady: 'SET_PAGE_READY',
	setForm: 'SET_FORM',
	setIsUpdating: 'SET_IS_UPDATING',
	setIsFetch: 'SET_IS_FETCH',
	setFieldRequired: 'SET_FIELD_REQUIRED',
	clearForm: 'CLEAR_FORM',
	setAlertProps: 'SET_ALERT_PROPS',
	setUserToDelete: 'SET_USER_TO_DELETE'
};

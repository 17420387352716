import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import Logo from '../../logo2.png';
import useStyles from './useStyles';
import { useAuthDataContext } from '../../context/AuthContext';

const Layout = ({ component }) => {
	const { user, onLogout } = useAuthDataContext();
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = event => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem onClick={handleMenuClose}>Salir</MenuItem>
		</Menu>
	);

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			{/*<MenuItem>
				<IconButton aria-label="show 4 new mails" color="inherit">
					<Badge badgeContent={4} color="secondary">
						<MailIcon />
					</Badge>
				</IconButton>
				<p className={classes.paragraph}>Mensajes</p>
			</MenuItem>
			<MenuItem>
				<IconButton aria-label="show 11 new notifications" color="inherit">
					<Badge badgeContent={11} color="secondary">
						<NotificationsIcon />
					</Badge>
				</IconButton>
				<p className={classes.paragraph}>Notificaciones</p>
			</MenuItem>*/}
			<MenuItem>
				<IconButton
					aria-label="account of current user"
					aria-controls="primary-search-account-menu"
					aria-haspopup="true"
					color="inherit"
				>
					<AccountCircle />
				</IconButton>
				<p className={classes.paragraph}>{user.first_name} {user.last_name_f} {user.las_name_m}</p>
			</MenuItem>
			<MenuItem onClick={onLogout}>
				<IconButton aria-label="show 4 new mails" color="inherit">
					<ExitToApp />
				</IconButton>
				<p className={classes.paragraph}>Salir</p>
			</MenuItem>
		</Menu>
	);

	return (
		<div className={classes.grow}>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="open drawer"
					>
						<img src={Logo} className={classes.logo} alt="logo" />
					</IconButton>
					<Typography className={classes.title} variant="h6" noWrap>
						Sistema de Integración de Aspirantes de los Trabajadores del Seguro Social
					</Typography>
					<Typography className={classes.mobileTitle} variant="h6" noWrap>
						SIATSS
					</Typography>
					<div className={classes.grow} />
					<div className={classes.sectionDesktop}>
						{/*<IconButton aria-label="show 4 new mails" color="inherit">
							<Badge badgeContent={4} color="secondary">
								<MailIcon />
							</Badge>
						</IconButton>
						<IconButton aria-label="show 17 new notifications" color="inherit">
							<Badge badgeContent={17} color="secondary">
								<NotificationsIcon />
							</Badge>
						</IconButton>*/}
					</div>
					<Typography component="div" className={classes.sectionDesktop}>
						<Box className="text-capitalize" fontWeight="fontWeightMedium" m={1}>
							<AccountCircle /> {user.first_name} {user.last_name_f} {user.last_name_m}
						</Box>
					</Typography>
					<IconButton
						onClick={onLogout}
						aria-label=""
						color="inherit"
						className={classes.sectionDesktop}>
						<Typography component="div" className="text-capitalize">
							<Box fontWeight="fontWeightMedium" m={1}>
								<ExitToApp /> Salir
							</Box>
						</Typography>
					</IconButton>
					<div className={classes.sectionMobile}>
						<IconButton
							aria-label="show more"
							aria-controls={mobileMenuId}
							aria-haspopup="true"
							onClick={handleMobileMenuOpen}
							color="inherit"
						>
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{renderMenu}
			<div className={classes.divContainerComponent}>
				{component}
			</div>
		</div>
	);
};

export default Layout;

import React, {useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {useAuthDataContext} from '../../context/AuthContext';

const Logout = () => {
	const {onLogout, isAuthenticated} = useAuthDataContext();
	useEffect(() =>{
		onLogout();
		//eslint-disable-next-line
	}, []);

	return isAuthenticated ? <Redirect to="/logout" /> : <Redirect to="/" />;
};

export default Logout;

import React from 'react';
import Router from './routes';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AuthDataProvider from './context/AuthContext';
import UserDataProvider from './context/UserContext';

const App = () => {
	return (
		<div className="App">
			<BrowserRouter>
				<AuthDataProvider>
					<UserDataProvider>
						<Router />
					</UserDataProvider>
				</AuthDataProvider>
			</BrowserRouter>
		</div>
	);
};

export default App;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	filtersRoot: {
		'& .MuiTextField-root': {
			marginRight: theme.spacing(1),
			width: '30ch',
			textTransform: 'uppercase'
		}
	},
	filters: {
		marginTop: 10,
		marginBottom: 10,
	},
	margin: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	title: {
		color: '#1a1c20ab',
		fontWeight:'bold'
	},
	autocomplete: {
		width: '5ch !important',
		display: 'contents'
	},
	pagination: {
		textAlign: 'end',
		marginTop: 10,
		marginBottom: 10,
		'& .MuiPagination-root': {
			display: 'inline-grid'
		}
	},
	employeeName: {
		textTransform: 'capitalize',
		fontWeight: 'bold',
		fontStyle: 'italic'
	},
	uppercase: {
		textTransform: 'uppercase'
	},
	expired: {
		backgroundColor: 'yellow'
	}
}));

export default useStyles;

import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	Visibility,
	VisibilityOff
} from '@material-ui/icons';
import clsx from 'clsx';

import actions from './actions';
import reducer, { initialState } from './reducer';

import Loader from '../Loader/Loader';
import NumberFormat from "react-number-format";
import { UserService } from 'services/api/users'

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: '25ch',
			textTransform: 'uppercase'
		}
	},
	select: {
		margin: theme.spacing(1),
		width: '25ch',
	},
	seniority: {
		width: '5ch !important',
	},
	autocomplete: {
		width: '5ch !important',
		display: 'contents'
	},
	actionsContainer: {
		flexDirection: 'row-reverse'
	},
	actionButtons: {
		margin: 5
	},
	saveButton: {
		color: 'green'
	},
	upperTextLabel: {
		textTransform: 'uppercase'
	},
	eyeIcon: {
		cursor: 'pointer',
		color: 'grey'
	}
}));

const CustomUserForm = ({
	userId,
	onResponse,
	onCancel,
	regions,
	roles,
}) => {
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false);
	const [changePassword, setChangePassword] = useState(false);
	const [regionList, setRegionList] = useState(regions);
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		if (+userId > 0) {
			dispatch({ type: actions.showLoader, payload: true });
			UserService.fetch(userId)
				.then((response) => {
					if (response.data) {
						const userData = response.data;
						userData.password = '';
						userData.resend_email = false;
						dispatch({ type: actions.setIsUpdate, payload: true });
						dispatch({
							type: actions.setForm,
							payload: userData
						});
						dispatch({ type: actions.showLoader, payload: false });
						window.scrollTo(0, 0);
					}
				});
		} // eslint-disable-next-line
	}, [userId]);

	useEffect(() => {
		switch (+state.form.role) {
			case 2:
				dispatch({
					type: actions.setFormField,
					payload: {
						key: 'region',
						value: +state.form.region >= 8 ? 0 : state.form.region
					}
				});
				setRegionList(regions.filter(region => region.id < 8));
				break;
			case 4:
				dispatch({
					type: actions.setFormField,
					payload: {
						key: 'region',
						value: +state.form.region <= 7 ? 0 : state.form.region
					}
				});
				setRegionList(regions.filter(region => region.id > 7));
				break;

			default:
				setRegionList(regions);
		} // eslint-disable-next-line
	}, [state.form.role]);

	useEffect(() => {
		if (state.form.password || state.form.password === '') {
			dispatch({
				type: actions.setFormField,
				payload: {
					key: 'resend_email',
					value: false
				}
			});
		} // eslint-disable-next-line
	}, [state.form.password]);

	const handleTogglePassword = () => {
		setShowPassword(!showPassword);
	};

	const handleCancelForm = () => {
		dispatch({ type: actions.clearFormData });
		dispatch({ type: actions.setIsUpdate, payload: false });
		onCancel();
	};

	const handleAutocompleteChange = (event, value, name) => {
		if (!value) {
			dispatch({
				type: actions.setFormField,
				payload: {
					key: name,
					value: 0
				}
			});
		} else {
			dispatch({
				type: actions.setFormField,
				payload: {
					key: name,
					value: value.id
				}
			});
		}
	};

	const handleCreateUser = () => {
		dispatch({ type: actions.showModalLoader, payload: true });
		UserService.create(state.form)
			.then((response) => {
				if (response.data) {
					handleCancelForm();
				}
				dispatch({ type: actions.showModalLoader, payload: false });
				onResponse(response);
			});
	};

	const handleUpdateUser = () => {
		dispatch({ type: actions.showModalLoader, payload: true });
		UserService.update(state.form.id, state.form)
			.then(response => {
				if (response.data) {
					handleCancelForm();
				}
				dispatch({ type: actions.showModalLoader, payload: false });
				onResponse(response);
			}).catch((err) => console.log(err));
	};

	const handleSubmit = event => {
		event.preventDefault();
		if (state.isUpdate) {
			handleUpdateUser();
		} else {
			handleCreateUser();
		}
	};

	const handleCheckboxChange = event => {
		const { checked } = event.target;
		dispatch({
			type: actions.setFormField,
			payload: {
				key: event.target.name,
				value: checked
			}
		});
	};

	const handleChangeForm = useCallback((event) => {
		dispatch({
			type: actions.setFormField,
			payload: {
				key: event.target.name,
				value: event.target.value
			}
		})
	}, []);

	if (state.showLoader) {
		return <Loader />
	}

	return (
		<form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
			<Card variant="outlined">
				<CardContent>
					<div>
						<TextField
							onChange={handleChangeForm}
							value={state.form.first_name}
							name="first_name"
							label="Nombre(s)"
							type="text"
							required
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							onChange={handleChangeForm}
							value={state.form.last_name}
							name="last_name"
							label="Apellidos"
							type="text"
							required
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							onChange={handleChangeForm}
							value={state.form.email}
							id="email"
							name="email"
							label="Email"
							type="email"
							required
							InputLabelProps={{
								shrink: true,
							}}
						/>
						{
							!state.isUpdate &&
							<TextField
								onChange={handleChangeForm}
								value={state.form.password || ''}
								id="password"
								name="password"
								label="Contraseña"
								type={showPassword ? 'text' : 'password'}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment
											position="end"
											onClick={handleTogglePassword}
											className={classes.eyeIcon}>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</InputAdornment>
									),
								}}
								autoComplete="current-password"
							/>
						}
						<NumberFormat
							customInput={TextField}
							format="(###) ###-####" mask="_"
							onChange={handleChangeForm}
							value={state.form.phone_number || ''}
							name="phone_number"
							label="Teléfono"
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<Autocomplete
							className={classes.autocomplete}
							name="role"
							options={roles}
							onChange={(event, value) => handleAutocompleteChange(event, value, 'role')}
							getOptionLabel={(option) => option.name.toUpperCase()}
							value={roles.filter(role => role.id === state.form.role)[0] || null}
							required
							renderInput={(params) =>
								<TextField
									className={classes.upperTextLabel}
									required
									{...params}
									label="Rol"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
						/>
						<Autocomplete
							className={classes.autocomplete}
							id="region"
							name="region"
							options={regionList}
							onChange={(event, value) => handleAutocompleteChange(event, value, 'region')}
							getOptionLabel={(option) => option.name.toUpperCase()}
							value={
								regions.filter(region => +region.id === +state.form.region)[0] || null
							}
							required
							renderInput={(params) =>
								<TextField
									className={classes.upperTextLabel}
									required
									{...params}
									label="Región"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
						/>
						{
							state.isUpdate &&
							<div>
								<FormControlLabel
									onChange={event => setChangePassword(event.target.checked)}
									control={<Checkbox name="change_password" checked={changePassword} />}
									label="Cambiar contraseña?"
								/>
							</div>
						}
						{
							state.isUpdate && changePassword &&
							<TextField
								onChange={handleChangeForm}
								value={state.form.password || ''}
								id="password"
								name="password"
								label="Nueva contraseña"
								type={showPassword ? 'text' : 'password'}
								required
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment
											position="end"
											onClick={handleTogglePassword}
											className={classes.eyeIcon}>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</InputAdornment>
									),
								}}
								autoComplete="current-password"
							/>
						}
						{
							state.isUpdate && state.form.password &&
							<div>
								<FormControlLabel
									onChange={handleCheckboxChange}
									control={<Checkbox name="resend_email" checked={state.form.resend_email} />}
									label={`${state.form.resend_email ? `Se enviará un correo con la nueva contraseña a: ${state.form.email}` : 'Re-enviar correo?'}`}
								/>
							</div>
						}
					</div>
				</CardContent>
				<CardActions className={classes.actionsContainer}>
					{!state.showModalLoader &&
						<Button
							disabled={false}
							className={clsx(classes.actionButtons, classes.saveButton)}
							type="submit"
							variant="outlined"
							color="primary"
						>
							GUARDAR
					</Button>}
					{state.showModalLoader && <CircularProgress />}
					<Button
						className={classes.actionButtons}
						type="button"
						variant="outlined"
						onClick={handleCancelForm}
					>
						CANCELAR
					</Button>
				</CardActions>
			</Card>
		</form>
	);
};

export default CustomUserForm;

import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { Edit, FindInPage, Delete, PersonAdd, PlaylistAdd, StarBorder, Star } from '@material-ui/icons';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import useStyles from './styles';
import clsx from 'clsx';

import actions from './actions';
import reducer, { initialState } from './reducer';

import { useAuthDataContext } from '../../context/AuthContext';

import CustomCandidateForm from '../../components/CustomCandidateForm';
import Loader from '../../components/CustomLoader';
import Observations from '../../components/Observation';
import SearchBox from '../../components/Searchbox/Searchbox';

import getSeniorityFormat from '../../utils/getSeniorityFormat';
import { VARIABLES } from '../../general-constants';
import Alert from '@material-ui/lab/Alert/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CustomModal from '../../components/CustomModal/CustomModal';
import ClearFilters from '../../components/ClearFilters';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import { getAge } from '../../utils/common';
import { CandidateService } from '../../services/api/candidates';
import useScrollTop from 'hooks/useScrollTop';

const Candidates = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [inRequirement, setInRequirement] = useState([]);
	const classes = useStyles();
	const { user } = useAuthDataContext();

	useScrollTop(state.pageReady);

	//#region handlers

	const handleSearch = async (page = state.currentPage) => {
		const status = props.type === 'active' ? 1 : 2;
		let params = `?status=${status}`;
		if (+state.filters.region > 0) params = params + `&region=${state.filters.region}`;
		if (+state.filters.category > 0) params = params + `&category=${state.filters.category}`;
		if (+state.filters.relationship > 0) params = params + `&relationship=${state.filters.relationship}`;
		params = params + `&sort=${state.filters.order_by}`;
		params = params + `&page=${page}`
		if (+state.search.type === 1 && state.search.enrollment.trim() !== '') {
			params = params + `&enrollment=${state.search.enrollment.trim()}`
		}
		if (+state.search.type === 2) {
			if (state.search.first_name.trim() !== '') params = params + `&firstName=${state.search.first_name.trim()}`;
			if (state.search.last_name_f.trim() !== '') params = params + `&lastNameF=${state.search.last_name_f.trim()}`;
			if (state.search.last_name_m.trim() !== '') params = params + `&lastNameM=${state.search.last_name_m.trim()}`;
		}
		await CandidateService.search(params)
			.then((response) => {
				if (response.data) {
					dispatch({ type: actions.setCandidates, payload: response.data });
				}
				if (response.meta) {
					//dispatch({ type: actions.setTotalPages, value: response.meta.pages });
					dispatch({ type: actions.setPage, payload: response.meta.pages });
				}
				dispatch({ type: actions.showLoader, payload: false });
			})
			.catch((e) => console.log(e));
	};

	const handleSubmitSearchBox = event => {
		(async () => {
			event.preventDefault();
			dispatch({ type: actions.setCurrentPage, payload: 1 });
			dispatch({ type: actions.showLoader, payload: true });
			handleSearch(1);
		})();
	};

	const handleFiltersAutocompleteChange = (event, value, name) => {
		if (!value) {
			if (state.currentPage !== 1) {
				dispatch({
					type: actions.setCurrentPage,
					payload: 1
				})
			}
			dispatch({
				type: actions.setFilters,
				payload: {
					key: name,
					value: 0
				}
			});
		} else {
			if (state.currentPage !== 1) {
				dispatch({
					type: actions.setCurrentPage,
					payload: 1
				});
			}
			dispatch({
				type: actions.setFilters,
				payload: {
					key: name,
					value: value.id
				}
			});
		}
	};

	const handleRequirementsChange = (event, value, name) => {
		if (!value) {
			dispatch({
				type: actions.setRequirementToAdd,
				payload: 0
			});
		} else {
			dispatch({
				type: actions.setRequirementToAdd,
				payload: value.id
			});
		}
	};

	const handlePageChange = (event, page) => {
		dispatch({
			type: actions.setCurrentPage,
			payload: page
		});
	};

	const handlePrepareCandidateToUpdate = id => {
		dispatch({ type: actions.showForm, payload: true });
		dispatch({
			type: actions.setCandidateId,
			payload: id
		});
	};

	const handleResponse = response => {
		if (response.data) {
			dispatch({
				type: actions.setAlertProps,
				payload: {
					show: true,
					message: response.meta.message,
					severity: 'success'
				}
			});
			handleSearch();
		}
		if (response.error) {
			dispatch({
				type: actions.setAlertProps,
				payload: {
					show: true,
					message: response.error,
					severity: 'error'
				}
			});
		}
	};

	const handleCloseAlert = () => {
		dispatch({
			type: actions.setAlertProps,
			payload: {
				show: false,
				message: '',
				severity: ''
			}
		});
	};

	const handleCancelForm = () => {
		dispatch({
			type: actions.showForm, payload: false
		});
		dispatch({
			type: actions.setCandidateId, payload: 0
		});
	};

	const toggleShowForm = () => {
		dispatch({
			type: actions.showForm,
			payload: !state.showForm
		});
	};

	const handleOpenDeleteModal = (row) => {
		dispatch({ type: actions.setCandidateToRemove, payload: row });
		dispatch({ type: actions.showModalDelete, payload: true });
	};

	const handleOpenAddRequirementModal = (row) => {
		getRequirementList(row.id);
		dispatch({ type: actions.setCandidateToAssoc, payload: row });
		dispatch({ type: actions.showModalRequirement, payload: true });
	};

	const handleCloseAddRequirementModal = () => {
		dispatch({ type: actions.setCandidateToAssoc, payload: {} });
		dispatch({ type: actions.showModalRequirement, payload: false });
		dispatch({ type: actions.setRequirementToAdd, payload: 0 });
	};

	const handleAssocCandidate = () => {
		const candidate_id = state.candidateToAssoc.id,
			requirement_id = state.requirementToAdd
		dispatch({ type: actions.showModalLoader, payload: true });
		CandidateService.addToRequirement({ candidate_id, requirement_id })
			.then(response => {
				if (response.data) {
					handleGetCandidatesInRequirement();
					dispatch({ type: actions.showModalLoader, payload: false });
					dispatch({ type: actions.showModalRequirement, payload: false });
					dispatch({
						type: actions.setAlertProps,
						payload: {
							show: true,
							message: 'Se ha añadido al requerimiento',
							severity: 'success'
						}
					});
					handleCloseAddRequirementModal();
					handleSearch()
				}
			}).catch(err => console.log(err))
	};

	const handleDeleteCandidate = () => {
		(async () => {
			dispatch({ type: actions.showModalLoader, payload: true });
			const response = await CandidateService.remove(state.candidateToRemove.id);
			if (response.data) {
				dispatch({ type: actions.showModalLoader, payload: false });
				dispatch({ type: actions.showModalDelete, payload: false });
				dispatch({
					type: actions.setAlertProps,
					payload: {
						show: true,
						message: response.meta.message || '',
						severity: 'success'
					}
				});
				handleSearch()
			} else {
				dispatch({
					type: actions.setAlertProps,
					payload: {
						show: true,
						message: 'Ocurrió un error,por favor intenta de nuevo, si el problema persiste contacta a soporte',
						severity: 'error'
					}
				});
			}
		})();
	};

	const handleCloseDeleteModal = () => {
		dispatch({ type: actions.setCandidateToRemove, payload: {} });
		dispatch({ type: actions.showModalDelete, payload: false });
	};

	const handleCheckboxChange = (checked, candidateId, next = null) => {
		if (checked) {
			dispatch({ type: actions.showLoader, payload: true });
			CandidateService.addToNextReq(candidateId)
				.then(response => {
					dispatch({ type: actions.showLoader, payload: false });
					if (response.data) {
						handleSearch();
						dispatch({
							type: actions.setAlertProps,
							payload: {
								show: true,
								message: response.meta.message || '',
								severity: 'success'
							}
						});
					}
				})
				.catch(err => console.log(err))
		} else {
			dispatch({ type: actions.showLoader, payload: true });
			CandidateService.removeFromNextReq(candidateId)
				.then(response => {
					dispatch({ type: actions.showLoader, payload: false });
					if (response.data) {
						handleSearch();
						dispatch({
							type: actions.setAlertProps,
							payload: {
								show: true,
								message: response.meta.message || '',
								severity: 'success'
							}
						});
					}
				})
				.catch(err => console.log(err))
		}
	};

	const getRequirementList = (candidateId = null) => {
		CandidateService.getRequirements(candidateId)
			.then(response => {
				if (response.data) {
					dispatch({
						type: actions.setRequirements,
						payload: response.data
					});
				}
			})
	};

	const isCategoryLimited = (birthday, categoryAge) => {
		return +getAge(birthday) > categoryAge;
	};

	const handleViewData = id => {
		props.history.push(`/trabajador/informacion/${id}`);
	};

	const handleShowClearFilters = () => {
		return +state.filters.region > 0 ||
			+state.filters.relationship > 0 ||
			+state.filters.category > 0 ||
			+state.filters.order_by > 0 ||
			state.search.enrollment.trim() !== '' ||
			state.search.first_name.trim() !== '' ||
			state.search.last_name_f.trim() !== '' ||
			state.search.last_name_m.trim() !== '';
	};

	const handleResetFilters = () => {
		dispatch({ type: actions.clearTerm });
		dispatch({ type: actions.resetFilters, payload: user.role });
	};

	const setRegions = (regions = []) => {
		if (props.type === 'inactive') {
			regions = regions.filter((region) => region.id > 8 && region.id !== 19 && region.name !== 'N/A');
		} else {
			regions = regions.filter((region) => region.id < 8);
		}
		dispatch({ type: actions.setRegions, payload: regions });
	};
	const setCategories = (categories = []) => {
		categories = categories.filter(category => category.name !== 'N/A');
		dispatch({ type: actions.setCategories, payload: categories });
	};
	const setRelationships = (relationships = []) => {
		dispatch({
			type: actions.setRelationships,
			payload: relationships
		});
		const filtered = relationships.filter(relationship => relationship.id > 2);
		filtered.push({ id: 1, name: 'Hijo / Esposo / Esposa' });
		dispatch({
			type: actions.setRelationshipOrder,
			payload: filtered.sort((a, b) => a.id - b.id)
		});
	};
	const setLevelOfStudy = (levelOfStudy = []) => {
		dispatch({ type: actions.setScholarships, payload: levelOfStudy });
	}
	const handleRadioChange = event => {
		const value = event.target.value;
		dispatch({
			type: actions.setSearchType,
			payload: {
				key: 'type',
				value
			}
		});
	}
	const handleChangeSearchBox = event => {
		let value = event.target.value.replace(/[^a-z0-9 ]/gi, '')
		if (+state.search.type === 1) {
			value = value.replace(/[^0-9]+/g, "")
		}
		dispatch({
			type: actions.setSearchType,
			payload: {
				key: event.target.id,
				value
			}
		});
	};
	const handleGetCandidatesInRequirement = async () => {
		const { data, error } = await CandidateService.getCandidatesInRequirements();

		if (data) {
			setInRequirement(data);
		}
		if (error) {
			throw new Error(error);
		}
	}
	//#endregion

	//#region effects
	useEffect(() => {
		(async function initialize() {
			const { history: { location } } = props;
			CandidateService.getRegions()
				.then(response => response.data)
				.then(regionList => {
					setRegions(regionList);
				});
			CandidateService.getCategories()
				.then(response => response.data)
				.then(categoryList => {
					setCategories(categoryList);
				});
			CandidateService.getRelationships()
				.then(response => response.data)
				.then(list => {
					setRelationships(list)
				});
			CandidateService.getLevelOfStudy()
				.then(response => response.data)
				.then(list => {
					setLevelOfStudy(list)
				});
			handleGetCandidatesInRequirement();

			if (location.state && location.state.hasOwnProperty('candidateId')) {
				dispatch({ type: actions.showForm, payload: true });
				dispatch({
					type: actions.setCandidateId,
					payload: location.state.candidateId
				});
				props.history.replace({ state: null });
			}
			await handleSearch();
			dispatch({ type: actions.setPageReady, payload: true })
		})(); // eslint-disable-next-line
	}, []);

	useEffect(() => {
		CandidateService.getRegions()
			.then(response => response.data)
			.then(regionList => {
				setRegions(regionList);
			});
		const currentPage = state.currentPage;
		if (+currentPage > 1) {
			dispatch({ type: actions.setCurrentPage, payload: 1 });
		} else {
			dispatch({ type: actions.showLoader, payload: true });
			handleSearch()
		}
		//eslint-disable-next-line
	}, [props.type]);

	useEffect(() => {
		(async () => {
			if (state.pageReady) {
				dispatch({ type: actions.showLoader, payload: true });
				await handleSearch();
			}
		})(); // eslint-disable-next-line
	}, [state.filters, state.currentPage]);
	//#endregion

	if (!state.pageReady) {
		return <Loader backdrop />
	}

	return (
		<Fragment>
			{state.showLoader && <Loader backdrop />}
			<Container>
				<Typography
					className={classes.title}
					variant="h5"
					gutterBottom>
					Aspirantes
					<IconButton
						aria-label="delete"
						className={classes.margin}
						onClick={toggleShowForm}
					>
						<PersonAdd fontSize="default" />
					</IconButton>
				</Typography>

				<div className={state.showForm ? '' : classes.hide}>
					<CustomCandidateForm
						showForm={state.showForm}
						candidateId={state.candidateId}
						onResponse={handleResponse}
						onCancel={handleCancelForm}
						form={state.form}
						regions={state.regions}
						relationships={state.relationships}
						categories={state.categories}
						affiliations={state.affiliations}
						scholarships={state.scholarships}
						type={props.type}
					/>
				</div>

				<div className={classes.filters}>
					<div className={classes.filtersRoot}>
						<Card variant="outlined" style={{ padding: 16 }}>
							<Autocomplete
								className={classes.autocomplete}
								disabled={user.role > 1}
								name="region"
								options={state.regions.sort((a, b) => a.name.localeCompare(b.name))}
								onChange={(event, value) => handleFiltersAutocompleteChange(event, value, 'region')}
								getOptionLabel={(option) => option.name.toUpperCase()}
								value={
									user.role !== 1 ?
										state.regions.filter(region => region.id === user.region)[0] || null :
										state.regions.filter(region => region.id === state.filters.region)[0] || null
								}
								renderInput={(params) =>
									<TextField
										required
										{...params}
										label="Región"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								}
							/>

							<Autocomplete
								className={classes.autocomplete}
								name="category"
								options={state.categories}
								onChange={(event, value) => handleFiltersAutocompleteChange(event, value, 'category')}
								getOptionLabel={(option) => option.name.toUpperCase()}
								value={
									state
										.categories
										.filter(category => category.id === state.filters.category)[0] || null}
								renderInput={(params) =>
									<TextField
										required
										{...params}
										label="categoría"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								}
							/>

							<Autocomplete
								className={classes.autocomplete}
								name="relationship"
								options={state.relationship_order}
								onChange={(event, value) => handleFiltersAutocompleteChange(event, value, 'relationship')}
								getOptionLabel={(option) => option.name.toUpperCase()}
								value={state.relationship_order.filter(relationship => relationship.id === state.filters.relationship)[0] || null}
								renderInput={(params) =>
									<TextField
										required
										{...params}
										label="Parentesco"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								}
							/>

							<Autocomplete
								className={classes.autocomplete}
								name="seniority"
								options={[
									{ id: 1, name: 'En requerimiento' },
									{ id: 2, name: 'Próximo requerimiento' },
								]}
								onChange={(event, value) => handleFiltersAutocompleteChange(event, value, 'order_by')}
								getOptionLabel={(option) => option.name.toUpperCase()}
								value={[
									{ id: 1, name: 'En requerimiento' },
									{ id: 2, name: 'Próximo requerimiento' }
								].filter(seniority => seniority.id === state.filters.order_by)[0] || null}
								renderInput={(params) =>
									<TextField
										required
										{...params}
										label="Ordenar por"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								}
							/>

							<SearchBox
								enrollment={state.search.enrollment}
								firstName={state.search.first_name}
								lastNameF={state.search.last_name_f}
								lastNameM={state.search.last_name_m}
								placeholder={'Buscar por ...'}
								handleChange={handleChangeSearchBox}
								handleRadio={handleRadioChange}
								searchPerform={handleSubmitSearchBox}
								searchType={state.search.type}
							/>

							<ClearFilters
								show={handleShowClearFilters()}
								onClearFilters={handleResetFilters}
							/>
						</Card>
					</div>
				</div>

				<div className={classes.pagination}>
					<Pagination
						onChange={handlePageChange}
						count={state.pages}
						page={state.currentPage}
					/>
				</div>

				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell> </TableCell>
								<TableCell>{VARIABLES.NAME}</TableCell>
								<TableCell>{VARIABLES.LAST_NAMES}</TableCell>
								<TableCell>{VARIABLES.EMPLOYEE}</TableCell>
								<TableCell>Edad</TableCell>
								<TableCell>{VARIABLES.SENIORITY}</TableCell>
								<TableCell>{VARIABLES.CATEGORY}</TableCell>
								<TableCell align="center">{VARIABLES.OBSERVATIONS}</TableCell>
								<TableCell align="center">{VARIABLES.OPTIONS}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{state.candidates.map((row) => {
								const seniority = row.seniority ? getSeniorityFormat(row.seniority) : null;
								const hasRequirement = (inRequirement.includes(row.id)) ? classes.inRequirement : classes.notInRequirement;
								const hasCategoryLimit = isCategoryLimited(row.birthday, row.category_limit) ? classes.categoryLimited : '';
								return (
									<TableRow key={row.id} className={clsx(hasRequirement)}>
										<TableCell component="th" scope="row">
											<FormControlLabel
												onChange={(event) => handleCheckboxChange(event.target.checked, row.id, row.next)}
												control={<Checkbox icon={<StarBorder style={{ color: '#FFA500' }} />} checkedIcon={<Star style={{ color: '#FFA500' }} />} name="next" checked={row.next ? true : false} />}
												label=""
											/>
										</TableCell>
										<TableCell
											className={clsx([{
												[classes.uppercaseText]: true,
												[classes.categoryLimitedText]: hasCategoryLimit,
											}])}
										>
											{row.first_name}
										</TableCell>
										<TableCell
											className={clsx([{
												[classes.uppercaseText]: true,
												[classes.categoryLimitedText]: hasCategoryLimit,
											}])}
										>
											{row.last_name_f} {row.last_name_m}
										</TableCell>
										<TableCell className={classes.uppercaseText}>{row.employee_full_name}</TableCell>
										<TableCell>{getAge(row.birthday)}</TableCell>
										{seniority && <TableCell>{seniority.years} - {seniority.fortnights} - {seniority.days}</TableCell>}
										{!seniority && <TableCell> </TableCell>}
										<TableCell className={classes.uppercase}>{row.category}</TableCell>
										<TableCell align="center">
											{row.candidate_observations && <Observations variant="warning" type="Aspirante" observation={row.candidate_observations} />}
											{row.employee_observations && <Observations observation={row.employee_observations} />}
										</TableCell>
										<TableCell align="center" style={{ display: 'flex' }}>
											<IconButton
												title="Ver información"
												aria-label="search"
												className={classes.margin}
												onClick={() => handleViewData(row.employee_id)}
											>
												<FindInPage fontSize="default" />
											</IconButton>
											<IconButton
												title="Añadir a requerimiento"
												aria-label="add"
												className={classes.margin}
												onClick={() => handleOpenAddRequirementModal(row)}
											>
												<PlaylistAdd fontSize="default" />
											</IconButton>
											<IconButton
												title="Actualizar información"
												aria-label="update"
												className={classes.margin}
												onClick={() => handlePrepareCandidateToUpdate(row.id)}
											>
												<Edit fontSize="default" />
											</IconButton>
											<IconButton
												title="Eliminar"
												aria-label="delete"
												className={classes.margin}
												onClick={() => handleOpenDeleteModal(row)}
											>
												<Delete fontSize="default" />
											</IconButton>
										</TableCell>
									</TableRow>
								)
							})}

							{state.candidates.length <= 0 && (
								<TableRow>
									<TableCell colSpan={9} align="center">
										No se encontraron resultados
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>

				<div className={classes.pagination}>
					<Pagination
						onChange={handlePageChange}
						count={state.pages}
						page={state.currentPage}
					/>
				</div>

				<CustomModal
					title="Eliminar información del aspirante"
					open={state.showModalDelete}
					confirm={handleDeleteCandidate}
					close={handleCloseDeleteModal}
					buttonText="Aceptar"
					disabled={false}
					showLoader={state.showModalLoader}
				>
					Se eliminará la información del aspirante
					<span className={classes.employeeName} style={{ textTransform: 'capitalize' }}> {state.candidateToRemove.first_name || ''} {state.candidateToRemove.last_name_f || ''} {state.candidateToRemove.last_name_m || ''} </span>
					y será removido de los requerimientos asociados.
				</CustomModal>

				<CustomModal
					fullWidth
					title="Agregar aspirante a requerimiento"
					open={state.showModalRequirement}
					confirm={handleAssocCandidate}
					close={handleCloseAddRequirementModal}
					buttonText="Aceptar"
					disabled={(state.requirementToAdd === 0 || state.candidateToAssoc.id === 0)}
					showLoader={state.showModalLoader}
				>
					<Typography component="h5">
						Lista de requerimientos
					</Typography>
					<Autocomplete
						name="region"
						options={state.requirements}
						onChange={(event, value) => handleRequirementsChange(event, value, 'requirement')}
						getOptionLabel={(option) => option.name.toUpperCase()}
						value={
							state.requirements.filter(requirement => requirement.id === state.requirementToAdd)[0] || null
						}
						renderInput={(params) =>
							<TextField
								required
								{...params}
								label="Requerimiento"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						}
					/>

				</CustomModal>

				<Snackbar
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					open={state.alertProps.show}
					autoHideDuration={3000}
					onClose={handleCloseAlert}>
					<Alert onClose={handleCloseAlert} severity={state.alertProps.severity}>
						<p className={classes.paragraphError}>
							{state.alertProps.message}
						</p>
					</Alert>
				</Snackbar>

			</Container>
		</Fragment>
	);
};

export default Candidates;

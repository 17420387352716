import moment from 'moment';

export const getNumberMask = (number) => {
	if (!number) {
		return '';
	}
	if (number.length < 10) {
		return number;
	}
	return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, number.length)}`;
};

export const getAge = (dob) => {
	return moment().diff(moment(dob).format('YYYY-MM-DD'), 'years', false);
};

export const getDestructuredAge = (birthday) => {
	const currentDate = moment();
	currentDate.set({hour: 0, minute: 0, second: 0, millisecond: 0});
	const dob = moment(birthday);
	const years = currentDate.diff(dob, 'years');
	const monthDifference = (+years * 12);
	const months = currentDate.diff(dob, 'months');
	const realMonths = (+months) - (+monthDifference);
	const dateMinusYears = currentDate.subtract(years, 'years').format('YYYY-MM-DD');
	const differenceByMonths = moment(dateMinusYears).subtract(realMonths, 'months').format('YYYY-MM-DD');
	const daysDifference = moment(differenceByMonths).diff(dob, 'days');

	return {
		years,
		months: realMonths,
		days: daysDifference
	}
};

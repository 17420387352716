import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Box from '@material-ui/core/Box';
import { useAuthDataContext } from 'context/AuthContext';
import Drawer from 'components/Drawer';
import Logo from '../../logo2.png';
import clsx from 'clsx';
import history from '../../history';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: '#4ca746'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textAlign: 'start'
    },
    logo: {
        width: 50,
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: 50,
        },
    },
    mobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    desktop: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    button: {
        backgroundColor: 'inherit !important'
    }
}));

export default function MenuAppBar() {
    const classes = useStyles();
    const { onLogout, user } = useAuthDataContext();
    const [show, setShow] = React.useState(false);

    const handleTogggleShow = (flag = true) => {
        setShow(flag)
    }

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => history.push('/')}
                    >
                        <img src={Logo} className={classes.logo} alt="logo" />
                    </IconButton>
                    <IconButton onClick={() => handleTogggleShow(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={clsx(classes.title, classes.mobile)}>
                        SIATSS - SNTSS sección XII, Coahuila
                    </Typography>
                    <Typography variant="h6" className={clsx(classes.title, classes.desktop)}>
                        SIATSS
                    </Typography>
                    <Typography component="div" className={clsx(classes.sectionDesktop, classes.mobile)}>
                        <Box className="text-capitalize" fontWeight="fontWeightMedium" m={1}>
                            <AccountCircle /> {user.first_name} {user.last_name_f}
                        </Box>
                    </Typography>

                    <IconButton
                        onClick={onLogout}
                        aria-label=""
                        color="inherit"
                        disableFocusRipple={true}
                        disableRipple={true}
                        className={clsx(classes.sectionDesktop, classes.button, classes.mobile)}>
                        <Typography component="div" className="text-capitalize">
                            <Box fontWeight="fontWeightMedium" m={1}>
                                <ExitToApp /> Salir
							</Box>
                        </Typography>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer show={show} toggleShow={handleTogggleShow} />
        </div>
    );
}

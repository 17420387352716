import { useEffect } from 'react'

const useScrollTop = (pageReady) => {
  useEffect(() => {
    if (pageReady) {
      console.log('scrolled!!')
      setTimeout(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }, 500)
    }
  }, [pageReady])
}

export default useScrollTop

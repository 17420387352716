import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthDataContext } from 'context/AuthContext';
import redirectTo from 'utils/redirectTo';

const PrivateRoute = ({ component, roles = [], ...options }) => {
    const { isAuthenticated, user } = useAuthDataContext();

    if (!isAuthenticated) {
        return <Redirect to="/" />;
    }

    if (!roles.includes(user.role)) {
        return <Redirect to={redirectTo(user.role)} />;
    }

    return <Route {...options} />;
};

export default PrivateRoute;

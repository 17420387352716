const useFetch = (endpoint, token = false) => {
	const defaultHeader = {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	};
	if (token) {
		defaultHeader.Authorization = `Bearer ${token}`;
	}
	const customFetch = (
		url,
		method = 'GET',
		body = false,
		headers = defaultHeader
	) => {
		const options = {
			method,
			headers
		};
		if (body) options.body = JSON.stringify(body);
		return fetch(url, options)
			.then((response) => response.json())
			.catch((err) => {
				throw new Error(err);
			});
	};
	const get = (path) => {
		const url = `${endpoint}${path}`;
		return customFetch(url);
	};
	const post = (path, body = false) => {
		if (!path) throw new Error('to make a post you must provide a     path');
		if (!body) throw new Error('to make a post you must provide a     body');
		return customFetch(endpoint + path, 'POST', body);
	};
	const put = (path, body = false) => {
		if (!path) {
			throw new Error('to make a put you must provide the path');
		}
		const url = `${endpoint}${path}`;
		return customFetch(url, 'PUT', body);
	};
	const remove = (path) => {
		const url = `${endpoint}${path}`;
		return customFetch(url, 'DELETE');
	};
	const getImage = (url) => {
		const headers = {};

		if (token) {
			headers.Authorization = `Bearer ${token}`;
		}

		const path = `${endpoint}${url}`;
		return fetch(path, {
			method: 'get',
			headers
		}).then((response) => response.blob());
	};

	const uploadFile = (url, {file, file_name, region}) => {
		const headers = {};

		if (token) {
			headers.Authorization = `Bearer ${token}`;
		}

		const formData = new FormData();
		formData.append('file', file);
		formData.append('filename', file_name);
		formData.append('region', region);

		return fetch(endpoint + url, {
			method: 'post',
			headers,
			body: formData
		}).then((response) => response.json());
	};

	return {
		get,
		getImage,
		post,
		put,
		remove,
		uploadFile
	};
};

export default useFetch;

import React, { Fragment, useState, useEffect, useRef } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Loader from '../../components/CustomLoader';
import IconButton from '@material-ui/core/IconButton';
import { Delete, NoteAdd, GetApp } from '@material-ui/icons';
import CustomModal from '../../components/CustomModal/CustomModal';
import Alert from '@material-ui/lab/Alert/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import download from "js-file-download";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useAuthDataContext } from '../../context/AuthContext';
import useStyles from './styles';
import { ResultService } from 'services/api/results';
import useScrollTop from '../../hooks/useScrollTop'

const Results = () => {
	const classes = useStyles();
	const { user } = useAuthDataContext();
	const [pageReady, setPageReady] = useState(false);
	const [showModalLoader, toggleShowModalLoader] = useState(false);
	const [showAddFileModal, toggleAddFileModal] = useState(false);
	const [results, setResults] = useState([]);
	const [regionList, setRegions] = useState([
		{ id: 1, name: "saltillo" }
	]);
	const [selectedRegion, setSelectedRegion] = useState(0);
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const fileRef = useRef(null);
	const [alertProps, setAlertProps] = useState({
		show: false,
		message: '',
		severity: ''
	});
	const [selectedDocument, setselectedDocument] = useState(null);
	const [showDeleteModal, toggleDeleteModal] = useState(false);

	const getAllDocuments = async () => {
		const { data } = await ResultService.index();

		if (data) {
			setResults(data);
		}
	};

	const openDeleteModal = (id) => {
		setselectedDocument(id);
		toggleDeleteModal(true);
	};

	const closeDeleteModal = () => {
		setselectedDocument(null);
		toggleDeleteModal(false);
	};

	const closeAddModal = () => {
		setFile(null);
		setFileName('');
		setSelectedRegion(0);
		fileRef.current.value = null;
		toggleAddFileModal(false);
	};

	const removeDocument = () => {
		(async () => {
			toggleShowModalLoader(true);
			const { data, meta, error } = await ResultService.remove(selectedDocument);
			toggleShowModalLoader(false);
			if (data) {
				await getAllDocuments();
				closeDeleteModal();
				setAlertProps({
					show: true,
					message: meta.message,
					severity: 'success'
				})
			}
			if (error) {
				setAlertProps({
					show: true,
					message: error,
					severity: 'error'
				})
			}
		})();
	};

	const handleCloseAlert = () => {
		setAlertProps({
			show: false,
			message: '',
			severity: ''
		})
	};

	const onChangeFile = (event) => {
		setFile(event.target.files[0]);
	};

	const onInputChange = (event) => {
		setFileName(event.target.value);
	};

	const uploadFile = () => {
		(async () => {
			const formData = new FormData();
			formData.append('file', file);
			if (fileName !== '') formData.append('filename', fileName);
			formData.append('region_id', selectedRegion);

			const { data, meta, error } = await ResultService.create(formData);
			toggleShowModalLoader(false);

			if (data) {
				await getAllDocuments();
				setAlertProps({
					show: true,
					message: meta.message,
					severity: 'success'
				});
				closeAddModal();
			}
			if (error) {
				setAlertProps({
					show: true,
					message: error,
					severity: 'error'
				});
			}
		})();
	};

	const viewDocument = (document) => {
		(async () => {
			const response = await ResultService.view(document.id);

			if (document.ext !== 'application/pdf') {
				download(response, document.path);
			} else {
				// Create a Blob from the pdf Stream
				const file = new Blob([response], {
					type: 'application/pdf'
				});
				// Build a URL from the file
				const fileURL = URL.createObjectURL(file);
				// Open the URL on new Window
				window.open(fileURL);
			}
		})();
	};

	const handleFiltersAutocompleteChange = (event, value, name) => {
		if (!value) {
			setSelectedRegion(0)
		} else {
			setSelectedRegion(value.id);
		}
	};

	useEffect(() => {
		(async () => {
			await getAllDocuments();
			const { data, error } = await ResultService.getRegions();
			if (data) {
				const regions = data.filter(region => region.name !== 'N/A');
				setRegions(regions);
			}
			if (error) {
				setAlertProps({
					show: true,
					message: error,
					severity: 'error'
				})
			}
			setPageReady(true);
		})(); // eslint-disable-next-line
	}, []);

	useScrollTop(pageReady);

	if (!pageReady) {
		return <Loader backdrop />;
	}

	return (
		<Fragment>
			<Container>
				<Typography
					className={classes.title}
					variant="h5"
					gutterBottom>
					Lista de resultados
					<IconButton
						aria-label="delete"
						className={classes.margin}
						onClick={() => toggleAddFileModal(true)}
					>
						<NoteAdd fontSize="default" />
					</IconButton>
				</Typography>

				<Box style={{ marginTop: 20 }}>
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>ID</TableCell>
									<TableCell align="left">Nombre del archivo</TableCell>
									<TableCell align="left">Región</TableCell>
									<TableCell align="left">Ruta</TableCell>
									<TableCell align="left">Extensión</TableCell>
									<TableCell align="left"> </TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{results.map((row) => (
									<TableRow key={row.id}>
										<TableCell>{row.id}</TableCell>
										<TableCell align="left">{row.filename}</TableCell>
										<TableCell align="left" className="text-capitalize">{row.Region.region}</TableCell>
										<TableCell align="left">{row.path}</TableCell>
										<TableCell align="left">{row.ext}</TableCell>
										<TableCell align="center" style={{ display: 'flex' }}>
											<IconButton
												title="Actualizar información"
												aria-label="update"
												className={classes.margin}
												onClick={(f) => viewDocument(row)}
											>
												<GetApp fontSize="default" />
											</IconButton>
											<IconButton
												title="Eliminar"
												aria-label="delete"
												className={classes.margin}
												onClick={() => openDeleteModal(row.id)}
											>
												<Delete fontSize="default" />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Container>

			<CustomModal
				title="Eliminar resultado"
				open={showDeleteModal}
				confirm={removeDocument}
				close={closeDeleteModal}
				buttonText="Aceptar"
				disabled={false}
				showLoader={showModalLoader}
			>
				Deseas eliminar el resultado?
			</CustomModal>

			<CustomModal
				fullWidth
				title="Cragar documento"
				open={showAddFileModal}
				confirm={uploadFile}
				close={() => closeAddModal()}
				buttonText="Aceptar"
				disabled={!file || selectedRegion <= 0}
				showLoader={showModalLoader}
			>
				<div className={classes.root}>
					<Box style={{ display: 'block' }}>
						<input
							ref={fileRef}
							accept=".pdf, .docx, .csv"
							className={classes.input}
							id="icon-button-file"
							type="file"
							onChange={onChangeFile}
						/>
						<label htmlFor="icon-button-file">
							<IconButton color="default" aria-label="upload picture" component="span">
								<NoteAdd />
							</IconButton>
						</label>
						{file && file.name}
						{!file && `Da click en el icono y selecciona el documento`}
					</Box>

					<Box>
						<Autocomplete
							className={classes.autocomplete}
							disabled={user.role > 1}
							name="region"
							options={regionList}
							onChange={(event, value) => handleFiltersAutocompleteChange(event, value, 'region')}
							getOptionLabel={(option) => option.name.toUpperCase()}
							value={
								user.role !== 1 ?
									regionList.filter(region => region.id === user.region)[0] || null :
									regionList.filter(region => region.id === selectedRegion)[0] || null
							}
							renderInput={(params) =>
								<TextField
									required
									{...params}
									label="Región"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
						/>
					</Box>

					<Box style={{ display: 'block' }}>
						<TextField
							style={{ width: '100%' }}
							onChange={onInputChange}
							value={fileName}
							id="filename"
							name="filename"
							label="Nombre del documento (opcional)"
							type="text"
							required
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>
				</div>
			</CustomModal>

			<Snackbar
				open={alertProps.show}
				autoHideDuration={3000}
				onClose={handleCloseAlert}>
				<Alert onClose={handleCloseAlert} severity={alertProps.severity}>
					<p className={classes.paragraphError}>
						{alertProps.message}
					</p>
				</Alert>
			</Snackbar>
		</Fragment>
	);
};

export default Results;

import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { PictureAsPdf } from '@material-ui/icons';

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #d3d4d5',
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {
		'&:focus': {
			//backgroundColor: theme.palette.primary.main,
			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: theme.palette.common.black,
			},
		},
	},
	display: {
		display: 'contents'
	}
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'contents',
		[theme.breakpoints.down('xs')]: {
			display: 'grid',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'inline',
		},
	},
	button: {
		marginRight: 10,
		[theme.breakpoints.down('xs')]: {
			margin: 10,
		},
		[theme.breakpoints.down('sm')]: {
			margin: 10,
		}
	}
}));

export default function DocumentList({ documents = [], label = '', onDownload }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const classes = useStyles();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDownload = (document) => {
		onDownload(document);
	};

	return (
		<div className={classes.root}>
			<Button
				className={classes.button}
				aria-controls="customized-menu"
				aria-haspopup="true"
				variant="contained"
				onClick={handleClick}
			>
				{label}
			</Button>
			<StyledMenu
				id="customized-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{documents.map(document => (
					<StyledMenuItem key={document.id} onClick={() => handleDownload(document)}>
						<ListItemIcon>
							<PictureAsPdf fontSize="small" />
						</ListItemIcon>
						<ListItemText primary={document.filename} />
					</StyledMenuItem>
				))}
			</StyledMenu>
		</div>
	);
}

import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import moment from 'moment';

export default function MaterialUIPickers({ value, onChange, name, label= '', required }) {

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
			<KeyboardDatePicker
				variant="inline"
				format="dd/MM/yyyy"
				margin="normal"
				id={name}
				name={name}
				label={label}
				required={required}
				value={
					value ?
						moment(value).startOf('day') :
						null
				}
				onChange={onChange}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
				InputLabelProps={{
					shrink: true,
				}}
			/>
		</MuiPickersUtilsProvider>
	);
}

import actions from './actions';

const form = {
	id: '',
	enrollment: '',
	first_name: '',
	last_name_f: '',
	last_name_m: '',
	email: '',
	password: '',
	years: '',
	fortnights: '',
	days: '',
	telephone: '',
	cell_phone: '',
	status: '',
	region: 0,
	category: 0,
	affiliation: 0,
	observations: '',
	membership: '',
	resend_email: false
};

export const initialState = {
	hasDifferences: false,
	differences: [],
	isUpdate: false,
	showLoader: false,
	showModalLoader: false,
	form: form,
	originalData: form,
};

const reducer = (state, action) => {
	switch (action.type) {
		case actions.showLoader: {
			return {
				...state,
				showLoader: action.payload
			}
		}
		case actions.setFormField: {
			return {
				...state,
				form: {
					...state.form,
					[action.payload.key]: action.payload.value
				}
			}
		}
		case actions.setForm: {
			return {
				...state,
				form: action.payload
			}
		}
		case actions.setIsUpdate: {
			return {
				...state,
				isUpdate: action.payload
			}
		}
		case actions.clearFormData: {
			return {
				...state,
				form: {
					id: '',
					enrollment: '',
					first_name: '',
					last_name_f: '',
					last_name_m: '',
					email: '',
					password: '',
					years: '',
					fortnights: '',
					days: '',
					telephone: '',
					cell_phone: '',
					status: '',
					region: 0,
					category: 0,
					affiliation: 0,
					observations: '',
					membership: '',
					resend_email: false
				},
			};
		}
		case actions.setHasDifferences: {
			return {
				...state,
				hasDifferences: action.payload
			};
		}
		case actions.setDifferences: {
			return {
				...state,
				differences: action.payload
			};
		}
		case actions.showModalLoader: {
			return {
				...state,
				showModalLoader: action.payload
			};
		}
		case actions.setOriginalData: {
			return {
				...state,
				originalData: action.payload
			};
		}

		default: {
			return {
				...state
			}
		}
	}
};

export default reducer;

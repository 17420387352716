const adminRoles = [1, 2];
const retiredRoles = [4];
const guestRoles = [3];
const temporalRoles = [5];

const redirectTo = (role) => {
	if (adminRoles.includes(role)) {
		return '/trabajadores-activos';
	}
	if (retiredRoles.includes(role)) {
		return '/trabajadores-inactivos';
	}
	if (guestRoles.includes(role)) {
		return '/trabajador';
	}
	if (temporalRoles.includes(role)) {
		return '/plazas';
	}
};

export default redirectTo;

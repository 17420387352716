import moment from 'moment';

const getSeniorityFormat = (seniority = moment()) => {
	const a = moment();
	a.set({hour: 0, minute: 0, second: 0, millisecond: 0});
	const b = moment(seniority);
	const years = a.diff(b, 'years');
	const date_without_years = a.subtract(years, 'years');
	const diff_days = date_without_years.diff(b, 'days');
	const fortnights = diff_days / 15;
	const f_string = fortnights.toString();
	const array_f = f_string.split('.');
	const quincenas = array_f[0];
	const quincenas_en_dias = parseInt(quincenas) * 15;
	const days = diff_days - quincenas_en_dias;

	return {
		years: years,
		fortnights: quincenas,
		days: days
	}
};

export default getSeniorityFormat;

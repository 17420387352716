export const form = {
	id: { value: '', error: '' },
	first_name: { value: '', error: '' },
	last_name: { value: '', error: '' },
	email: { value: '', error: '' },
	password: { value: '', error: '' },
	phone_number: { value: '', error: '' },
	region: { value: '', error: '' },
	role: { value: '', error: '' },
	resend_email: { value: false, error: '' }
};

export const validations = {
	first_name: {
		required: true,
		validator: {
			regEx: /^[a-zA-ZáéíóúÁÉÍÓÚ,]+(\s{0,1}[a-zA-ZáéíóúÁÉÍÓÚ, ])*$/,
			error: 'Solo de admiten letras.'
		}
	},
	last_name: {
		required: true,
		validator: {
			regEx: /^[a-zA-ZáéíóúÁÉÍÓÚ,]+(\s{0,1}[a-zA-ZáéíóúÁÉÍÓÚ, ])*$/,
			error: 'Solo de admiten letras.'
		}
	},
	email: {
		required: true,
		validator: {
			regEx: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,
			error: 'Formato de correo invalido.'
		}
	},
	password: {
		required: true,
		validator: {
			regEx: /^(?=.{8,})/,
			error: 'La contraseña debe contener 8 caracteres.'
		}
	},
	phone_number: {
		required: true
	},
	region: {
		required: true
	},
	role: {
		required: true
	},
	resend_email: {
		required: false
	}
};

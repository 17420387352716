import React, { Fragment, useEffect, useReducer, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import DatePicker from '../DatePicker/DatePicker';
import Loader from '../CustomLoader';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';

import reducer, { initialState } from './reducer';
import actions from './actions';

import RequirementList from './RequirementList';
import { useAuthDataContext } from '../../context/AuthContext';
import NumberFormat from 'react-number-format';
import deepDiffMapper from '../../utils/deepDiffMapper';
import { getAge } from '../../utils/common';
import { CandidateService } from '../../services/api/candidates'

const results = [{ id: 1, name: 'Seleccionado' }, { id: 2, name: 'No Seleccionado' }, { id: 3, name: 'Reserva' }];
const documents = [{ id: 1, name: 'Certificado' }, { id: 2, name: 'Carta Pasante' }, { id: 3, name: 'Título y Cédula Profesional' }];

const CustomCandidateForm = ({
	onResponse,
	candidateId,
	onCancel,
	regions,
	categories,
	relationships,
	scholarships = [],
}) => {
	const classes = useStyles();
	const { user } = useAuthDataContext();
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		if (+candidateId > 0) {
			dispatch({ type: actions.showLoader, payload: true });
			CandidateService.fetch(candidateId)
				.then(response => {
					if (response.data) {
						const candidate_data = response.data;
						dispatch({ type: actions.setIsUpdate, payload: true });
						dispatch({
							type: actions.setOriginalData,
							payload: candidate_data
						});
						//handleFindDifferences();
						dispatch({ type: actions.setForm, payload: candidate_data });
						dispatch({ type: actions.showLoader, payload: false });
						window.scrollTo(0, 0);
					}
				})
				.catch()
		}
		//eslint-disable-next-line
	}, [candidateId]);

	useEffect(() => {
		handleFindDifferences();
		//eslint-disable-next-line
	}, [state.form]);

	useEffect(() => {
		if (state.form.password || state.form.password === '') {
			dispatch({
				type: actions.setFormField,
				payload: {
					key: 'resend_email',
					value: false
				}
			});
		}
		//eslint-disable-next-line
	}, [state.form.password]);

	const handleCancelForm = () => {
		dispatch({ type: actions.clearFormData });
		dispatch({ type: actions.setIsUpdate, payload: false });
		onCancel();
	};

	const handleAutocompleteChange = (event, value, name) => {
		if (!value) {
			dispatch({
				type: actions.setFormField,
				payload: {
					key: name,
					value: 0
				}
			});
		} else {
			dispatch({
				type: actions.setFormField,
				payload: {
					key: name,
					value: value.id
				}
			});
		}
	};

	const handleDatepickerChange = (date, name) => {
		dispatch({
			type: actions.setFormField,
			payload: {
				key: name,
				value: date
			}
		})
	};

	const handleCreateCandidate = () => {
		dispatch({ type: actions.showModalLoader, payload: true });
		CandidateService.create(state.form)
			.then((response) => {
				if (response.data) {
					handleCancelForm();
					dispatch({ type: actions.showModalLoader, payload: false });
					onResponse(response);
				} else {
					dispatch({ type: actions.showModalLoader, payload: false });
					onResponse(response);
				}
			});
	};

	const handleUpdateCandidate = () => {
		const form = state.form;
		form.differences = state.differences;
		dispatch({ type: actions.showModalLoader, payload: true });
		CandidateService.update(state.form.id, form)
			.then((response) => {
				if (response.data) {
					handleCancelForm();
					handleFindDifferences();
					dispatch({ type: actions.showModalLoader, payload: false });
					onResponse(response);
				} else {
					dispatch({ type: actions.showModalLoader, payload: false });
					onResponse(response);
				}
			}).catch((err) => console.log(err));
	};

	const handleSubmit = event => {
		event.preventDefault();
		if (state.isUpdate) {
			handleUpdateCandidate();
		} else {
			handleCreateCandidate();
		}
	};

	const handleFindDifferences = () => {
		const differences = deepDiffMapper(state.form, state.originalData);

		if (differences.length > 0) {
			dispatch({ type: actions.setHasDifferences, payload: true });
			dispatch({ type: actions.setDifferences, payload: differences });
		} else {
			dispatch({ type: actions.setHasDifferences, payload: false });
			dispatch({ type: actions.setDifferences, payload: [] });
		}
	};

	const handleCheckboxChange = event => {
		const { checked } = event.target;
		dispatch({
			type: actions.setFormField,
			payload: {
				key: event.target.name,
				value: checked
			}
		});
	};

	const handleChangeForm = useCallback((event) => {
		dispatch({
			type: actions.setFormField,
			payload: {
				key: event.target.name,
				value: event.target.value
			}
		}); // eslint-disable-next-line
	}, [state.form]);

	return (
		<Fragment>
			{state.showLoader && <Loader fullWidth />}
			<form className={classes.root} autoComplete="off"
				onSubmit={handleSubmit}>
				<Card variant="outlined">
					<CardContent>
						<div>
							{
								state.form.Employee &&
								<Typography
									variant="h5"
									gutterBottom>
									Parentesco con: <span
										className={classes.upperTextLabel}>{state.form.Employee.full_name}</span>
								</Typography>
							}
							{
								state.form.birthday &&
								<Typography
									variant="h5"
									gutterBottom>
									Edad: <span>{getAge(state.form.birthday)}</span>
								</Typography>
							}
							<TextField
								onChange={handleChangeForm}
								value={state.form.first_name}
								id="first_name"
								name="first_name"
								label="Nombre"
								type="text"
								required
								InputLabelProps={{
									shrink: true,
								}}
							/>
							<TextField
								onChange={handleChangeForm}
								value={state.form.last_name_f}
								id="last_name_f"
								name="last_name_f"
								label="Apellido Paterno"
								type="text"
								required
								InputLabelProps={{
									shrink: true,
								}}
							/>
							<TextField
								onChange={handleChangeForm}
								value={state.form.last_name_m}
								id="last_name_m"
								name="last_name_m"
								label="Apellido Materno"
								type="text"
								required
								InputLabelProps={{
									shrink: true,
								}}
							/>
							<DatePicker
								label="Fecha de nacimiento"
								value={state.form.birthday || null}
								onChange={(date) => handleDatepickerChange(date, 'birthday')}
								required={true}
							/>
							<NumberFormat
								customInput={TextField}
								format="(###) ###-####" mask="_"
								onChange={handleChangeForm}
								value={state.form.telephone || ''}
								name="telephone"
								label="Teléfono"
								InputLabelProps={{
									shrink: true,
								}}
							/>
							<NumberFormat
								customInput={TextField}
								format="(###) ###-####" mask="_"
								onChange={handleChangeForm}
								value={state.form.cell_phone}
								name="cell_phone"
								label="Celular"
								required
								InputLabelProps={{
									shrink: true,
								}}
							/>
							<TextField
								onChange={handleChangeForm}
								value={state.form.enrollment}
								id="enrollment"
								name="enrollment"
								label="Matrícula del trabajador"
								type="number"
								required
								InputLabelProps={{
									shrink: true,
								}}
							/>
							<Autocomplete
								className={classes.autocomplete}
								id="region"
								name="region"
								disabled={user.role > 1}
								options={regions}
								onChange={(event, value) => handleAutocompleteChange(event, value, 'region')}
								getOptionLabel={(option) => option.name.toUpperCase()}
								value={
									user.role !== 1 ?
										regions.filter(region => region.id === user.region)[0] || null :
										regions.filter(region => region.id === state.form.region)[0] || null
								}
								required
								renderInput={(params) =>
									<TextField
										className={classes.upperTextLabel}
										required
										{...params}
										label="Región"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								}
							/>
							<Autocomplete
								className={classes.autocomplete}
								name="relationship"
								options={relationships}
								onChange={(event, value) => handleAutocompleteChange(event, value, 'relationship')}
								getOptionLabel={(option) => option.name.toUpperCase()}
								value={relationships.filter(relationship => relationship.id === state.form.relationship)[0] || null}
								renderInput={(params) =>
									<TextField
										required
										{...params}
										label="Parentesco"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								}
							/>
							{
								state.form.relationship === 6 &&
								<TextField
									onChange={handleChangeForm}
									value={state.form.other_relationship}
									name="other_relationship"
									label="Que parentresco tiene?"
									type="text"
									required={state.form.relationship === 6}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
							<Autocomplete
								className={classes.autocomplete}
								name="level_of_study"
								options={scholarships}
								onChange={(event, value) => handleAutocompleteChange(event, value, 'level_of_study')}
								getOptionLabel={(option) => option.name.toUpperCase()}
								value={scholarships.filter(scholarship => scholarship.id === state.form.level_of_study)[0] || null}
								renderInput={(params) =>
									<TextField
										required
										{...params}
										label="Escolaridad"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								}
							/>
							<Autocomplete
								className={classes.autocomplete}
								name="documents"
								options={documents}
								onChange={(event, value) => handleAutocompleteChange(event, value, 'documents')}
								getOptionLabel={(option) => option.name.toUpperCase()}
								value={documents.filter(document => document.id === state.form.documents)[0] || null}
								renderInput={(params) =>
									<TextField
										required
										{...params}
										label="Documento de acreditación"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								}
							/>
							<Autocomplete
								className={classes.autocomplete}
								id="category"
								name="category"
								options={categories}
								onChange={(event, value) => handleAutocompleteChange(event, value, 'category')}
								getOptionLabel={(option) => option.name.toUpperCase()}
								value={categories.filter(category => category.id === state.form.category)[0] || null}
								renderInput={(params) =>
									<TextField
										style={{ width: '50ch' }}
										required
										{...params}
										label="Categoría"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								}
							/>

							<TextField
								style={{ width: '100%' }}
								id="observations"
								name="observations"
								fullWidth
								label="Antecedentes"
								inputProps={{
									maxLength: 5000
								}}
								InputLabelProps={{
									shrink: true,
								}}
								value={state.form.observations || ''}
								onChange={handleChangeForm}
								multiline />

							<Grid container>
								<Grid item lg={6}>
									<div>
										<FormControlLabel
											onChange={handleCheckboxChange}
											control={<Checkbox
												name="unconditional"
												checked={state.form.unconditional} />}
											label="Es incondicional?"
										/>
									</div>
									<div>
										<FormControlLabel
											onChange={handleCheckboxChange}
											control={<Checkbox
												name="is_studying"
												checked={state.form.is_studying} />}
											label="Estudia actualmente?"
										/>
									</div>
									{
										state.form.is_studying &&
										<TextField
											style={{ width: 400 }}
											onChange={handleChangeForm}
											value={state.form.career}
											name="career"
											label="Que carrera/curso estudia?"
											type="text"
											required={state.form.is_studying}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									}
									<div>
										<FormControlLabel
											onChange={handleCheckboxChange}
											control={<Checkbox
												name="has_been_evaluated"
												checked={state.form.has_been_evaluated} />}
											label="Ya ha sido evaluado anteriormente?"
										/>
									</div>
									{
										state.form.has_been_evaluated &&
										<Fragment>
											<DatePicker
												label="Fecha de evaluación"
												value={state.form.evaluation_date || null}
												onChange={(date) => handleDatepickerChange(date, 'evaluation_date')}
												required={state.form.has_been_evaluated}
											/>
											<Autocomplete
												className={classes.autocomplete}
												name="category_requested"
												options={categories}
												onChange={(event, value) => handleAutocompleteChange(event, value, 'category_requested')}
												getOptionLabel={(option) => option.name.toUpperCase()}
												value={categories.filter(category => category.id === state.form.category_requested)[0] || null}
												renderInput={(params) =>
													<TextField
														required={state.form.has_been_evaluated}
														{...params}
														label="Categoría solicitada"
														InputLabelProps={{
															shrink: true,
														}}
													/>
												}
											/>
											<Autocomplete
												className={classes.autocomplete}
												name="result"
												options={results}
												onChange={(event, value) => handleAutocompleteChange(event, value, 'result')}
												getOptionLabel={(option) => option.name.toUpperCase()}
												value={results.filter(result => result.id === +state.form.result)[0] || null}
												renderInput={(params) =>
													<TextField
														required={state.form.has_been_evaluated}
														{...params}
														label="Resultado"
														InputLabelProps={{
															shrink: true,
														}}
													/>
												}
											/>
										</Fragment>
									}
								</Grid>
								<Grid item lg={6}>
									{
										state.form.Requirements && state.form.Requirements.length > 0 &&
										<RequirementList
											requirements={state.form.Requirements || []}
											candidateId={state.form.id}
										/>
									}
								</Grid>
							</Grid>
						</div>
					</CardContent>
					<CardActions className={classes.actionsContainer}>
						{!state.showModalLoader &&
							<Button
								disabled={!state.hasDifferences && state.isUpdate}
								className={clsx(classes.actionButtons, classes.saveButton)}
								type="submit"
								variant="outlined"
								color="primary"
							>
								GUARDAR
						</Button>}
						{state.showModalLoader && <CircularProgress />}
						<Button
							className={classes.actionButtons}
							type="button"
							variant="outlined"
							onClick={handleCancelForm}
						>
							CANCELAR
						</Button>
					</CardActions>
				</Card>
			</form>
		</Fragment>
	);
};

export default CustomCandidateForm;

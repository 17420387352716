import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const Loader = () => {
	const classes = useStyles();

	return (
		<Backdrop className={classes.backdrop} open={true} timeout={0}>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};

export default Loader;

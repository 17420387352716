import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { ArrowForward } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	uppercase: {
		textTransform: 'uppercase'
	},
	emptyResult: {
		borderLeft: '5px solid #dadada'
	},
	selectedResult: {
		borderLeft: '5px solid #00b44f'
	},
	noSelectedResult: {
		borderLeft: '5px solid red'
	},
	reserveResult: {
		borderLeft: '5px solid wellow'
	}
}));

export default function CheckboxList({ requirements = [], candidateId }) {
	const classes = useStyles();

	return (
		<Card>
			{requirements.length > 0 && <p style={{ padding: 10 }}>Lista de requerimientos asociados</p>}
			<List className={classes.root}>
				{requirements.map((requirement) => {
					const labelId = `checkbox-list-label-${requirement.id}`;
					let resultClass = '';
					let requirementName = '';

					switch (requirement.result_name) {
						case "Sin Resultado":
							resultClass = classes.emptyResult;
							requirementName = `${requirement.period} - SIN RESULTADO`;
							break;
						case 'Seleccionado':
							resultClass = classes.selectedResult;
							requirementName = `${requirement.period} - SELECCIONADO`;
							break;
						case 'No Seleccionado':
							resultClass = classes.noSelectedResult;
							requirementName = `${requirement.period} - NO SELECCIONADO`;
							break;
						case 'Reserva':
							resultClass = classes.reserveResult;
							requirementName = `${requirement.period} - RESERVA`;
							break;

						default:
							resultClass = '';
					}

					return (
						<ListItem
							className={resultClass}
							key={requirement.id}
							role={undefined}
							dense
						>
							<ListItemText
								id={labelId}
								primary={requirementName}
								className={classes.uppercase}
							/>
							<ListItemSecondaryAction title="Ver lista de requerimientos">
								<Link to={`/requerimientos?requerimiento=${requirement.id}&aspirante=${candidateId}`}>
									<ArrowForward />
								</Link>
							</ListItemSecondaryAction>
						</ListItem>
					);
				})}
			</List>
		</Card>
	);
}

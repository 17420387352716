import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import GroupIcon from '@material-ui/icons/Group';
import EmailIcon from '@material-ui/icons/Email';
import history from '../../history';
import {
    SupervisedUserCircle,
    NoteAddOutlined,
    FormatListNumbered,
    ExitToApp,
    ExpandLess,
    ExpandMore,
    Work,
    List as ListIcon
} from '@material-ui/icons';
import { useAuthDataContext } from 'context/AuthContext'

const options = [
    { name: 'Trabajadores', icon: <GroupIcon />, roles: [2, 4], path: '/trabajadores' },
    { name: 'Aspirantes', icon: <GroupIcon />, roles: [2, 4], path: '/aspirantes' },
    { name: 'Usuarios', icon: <SupervisedUserCircle />, roles: [1], path: '/usuarios' },
    { name: 'Documentos', icon: <NoteAddOutlined />, roles: [1], path: '/documentos' },
    { name: 'Resultados', icon: <LibraryAddCheckIcon />, roles: [1], path: '/resultados' },
    { name: 'Requerimientos', icon: <FormatListNumbered />, roles: [1, 2, 4], path: '/requerimientos' },
    { name: 'Administrar plazas', icon: <Work />, roles: [1, 5], path: '/plazas' },
    { name: 'Medicos Coahuila', icon: <ListIcon />, roles: [1, 5], path: '/medicoscoahuila' },
    { name: 'Correos', icon: <EmailIcon />, roles: [1, 2], path: '/correos' },
]

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    hide: {
        display: 'none'
    },
    active: {
        color: '#49d249'
    },
    inactive: {
        color: '#ef6565'
    },
});

export default function TemporaryDrawer({ show, toggleShow }) {
    const classes = useStyles();
    const { user, onLogout } = useAuthDataContext();
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(show);
    const [employeeExpandOpen, setEmployeeExpandOpen] = React.useState(false);
    const [candidateExpandOpen, setCandidateExpandOpen] = React.useState(false);

    useEffect(() => {
        setIsOpen(show);
    }, [show]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        //setState({ ...state, [anchor]: open });
        toggleShow(open);
    };

    const handleButtonClick = (path) => {
        toggleShow(false);
        if (path === '/trabajadores') {
            if (user.role === 4) {
                return history.push('/trabajadores-inactivos');
            } else {
                return history.push('/trabajadores-activos');
            }
        }
        if (path === '/aspirantes') {
            if (user.role === 4) {
                return history.push('/aspirantes-inactivos');
            } else {
                return history.push('/aspirantes-activos');
            }
        }
        history.push(path);
    };

    const list = () => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: false,
            })}
            role="presentation"
            onKeyDown={toggleDrawer(false)}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={toggleDrawer(false)}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>
            <Divider />
            <ListItem
                button
                onClick={() => setEmployeeExpandOpen((current) => !current)}
                className={clsx([{
                    [classes.hide]: user.role !== 1
                }])}
            >
                <ListItemIcon>
                    <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Trabajadores" />
                {employeeExpandOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={employeeExpandOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem
                        button
                        className={classes.nested}
                        onClick={() => handleButtonClick('/trabajadores-activos')}
                    >
                        <ListItemIcon>
                            <RadioButtonCheckedIcon className={classes.active} />
                        </ListItemIcon>
                        <ListItemText primary="Activos" />
                    </ListItem>
                    <ListItem
                        button
                        className={classes.nested}
                        onClick={() => handleButtonClick('/trabajadores-inactivos')}
                    >
                        <ListItemIcon>
                            <RadioButtonUncheckedIcon className={classes.inactive} />
                        </ListItemIcon>
                        <ListItemText primary="Jubilados" />
                    </ListItem>
                </List>
            </Collapse>
            <ListItem
                button
                onClick={() => setCandidateExpandOpen((current) => !current)}
                className={clsx([{
                    [classes.hide]: user.role !== 1
                }])}
            >
                <ListItemIcon>
                    <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Aspirantes" />
                {candidateExpandOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={candidateExpandOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem
                        button
                        className={classes.nested}
                        onClick={() => handleButtonClick('/aspirantes-activos')}
                    >
                        <ListItemIcon>
                            <RadioButtonCheckedIcon className={classes.active} />
                        </ListItemIcon>
                        <ListItemText primary="Activos" />
                    </ListItem>
                    <ListItem
                        button
                        className={classes.nested}
                        onClick={() => handleButtonClick('/aspirantes-inactivos')}
                    >
                        <ListItemIcon>
                            <RadioButtonUncheckedIcon className={classes.inactive} />
                        </ListItemIcon>
                        <ListItemText primary="Jubilados" />
                    </ListItem>
                </List>
            </Collapse>
            <List>
                {options.map((option, index) => (
                    <ListItem
                        button
                        key={index}
                        className={clsx([{
                            [classes.hide]: !option.roles.includes(user.role)
                        }])}
                        onClick={() => handleButtonClick(option.path)}
                    >
                        <ListItemIcon>{option.icon}</ListItemIcon>
                        <ListItemText primary={option.name} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {[{ name: 'Salir', icon: <ExitToApp /> }].map((option, index) => (
                    <ListItem button key={index} onClick={onLogout}>
                        <ListItemIcon>{option.icon}</ListItemIcon>
                        <ListItemText primary={option.name} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <div>
            <React.Fragment >
                <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
                    {list()}
                </Drawer>
            </React.Fragment>
        </div>
    );
}

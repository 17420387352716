import actions from './actions';

export const initialState = {
	pageReady: false,
	showLoader: false,
	employeeId: null,
	employeeData: {},
	candidateData: {},
	seniority: {},
	documents: [],
	results: []
};

const reducer = (state, action) => {
	const {type, payload} = action;

	switch (type) {
	case actions.setPageready: {
		return {
			...state,
			pageReady: payload
		}
	}
	case actions.showLoader: {
		return {
			...state,
			showLoader: payload
		}
	}
	case actions.setEmployeeData: {
		return {
			...state,
			employeeData: payload
		}
	}
	case actions.setCandidateData: {
		return {
			...state,
			candidateData: payload
		}
	}
	case actions.setEmployeeId: {
		return {
			...state,
			employeeId: payload
		}
	}
	case actions.setSeniority: {
		return {
			...state,
			seniority: payload
		}
	}
	case actions.setDocuments: {
		return {
			...state,
			documents: payload
		}
	}
	case actions.setResults: {
		return {
			...state,
			results: payload
		}
	}

	default: return state
	}
};

export default reducer;

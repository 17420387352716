import { get, post, put, destroy, viewFile } from 'config/api'

export const RequirementService = {
    index: (page = 1) => get(`/requirements/candidates?page=${page}`),
    fetch: (id) => get(`/requirements/${id}`),
    view: (id) => viewFile(`/requirements/view/${id}`),
    create: (data) => post('/requirements', data),
    update: (id, data) => put(`/requirements/${id}`, data),
    remove: (id) => destroy(`/requirements/${id}`),
    removeCandidate: (id, candidate_id) => destroy(`/candidate-requirements/${id}/${candidate_id}`),
    getRegions: () => get('/regions'),
    getPages: () => get('/requirements/pages'),
    sendEmail: (id) => get(`/requirements/${id}/send`),
    changeResult: (requirement, result) => put(`/candidate-requirements/${requirement}`, { result }),
    generatePdf: (id) => viewFile(`/requirements/${id}/pdf`)
}
import axios from 'axios'

const apiClient = axios.create({ baseURL: process.env.REACT_APP_API_URL })

apiClient.interceptors.request.use(
    (config) => {
        const session = JSON.parse(localStorage.getItem('session')) || null
        const headers = {
            'Content-Type': 'application/json'
        }

        if (session) {
            headers.Authorization = `Bearer ${session.accessToken}`
        }

        return {
            ...config,
            headers
        }
    },
    (error) => Promise.reject(error)
)

apiClient.interceptors.response.use(
    (response) => response.data,
    async (error) => {
        const originalRequest = error.config

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            const { data } = await apiClient.post('/auth/token/refresh')
            const session = JSON.parse(localStorage.getItem('session'))
            session.accessToken = data.accessToken;
            localStorage.setItem('session', JSON.stringify(session))
            axios.defaults.headers.common[
                'authorization'
            ] = `Bearer ${data.accessToken}`
            return apiClient(originalRequest)
        }

        if (
            error.response.status === 500 &&
            error.response.data.error === 'JsonWebTokenError'
        ) {
            window.localStorage.removeItem('session')
            window.location.href = '/'
        }

        return error.response.data
    }
)

const { get, post, put, delete: destroy } = apiClient

const viewFile = async (url) => {
    const headers = {};
    const session = JSON.parse(localStorage.getItem('session')) || null
    if (session) {
        headers.Authorization = `Bearer ${session.accessToken}`;
    }
    const BASE_URL = process.env.REACT_APP_API_URL;
    const path = `${BASE_URL}${url}`;
    const response = await fetch(path, {
        method: 'get',
        headers
    })
    return await response.blob()
};

export { get, post, put, destroy, viewFile }
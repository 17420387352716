import React, { Fragment, useEffect, useReducer, useState } from 'react';
import Observations from '../../components/Observation';
import SearchBox from '../../components/Searchbox/Searchbox';
import Loader from '../../components/Loader/Loader';
import { VARIABLES } from '../../general-constants';
import queryStrings from 'query-string';

import { useAuthDataContext } from '../../context/AuthContext';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomEmployeeForm from '../../components/CustomEmployeeForm';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import CustomModal from '../../components/CustomModal';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import FindInPage from '@material-ui/icons/FindInPage';
import IconButton from '@material-ui/core/IconButton';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';

import actions from './actions';
import reducer, { initialState } from './reducer';
import getSeniorityFormat from '../../utils/getSeniorityFormat';
import { getNumberMask } from '../../utils/common';
import Card from '@material-ui/core/Card';
import useStyles from './styles';
import ClearFilters from '../../components/ClearFilters';
import { EmployeeService } from '../../services/api/employees';
import useScrollTop from 'hooks/useScrollTop';

const Employee = (props) => {
	const classes = useStyles();
	const { user } = useAuthDataContext();
	const [state, dispatch] = useReducer(reducer, initialState);
	const [employeeStatus, setEmployeeStatus] = useState(props.type === 'active' ? 1 : 2)
	const params = JSON.parse(JSON.stringify(queryStrings.parse(props.location.search)));

	useEffect(() => {
		dispatch({ type: actions.showLoader, value: true });
		handleCancelForm();
		dispatch({
			type: actions.setTerm,
			value: ''
		});
		dispatch({
			type: actions.setFormat,
			value: ''
		});
		const status = props.type === 'active' ? 1 : 2;
		setEmployeeStatus(status);
		handleResetFilters();
		EmployeeService.getRegions()
			.then(response => response.data)
			.then(regionList => {
				setRegions(regionList);
			});

		EmployeeService.getCategories()
			.then(response => response.data)
			.then(categoryList => {
				setCategories(categoryList);
			});

		EmployeeService.getAffiliations()
			.then(response => response.data)
			.then(affiliationList => {
				setAffiliations(affiliationList);
				setFilterAffiliations(affiliationList);
			});

		EmployeeService.getPages(1)
			.then(response => {
				if (response.data) {
					const { pages, total } = response.data;
					dispatch({ type: actions.setTotalPages, value: total });
					dispatch({ type: actions.setPages, value: pages });
				}
			})
		EmployeeService.index(status, 1)
			.then((response) => {
				const employees = response.data;
				dispatch({ type: actions.showLoader, value: false });
				dispatch({ type: actions.setEmployees, value: employees });
				dispatch({ type: actions.setEmployeeId, value: params.hasOwnProperty('trabajador') ? params.trabajador : 0 });
			});

		const { history: { location } } = props;
		if (location.state && location.state.hasOwnProperty('employeeId')) {
			dispatch({ type: actions.showForm, payload: true });
			dispatch({
				type: actions.setEmployeeId,
				value: location.state.employeeId
			});
			props.history.replace({ state: null });
		} // eslint-disable-next-line
	}, [props.type]);

	useEffect(() => {
		const affiliations = [...state.affiliations];
		dispatch({
			type: actions.setFilters,
			payload: {
				key: 'affiliation',
				value: 0
			}
		});
		if (+state.filters.region > 0) {
			setFilterAffiliations(affiliations.filter(item => item.region === state.filters.region));
		} else {
			setFilterAffiliations(affiliations);
		}
		//eslint-disable-next-line
	}, [state.filters.region]);

	useEffect(() => {
		handleSearch(); // eslint-disable-next-line
	}, [state.filters, state.currentPage]);
	//#region handlers
	const handleOpenDeleteModal = employee => {
		dispatch({ type: actions.showModalDelete, payload: true });
		dispatch({ type: actions.setEmployeeToRemove, payload: employee })
	};

	const handleCloseDeleteModal = () => {
		dispatch({ type: actions.showModalDelete, payload: false });
		dispatch({ type: actions.setEmployeeToRemove, payload: {} });
	};

	const setRegions = (regions = []) => {
		if (props.type === 'inactive') {
			regions = regions.filter((region) => region.id > 8 && region.id !== 19 && region.name !== 'N/A');
		} else {
			regions = regions.filter((region) => region.id < 8);
		}
		dispatch({ type: actions.setRegions, value: regions });
	};

	const setCategories = (categories = []) => {
		categories = categories.filter(category => category.name !== 'N/A');
		dispatch({ type: actions.setCategories, value: categories });
	};

	const setAffiliations = (affiliations = []) => {
		affiliations = affiliations.filter(affiliation => affiliation.id !== 185 && affiliation.name !== 'N/A');
		dispatch({ type: actions.setAffiliations, value: affiliations });
	};

	const setFilterAffiliations = (affiliations = []) => {
		affiliations = affiliations.filter(affiliation => affiliation.id !== 185 && affiliation.name !== 'N/A');
		dispatch({ type: actions.setFilterAffiliations, value: affiliations });
	};

	const handleChangeSearchBox = event => {
		let value = event.target.value.replace(/[^a-z0-9 ]/gi, '')
		if (+state.search.type === 1) {
			value = value.replace(/[^0-9]+/g, "")
		}
		dispatch({
			type: actions.setSearchType,
			payload: {
				key: event.target.id,
				value
			}
		});
	};

	const handleSubmitSearchBox = event => {
		event.preventDefault();
		dispatch({
			type: actions.setCurrentPage,
			value: 1
		});
		handleSearch(1);
	};

	const handleSearch = (page = state.currentPage) => {
		let params = `?status=${employeeStatus}`;
		if (+state.filters.region > 0) params = params + `&region=${state.filters.region}`;
		if (+state.filters.category > 0) params = params + `&category=${state.filters.category}`;
		if (+state.filters.affiliation > 0) params = params + `&affiliation=${state.filters.affiliation}`;
		params = params + `&sort=${state.filters.seniority}`;
		params = params + `&page=${page}`
		if (+state.search.type === 1 && state.search.enrollment.trim() !== '') {
			params = params + `&enrollment=${state.search.enrollment.trim()}`
		}
		if (+state.search.type === 2) {
			if (state.search.first_name.trim() !== '') params = params + `&firstName=${state.search.first_name.trim()}`;
			if (state.search.last_name_f.trim() !== '') params = params + `&lastNameF=${state.search.last_name_f.trim()}`;
			if (state.search.last_name_m.trim() !== '') params = params + `&lastNameM=${state.search.last_name_m.trim()}`;
		}
		EmployeeService.search(params)
			.then((response) => {
				if (response.data) {
					dispatch({ type: actions.setEmployees, value: response.data });
				}
				if (response.meta) {
					dispatch({ type: actions.setTotalPages, value: response.meta.pages });
					dispatch({ type: actions.setPages, value: response.meta.pages });
				}
			})
			.catch((e) => console.log(e));
	};

	const handlePageChange = (event, page) => {
		dispatch({
			type: actions.setCurrentPage,
			value: page
		});
	};

	const clearSearchTerm = () => {
		dispatch({
			type: actions.clearTerm
		});
	};

	const toggleShowForm = () => {
		dispatch({
			type: actions.showForm,
			payload: !state.showForm
		});
	};

	const handleCancelForm = () => {
		dispatch({ type: actions.clearFormData });
		dispatch({ type: actions.clearOriginalData });
		dispatch({ type: actions.setIsUpdating, payload: false });
		dispatch({ type: actions.showForm, payload: false });
		dispatch({ type: actions.setEmployeeId, value: 0 });
	};

	const handleResponse = response => {
		if (response.data) {
			dispatch({
				type: actions.setAlertProps,
				payload: {
					show: true,
					message: response.meta.message,
					severity: 'success'
				}
			});
			handleSearch();
		}
		if (response.error) {
			dispatch({
				type: actions.setAlertProps,
				payload: {
					show: true,
					message: response.error,
					severity: 'error'
				}
			});
		}
	};

	const handleFiltersAutocompleteChange = (event, value, name) => {
		if (!value) {
			if (state.currentPage !== 1) {
				dispatch({
					type: actions.setCurrentPage,
					value: 1
				})
			}
			dispatch({
				type: actions.setFilters,
				payload: {
					key: name,
					value: 0
				}
			});
		} else {
			if (state.currentPage !== 1) {
				dispatch({
					type: actions.setCurrentPage,
					value: 1
				})
			}
			dispatch({
				type: actions.setFilters,
				payload: {
					key: name,
					value: value.id
				}
			});
		}
	};

	const handleDeleteEmployee = () => {
		dispatch({ type: actions.showModalLoader, payload: true });
		EmployeeService.remove(state.employeeToRemove.id)
			.then((response) => {
				if (response.meta) {
					dispatch({
						type: actions.setAlertProps,
						payload: {
							show: true,
							message: response.meta.message,
							severity: 'success'
						}
					});
					dispatch({ type: actions.showModalLoader, payload: false });
					handleCloseDeleteModal();
					handleSearch();
				}
				if (response.error) {
					dispatch({
						type: actions.setAlertProps,
						payload: {
							show: true,
							message: response.error,
							severity: 'warning'
						}
					});
					dispatch({ type: actions.showModalLoader, payload: false });
					handleCloseDeleteModal();
				}
			})
	};

	const handleCloseAlert = () => {
		dispatch({
			type: actions.setAlertProps,
			payload: {
				show: false,
				message: '',
				severity: ''
			}
		});
	};

	const handlePrepareEmployeeToUpdate = id => {
		dispatch({ type: actions.showForm, payload: true });
		dispatch({
			type: actions.setEmployeeId,
			value: id
		});
	};

	const handleViewData = id => {
		props.history.push(`/trabajador/informacion/${id}`);
	};

	const handleShowClearFilters = () => {
		return +state.filters.region > 0 ||
			+state.filters.affiliation > 0 ||
			+state.filters.category > 0 ||
			+state.filters.seniority > 0 ||
			state.search.enrollment.trim() !== '' ||
			state.search.first_name.trim() !== '' ||
			state.search.last_name_f.trim() !== '' ||
			state.search.last_name_m.trim() !== '';
	};

	const handleResetFilters = () => {
		dispatch({ type: actions.clearTerm });
		dispatch({ type: actions.resetFilters, payload: user.role });
	};

	const handleRadioChange = (event) => {
		const value = event.target.value;
		dispatch({
			type: actions.setSearchType,
			payload: {
				key: 'type',
				value
			}
		});
	}
	//#endregion

  useScrollTop(state.showLoader);
	if (state.showLoader) {
		return (
			<Loader />
		);
	}

	return (
		<Fragment>
			<Container>
				<Typography
					className={classes.title}
					variant="h5"
					gutterBottom>
					{props.type === 'inactive' && 'Jubliados / Pensionados'}
					{props.type === 'active' && 'Trabajadores'}
					<IconButton
						aria-label="delete"
						className={classes.margin}
						onClick={toggleShowForm}
					>
						<PersonAdd fontSize="default" />
					</IconButton>
				</Typography>

				<Collapse in={state.showForm} timeout="auto" unmountOnExit component="div">
					<CustomEmployeeForm
						employeeId={state.employeeId}
						onResponse={handleResponse}
						onCancel={handleCancelForm}
						form={state.form}
						regions={state.regions}
						categories={state.categories}
						affiliations={state.affiliations}
						type={props.type}
					/>
				</Collapse>

				<div className={classes.filters}>
					<div className={classes.filtersContainer}>
						<Card variant="outlined" className={classes.card}>
							<Autocomplete
								className={classes.autocomplete}
								disabled={user.role > 1}
								name="region"
								options={state.regions.sort((a, b) => a.name.localeCompare(b.name))}
								onChange={(event, value) => handleFiltersAutocompleteChange(event, value, 'region')}
								getOptionLabel={(option) => option.name.toUpperCase()}
								value={
									user.role !== 1 ?
										state.regions.filter(region => region.id === user.region)[0] || null :
										state.regions.filter(region => region.id === state.filters.region)[0] || null
								}
								renderInput={(params) =>
									<TextField
										required
										{...params}
										label="Región"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								}
							/>
							{props.type === 'active' &&
								<Autocomplete
									className={classes.autocomplete}
									name="affiliation"
									options={state.filterAffiliations}
									onChange={(event, value) => handleFiltersAutocompleteChange(event, value, 'affiliation')}
									getOptionLabel={(option) => option.name.toUpperCase()}
									value={state.filterAffiliations.filter(affiliation => affiliation.id === state.filters.affiliation)[0] || null}
									renderInput={(params) =>
										<TextField
											required
											{...params}
											label="Adscripción"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									}
								/>
							}
							{props.type === 'active' &&
								<Autocomplete
									className={classes.autocomplete}
									name="category"
									options={state.categories}
									onChange={(event, value) => handleFiltersAutocompleteChange(event, value, 'category')}
									getOptionLabel={(option) => option.name.toUpperCase()}
									value={
										state
											.categories
											.filter(category => category.id === state.filters.category)[0] || null}
									renderInput={(params) =>
										<TextField
											required
											{...params}
											label="categoría"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									}
								/>
							}
							<Autocomplete
								className={classes.autocomplete}
								name="seniority"
								options={
									props.type === 'inactive' ?
										state.seniority.filter(order => order.id < 5 || order.id > 6) :
										state.seniority
								}
								onChange={(event, value) => handleFiltersAutocompleteChange(event, value, 'seniority')}
								getOptionLabel={(option) => option.name.toUpperCase()}
								value={state.seniority.filter(seniority => seniority.id === state.filters.seniority)[0] || null}
								renderInput={(params) =>
									<TextField
										required
										{...params}
										label="Ordenar por"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								}
							/>
							<SearchBox
								enrollment={state.search.enrollment}
								firstName={state.search.first_name}
								lastNameF={state.search.last_name_f}
								lastNameM={state.search.last_name_m}
								placeholder={'Buscar por ...'}
								clearSearch={clearSearchTerm}
								handleChange={handleChangeSearchBox}
								handleRadio={handleRadioChange}
								searchPerform={handleSubmitSearchBox}
								searchType={state.search.type}
							/>
							<ClearFilters
								show={handleShowClearFilters()}
								onClearFilters={handleResetFilters}
							/>
						</Card>
					</div>
				</div>

				<div className={classes.pagination}>
					<Pagination
						onChange={handlePageChange}
						count={state.pages}
						page={state.currentPage}
					/>
				</div>

				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>{VARIABLES.ID}</TableCell>
								<TableCell>{VARIABLES.ENROLLMENT}</TableCell>
								<TableCell>{VARIABLES.NAME}</TableCell>
								<TableCell>{VARIABLES.LAST_NAMES}</TableCell>
								<TableCell>{VARIABLES.TELEPHONE}</TableCell>
								<TableCell>{VARIABLES.SENIORITY}</TableCell>
								{props.type === 'active' && <TableCell>{VARIABLES.CATEGORY}</TableCell>}
								<TableCell align="center">{VARIABLES.OBSERVATIONS}</TableCell>
								<TableCell align="center">{VARIABLES.OPTIONS}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{state.employees.map((row) => {
								const seniority = getSeniorityFormat(row.seniority);
								return (
									<TableRow key={row.id} className={(row.membership_is_expired && props.type === 'inactive') ? classes.expired : 'hasnot'}>
										<TableCell component="th" scope="row">
											{row.id}
										</TableCell>
										<TableCell>{row.enrollment}</TableCell>
										<TableCell className={classes.uppercase}>{row.first_name}</TableCell>
										<TableCell className={classes.uppercase}>{row.last_name_f} {row.last_name_m}</TableCell>
										<TableCell>{getNumberMask(row.cell_phone)}</TableCell>
										<TableCell>{seniority.years} - {seniority.fortnights} - {seniority.days}</TableCell>
										{props.type === 'active' && <TableCell className={classes.uppercase}>{row.category}</TableCell>}
										<TableCell align="center"> {row.observations && <Observations observation={row.observations} />} </TableCell>
										<TableCell align="center" style={{ display: 'flex' }}>
											<IconButton
												title="Ver información"
												aria-label="search"
												className={classes.margin}
												onClick={() => handleViewData(row.id)}
											>
												<FindInPage fontSize="default" />
											</IconButton>
											<IconButton
												title="Actualizar información"
												aria-label="update"
												className={classes.margin}
												onClick={() => handlePrepareEmployeeToUpdate(row.id)}
											>
												<Edit fontSize="default" />
											</IconButton>
											<IconButton
												title="Eliminar"
												aria-label="delete"
												className={classes.margin}
												onClick={() => handleOpenDeleteModal(row)}
											>
												<Delete fontSize="default" />
											</IconButton>
										</TableCell>
									</TableRow>
								)
							})}

							{state.employees.length <= 0 && (
								<TableRow>
									<TableCell colSpan={9} align="center">
										No se encontraron resultados
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>

				<div className={classes.pagination}>
					<Pagination
						onChange={handlePageChange}
						count={state.pages}
						page={state.currentPage}
					/>
				</div>
			</Container>

			<CustomModal
				title="Eliminar información del trabajador"
				open={state.showModalDelete}
				confirm={handleDeleteEmployee}
				close={handleCloseDeleteModal}
				buttonText="Aceptar"
				disabled={false}
				showLoader={state.showModalLoader}
			>
				Se eliminará la información del trabajador
				<span className={classes.employeeName} style={{ textTransform: 'capitalize' }}> {state.employeeToRemove.first_name || ''} {state.employeeToRemove.last_name_f || ''} {state.employeeToRemove.last_name_m || ''} </span>
				al igual que la información de su aspirante y será removido de los requerimientos asociados.
			</CustomModal>

			<Snackbar
				open={state.alertProps.show}
				autoHideDuration={3000}
				onClose={handleCloseAlert}>
				<Alert onClose={handleCloseAlert} severity={state.alertProps.severity}>
					<p className={classes.paragraphError}>
						{state.alertProps.message}
					</p>
				</Alert>
			</Snackbar>
		</Fragment>
	);
};

export default Employee;

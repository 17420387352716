import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CustomCheckbox({ checked, onStatusChange, jobPositionId }) {
    const [state, setState] = React.useState(checked);

    const handleChange = (event) => {
        setState(event.target.checked);
        onStatusChange(jobPositionId, event.target.checked);
    };

    useEffect(() => {
        setState(checked);
    }, [checked]);

    return (
        <FormGroup row>
            <FormControlLabel
                control={<GreenCheckbox checked={state} onChange={handleChange} name="status" />}
                label=""
            />
        </FormGroup>
    );
}

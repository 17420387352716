import React, { useState, useEffect, useReducer, useCallback } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import DatePicker from '../DatePicker/DatePicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	MailOutline,
	Email,
	Visibility,
	VisibilityOff
} from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';
import actions from './actions';
import reducer, { initialState } from './reducer';
import useStyles from './styles';
import Loader from '../Loader/Loader';
import { useAuthDataContext } from '../../context/AuthContext';
import deepDiffMapper from '../../utils/deepDiffMapper';
import { EmployeeService } from '../../services/api/employees'
import getSeniorityFormat from 'utils/getSeniorityFormat';

const CustomEmployeeForm = ({
	onResponse,
	employeeId,
	onCancel,
	regions,
	categories,
	affiliations,
	type,
}) => {
	const classes = useStyles();
	const { user } = useAuthDataContext();
	const [showPassword, setShowPassword] = useState(false);
	const [affiliationList, setAffiliationList] = useState(affiliations);
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		if (type === 'active') {
			dispatch({
				type: actions.setFormField,
				payload: {
					key: 'status',
					value: 1
				}
			})
		}
	}, [type]);

	useEffect(() => {
		if (+employeeId > 0) {
			dispatch({ type: actions.showLoader, payload: true });
			EmployeeService.fetch(employeeId)
				.then((response) => {
					if (response.data) {
						const employee_data = response.data;
						const seniority = getSeniorityFormat(employee_data.seniority);
						employee_data.years = +seniority.years;
						employee_data.fortnights = +seniority.fortnights;
						employee_data.days = +seniority.days;
						delete employee_data.seniority;
						employee_data.password = '';
						employee_data.resend_email = false;
						dispatch({ type: actions.setIsUpdate, payload: true });
						dispatch({
							type: actions.setForm,
							payload: employee_data
						});
						dispatch({
							type: actions.setOriginalData,
							payload: employee_data
						});
						handleFindDifferences();
						dispatch({ type: actions.showLoader, payload: false });
						window.scrollTo(0, 0);
					}
				});
		} // eslint-disable-next-line
	}, [employeeId]);

	useEffect(() => {
		if (+state.form.region > 0) {
			const filteredAffiliations = affiliations.filter(affiliation => affiliation.region === +state.form.region);
			setAffiliationList(filteredAffiliations);
		} // eslint-disable-next-line
	}, [state.form.region]);

	useEffect(() => {
		handleFindDifferences(); // eslint-disable-next-line
	}, [state.form]);

	useEffect(() => {
		if (state.form.password || state.form.password === '') {
			dispatch({
				type: actions.setFormField,
				payload: {
					key: 'resend_email',
					value: false
				}
			});
		} // eslint-disable-next-line
	}, [state.form.password]);

	const handleTogglePassword = () => {
		setShowPassword(!showPassword);
	};

	const handleCancelForm = () => {
		dispatch({ type: actions.clearFormData });
		dispatch({ type: actions.setIsUpdate, payload: false });
		onCancel();
	};

	const handleAutocompleteChange = (event, value, name) => {
		if (!value) {
			dispatch({
				type: actions.setFormField,
				payload: {
					key: name,
					value: 0
				}
			});
		} else {
			dispatch({
				type: actions.setFormField,
				payload: {
					key: name,
					value: value.id
				}
			});
		}
	};

	const handleDatepickerChange = (date, event) => {
		dispatch({
			type: actions.setFormField,
			payload: {
				key: 'membership',
				value: date
			}
		})
	};

	const handleCreateEmployee = () => {
		dispatch({ type: actions.showModalLoader, payload: true });
		EmployeeService.create(state.form)
			.then((response) => {
				if (response.data) handleCancelForm();
				dispatch({ type: actions.showModalLoader, payload: false });
				onResponse(response);
			})
			.catch(err => console.log(err));
	};

	const handleUpdateEmployee = () => {
		const form = state.form;
		dispatch({ type: actions.showModalLoader, payload: true });
		EmployeeService.update(state.form.id, form)
			.then((response) => {
				if (response.data) {
					handleCancelForm();
					handleFindDifferences();
				}
				dispatch({ type: actions.showModalLoader, payload: false });
				onResponse(response);
			}).catch(err => console.log(err));
	};

	const handleSubmit = event => {
		event.preventDefault();
		if (state.isUpdate) {
			handleUpdateEmployee();
		} else {
			handleCreateEmployee();
		}
	};

	const handleFindDifferences = () => {
		const differences = deepDiffMapper(state.form, state.originalData);

		if (differences.length > 0) {
			dispatch({ type: actions.setHasDifferences, payload: true });
		} else {
			dispatch({ type: actions.setHasDifferences, payload: false });
		}
	};

	const handleCheckboxChange = event => {
		const { checked } = event.target;
		dispatch({
			type: actions.setFormField,
			payload: {
				key: event.target.name,
				value: checked
			}
		});
	};

	const handleChangeForm = useCallback((event) => {
		const numberTypes = ['years', 'enrollment', 'fortnights', 'days'];
		let value = event.target.value;
		if (numberTypes.includes(event.target.name)) {
			value = value.replace(/[^0-9]+/g, "")
		}
		dispatch({
			type: actions.setFormField,
			payload: {
				key: event.target.name,
				value: value
			}
		})
	}, []);

	if (state.showLoader) {
		return <Loader />
	}

	return (
		<form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
			<Card variant="outlined">
				<CardContent>
					<div>
						<TextField
							onChange={handleChangeForm}
							value={state.form.enrollment}
							id="enrollment"
							name="enrollment"
							label="Matrícula"
							type="text"
							required
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							onChange={handleChangeForm}
							value={state.form.first_name}
							id="first_name"
							name="first_name"
							label="Nombre"
							type="text"
							required
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							onChange={handleChangeForm}
							value={state.form.last_name_f}
							id="last_name_f"
							name="last_name_f"
							label="Apellido Paterno"
							type="text"
							required
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							onChange={handleChangeForm}
							value={state.form.last_name_m}
							id="last_name_m"
							name="last_name_m"
							label="Apellido Materno"
							type="text"
							required
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							className={classes.lowercaseInput}
							onChange={handleChangeForm}
							value={state.form.email}
							id="email"
							name="email"
							label="Email"
							type="email"
							required
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							className={classes.lowercaseInput}
							onChange={handleChangeForm}
							value={state.form.password || ''}
							id="password"
							name="password"
							label="Contraseña"
							type={showPassword ? 'text' : 'password'}
							required={!state.isUpdate}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment
										position="end"
										onClick={handleTogglePassword}
										className={classes.eyeIcon}>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</InputAdornment>
								),
							}}
							autoComplete="current-password"
						/>
						<TextField
							onChange={handleChangeForm}
							value={state.form.years}
							className={classes.seniority}
							id="years"
							name="years"
							label="A"
							type="text"
							required
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							onChange={handleChangeForm}
							value={state.form.fortnights}
							className={classes.seniority}
							id="fortnights"
							name="fortnights"
							label="Q"
							required
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							onChange={handleChangeForm}
							value={state.form.days}
							className={classes.seniority}
							id="days"
							name="days"
							label="D"
							required
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<NumberFormat
							onChange={handleChangeForm}
							value={state.form.telephone || ''}
							name="telephone"
							label="Teléfono"
							InputLabelProps={{
								shrink: true,
							}}
							customInput={TextField}
							format="(###) ###-####" mask="_"
						/>
						<NumberFormat
							onChange={handleChangeForm}
							value={state.form.cell_phone}
							name="cell_phone"
							label="Celular"
							required
							InputLabelProps={{
								shrink: true,
							}}
							customInput={TextField}
							format="(###) ###-####" mask="_"
						/>
						<FormControl
							className={classes.select}
							required
							disabled={type === 'active'}
						>
							<InputLabel
								id="status-label"
								shrink
							>
								STATUS
									</InputLabel>
							<Select
								labelId="status-label"
								id="status"
								name="status"
								onChange={handleChangeForm}
								value={state.form.status}
							>
								{type === 'active' && <MenuItem value={1}>ACTIVO</MenuItem>}
								{type !== 'active' && <MenuItem value={2}>JUBILADO</MenuItem>}
								{type !== 'active' && <MenuItem value={3}>PENSIONADO</MenuItem>}
							</Select>
						</FormControl>
						<Autocomplete
							className={classes.autocomplete}
							id="region"
							name="region"
							disabled={user.role > 1}
							options={regions}
							onChange={(event, value) => handleAutocompleteChange(event, value, 'region')}
							getOptionLabel={(option) => option.name.toUpperCase()}
							value={
								user.role !== 1 ?
									regions.filter(region => region.id === user.region)[0] || null :
									regions.filter(region => region.id === state.form.region)[0] || null
							}
							required
							renderInput={(params) =>
								<TextField
									className={classes.upperTextLabel}
									required
									{...params}
									label="Región"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
						/>
						{
							type === 'active' &&
							<Autocomplete
								className={classes.autocomplete}
								id="affiliation"
								name="affiliation"
								options={affiliationList}
								onChange={(event, value) => handleAutocompleteChange(event, value, 'affiliation')}
								getOptionLabel={(option) => option.name.toUpperCase()}
								value={affiliationList.filter(affiliation => affiliation.id === state.form.affiliation)[0] || null}
								renderInput={(params) =>
									<TextField
										required
										{...params}
										label="Adscripción"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								}
							/>
						}
						{
							type === 'active' &&
							<Autocomplete
								className={classes.autocomplete}
								id="category"
								name="category"
								options={categories}
								onChange={(event, value) => handleAutocompleteChange(event, value, 'category')}
								getOptionLabel={(option) => option.name.toUpperCase()}
								value={categories.filter(category => category.id === state.form.category)[0] || null}
								renderInput={(params) =>
									<TextField
										required
										{...params}
										label="Categoría"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								}
							/>
						}
						<TextField
							style={{ width: '100%' }}
							id="observations"
							name="observations"
							fullWidth
							label="Antecedentes"
							inputProps={{
								maxLength: 5000
							}}
							InputLabelProps={{
								shrink: true,
							}}
							value={state.form.observations || ''}
							onChange={handleChangeForm}
							multiline />

						{
							type === 'inactive' &&
							<DatePicker
								label="Membresía"
								value={state.form.membership || null}
								onChange={handleDatepickerChange}
								required={false}
							/>
						}

						{
							state.isUpdate && state.form.password &&
							<div>
								<FormControlLabel
									onChange={handleCheckboxChange}
									control={<Checkbox icon={<MailOutline />} checkedIcon={<Email />} name="resend_email" />}
									label={`Re-enviar correo? : ${state.form.resend_email ? `Se enviará un correo con la nueva contraseña a: ${state.form.email}` : 'Da click en el icono de correo para reenviar un correo con la nueva contraseña.'}`}
								/>
							</div>
						}
					</div>
				</CardContent>
				<CardActions className={classes.actionsContainer}>
					{!state.showModalLoader &&
						<Button
							disabled={!state.hasDifferences && state.isUpdate}
							className={clsx(classes.actionButtons, classes.saveButton)}
							type="submit"
							variant="outlined"
							color="primary"
						>
							GUARDAR
					</Button>}
					{state.showModalLoader && <CircularProgress />}
					<Button
						className={classes.actionButtons}
						type="button"
						variant="outlined"
						onClick={handleCancelForm}
					>
						CANCELAR
					</Button>
				</CardActions>
			</Card>
		</form>
	);
};

export default CustomEmployeeForm;

export default {
	setHasDifferences: 'SET_HAS_DIFFERENCES',
	setDifferences: 'SET_DIFFERENCES',
	setIsPageReady: 'SET_IS_PAGE_READY',
	showLoader: 'SHOW_LOADER',
	showModalLoader: 'SHOW_MODAL_LOADER',
	setEmployees: 'SET_EMPLOYEES',
	setFilters: 'SET_FILTERS',
	setTerm: 'SET_TERM',
	setFormat: 'SET_FORMAT',
	setResults: 'SET_RESULTS',
	setFormData: 'SET_FORM_DATA',
	setRegions: 'SET_REGIONS',
	setTotal: 'SET_TOTAL',
	setAffiliations: 'SET_AFFILIATIONS',
	setFilterAffiliations: 'SET_FILTER_AFFILIATIONS',
	setCategories: 'SET_CATEGORIES',
	setDeleteMessage: 'SET_DELETE_MESSAGE',
	showDialog: 'SHOW_DIALOG',
	setCurrentRow: 'SET_CURRENT_ROW',
	showAlert: 'SHOW_ALERT',
	showCreateFrom: 'SHOW_CREATE_FORM',
	clearFilters: 'CLEAR_FILTERS',
	disableFormSubmit: 'DISABLE_FORM_SUBMIT',
	changeInputType: 'CHANGE_INPUT_TYPE',
	setEmployeeData: 'SET_EMPLOYEE_DATA',
	clearFormData: 'CLEAR_FORM_DATA',
	clearOriginalData: 'CLEAR_ORIGINAL_DATA',
	setEmployeeId: 'SET_EMPLOYEE_ID',
	setEmployeeToRemove: 'SET_EMPLOYEE_ID_REMOVE',
	setFirstRender: 'SET_FIRST_RENDER',
	setTotalPages: 'SET_TOTAL_PAGES',
	setPages: 'SET_PAGES',
	setCurrentPage: 'SET_CURRENT_PAGE',
	setForm: 'SET_FORM',
	setOriginalData: 'SET_ORIGINAL_DATA',
	showModalDelete: 'SHOW_MODAL_DELETE',
	setFormItem: 'SET_FORM_ITEM',
	showForm: 'SHOW_FORM',
	clearTerm: 'CLEAR_TERMs',
	setIsUpdating: 'SET_IS_UPDATING',
	setFieldRequired: 'SET_FIELD_REQUIRED',
	resetFilters: 'RESET_FILTERS',
	setAlertProps: 'SET_ALERT_PROPS',
	setSearchType: 'SET_SEARCH_TYPE'
};

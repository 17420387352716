import React from 'react';
import AppBar from './AppBar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	divContainerComponent: {
		marginTop: 110,
		[theme.breakpoints.down('sm')]: {
			marginTop: 65,
		},
	},
}))

export const Layout = ({ component, ...props }) => {
	const classes = useStyles();

	return (
		<div>
			<AppBar />
			<div className={classes.divContainerComponent}>
				{component}
			</div>
		</div>
	)
}

export default Layout
const onlyLetterPatter = /^[ñA-Za-z _]*[ñÑA-Za-z][ñA-Za-z _]*$/;

module.exports = {
	validations: {
		enrollment: {
			required: true
		},
		first_name: {
			required: true,
			validator: {
				regEx: onlyLetterPatter,
				error: 'Solo de admiten letras.'
			}
		},
		last_name_f: {
			required: true,
			validator: {
				regEx: onlyLetterPatter,
				error: 'Solo de admiten letras.'
			}
		},
		last_name_m: {
			required: true,
			validator: {
				regEx: onlyLetterPatter,
				error: 'Solo de admiten letras.'
			}
		},
		birthday: {
			required: true
		},
		region: {
			required: true
		},
		unconditional: {
			required: false
		},
		relationship: {
			required: true
		},
		other_relationship: {
			required: false,
			validator: {
				regEx: onlyLetterPatter,
				error: 'Solo de admiten letras.'
			}
		},
		level_of_study: {
			required: true
		},
		documents: {
			required: true
		},
		is_studying: {
			required: false
		},
		career: {
			required: false
		},
		telephone: {
			required: false
		},
		cell_phone: {
			required: true
		},
		category: {
			required: true
		},
		has_been_evaluated: {
			required: false
		},
		evaluation_date: {
			required: false
		},
		category_requested: {
			required: false
		},
		result: {
			required: false
		},
		observations: {
			required: false
		}
	},
	form: {
		id: {
			value: 0,
			error: ''
		},
		enrollment: {
			value: '',
			error: ''
		},
		first_name: {
			value: '',
			error: ''
		},
		last_name_f: {
			value: '',
			error: ''
		},
		last_name_m: {
			value: '',
			error: ''
		},
		birthday: {
			value: (new Date()),
			error: ''
		},
		region: {
			value: 0,
			error: ''
		},
		unconditional: {
			value: false,
			error: ''
		},
		relationship: {
			value: 0,
			error: ''
		},
		other_relationship: {
			value: '',
			error: ''
		},
		scholarship: {
			value: 0,
			error: ''
		},
		level_of_study: {
			value: 0,
			error: ''
		},
		documents: {
			value: 0,
			error: ''
		},
		is_studying: {
			value: false,
			error: ''
		},
		career: {
			value: '',
			error: ''
		},
		telephone: {
			value: '',
			error: ''
		},
		cell_phone: {
			value: '',
			error: ''
		},
		category: {
			value: 0,
			error: ''
		},
		has_been_evaluated: {
			value: false,
			error: ''
		},
		evaluation_date: {
			value: '',
			error: ''
		},
		category_requested: {
			value: 0,
			error: ''
		},
		result: {
			value: 0,
			error: ''
		},
		observations: {
			value: '',
			error: ''
		},
		selectedRequirement: {
			value: 0,
			error: ''
		},
		requirements_associated: {
			value: [],
			error: ''
		}
	},
	regions: [],
	affiliations: [],
	categories: [],
	documents: [],
	results: [
		{id: 1, name: 'SELECCIONADO'},
		{id: 2, name: 'NO SELECCIONADO'},
		{id: 3, name: 'RESERVA'}],
	orderBy: [
		{
			id: 0,
			name: 'Default'
		},
		{
			id: 1,
			name: 'Antiguedad Mayor > a Menor <'
		},
		{
			id: 2,
			name: 'Antiguedad Menor < a Mayor >'
		},
		{
			id: 3,
			name: 'Nombre A-Z'
		},
		{
			id: 4,
			name: 'Nombre Z-A'
		},
		{
			id: 5,
			name: 'Categoria A-Z'
		},
		{
			id: 6,
			name: 'Categoria Z-A'
		},
		{
			id: 8,
			name: 'En Requerimiento'
		}
	]
};

import React, { useReducer, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Link, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Loader from 'components/CustomLoader';
import Logo from './logo2.png';
import Button from '@material-ui/core/Button';
import redirectTo from '../../utils/redirectTo';
import { useAuthDataContext } from '../../context/AuthContext';
import signInReducer from './reducer';
import actions from './actions';
import { AuthService } from '../../services/api/auth'
import './SignIn.css';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: '15px 0px 0px 0px'
		}
	},
	eyeIcon: {
		cursor: 'pointer'
	},
	emailLink: {
		color: '#00aab4'
	},
	paragraphError: {
		margin: 0
	},
	logo: {
		width: 200
	},
	buttonLogin: {
		'&:hover': {
			border: '1px solid #3fb574',
			backgroundColor: 'rgba(42, 175, 107, 0.13)'
		},
		'color': '#317745',
		'border': '1px solid rgba(63, 181, 154, 0.5)'
	}
}));

const SignIn = () => {
	const [snackError, setSnackError] = useState({
		show: false,
		message: ''
	});
	const { saveUser, isAuthenticated, user } = useAuthDataContext();
	const classes = useStyles();
	const initialState = {
		credentials: {
			email: '',
			password: ''
		},
		error: {
			email: {
				message: ''
			},
			password: {
				message: ''
			}
		},
		passwordInputType: 'password',
		showLoader: false,
		invalidEmail: false,
		emailNotFound: false,
		wrongPassword: false,
		apiError: false,
		hasErrorForm: false,
		openToast: false
	};
	const [state, dispatch] = useReducer(signInReducer, initialState);
	const {
		showLoader,
		passwordInputType,
		invalidEmail,
		emailNotFound,
		wrongPassword,
		apiError,
		error,
		credentials: { email, password }
	} = state;
	const handleChange = (e) => {
		const { id, value } = e.target;

		if (id === 'password' && value === '') {
			dispatch({ type: actions.wrongPassword, value: false });
		}
		if (id === 'email' && value === '') {
			dispatch({ type: actions.emailNotFound, value: false });
			dispatch({ type: actions.showInvalidEmail, value: false });
		}

		dispatch({
			type: actions.storeCredentials,
			value: {
				key: id,
				value: value
			}
		});
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch({ type: actions.clearErrors });
		dispatch({ type: actions.showLoader, value: true });
		AuthService.login(state.credentials)
			.then((response) => {
				if (response.error) {
					setSnackError({
						show: true,
						message: response.error
					});
					dispatch({ type: actions.showLoader, value: false });
				}
				if (response.data) {
					saveUser(response.data)
				}
			})
			.catch(error => {
				console.log(error)
				setSnackError({
					show: true,
					message: 'Something went wrong, please try again...'
				});
				dispatch({ type: actions.showLoader, value: false });
			});
	};
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackError({
			show: false,
			message: ''
		});
	};

	// Render redirect component in case there is a user session, if not then render the original component.
	// This is only in the components that you want to redirect if there is a session
	return isAuthenticated ?
		<Redirect to={redirectTo(user.role)} /> :
		(
			<div className="sign-in-container">
				<div className="sign-in-form-container">
					<div className="sign-in-form">
						<div className="sign-in-logo">
							<img src={Logo} alt="" className={classes.logo} />
						</div>
						<span>SNTSS SECCIÓN XII</span>
						<form
							className={classes.root}
							noValidate
							onSubmit={handleSubmit}
							autoComplete="off">
							<TextField
								fullWidth
								type="email"
								error={emailNotFound || invalidEmail}
								id="email"
								onChange={handleChange}
								label="Usuario"
								value={state.credentials.email}
								helperText={error.email.message}
								variant="outlined" />
							<TextField
								fullWidth
								error={wrongPassword}
								id="password"
								type={passwordInputType}
								onChange={handleChange}
								InputProps={{
									endAdornment: (
										<InputAdornment
											position="end"
											onClick={() => dispatch({ type: 'CHANGE_INPUT_TYPE', value: (passwordInputType === 'password' ? 'text' : 'password') })}
											className={classes.eyeIcon}>
											{passwordInputType === 'password' ? <Visibility /> : <VisibilityOff />}
										</InputAdornment>
									)
								}}
								helperText={error.password.message}
								label="Contraseña"
								value={state.credentials.password}
								variant="outlined" />
							<Button
								size="large"
								type="submit"
								disabled={email === '' || password === '' || state.showLoader || state.hasErrorForm}
								fullWidth
								color="primary"
								className={classes.buttonLogin}
								variant="outlined">
								INICIAR SESIÓN
							</Button>
							{showLoader && <Loader fullWidth />}
							{/* <p className="forgot-password-link">
								<Link href="/reset">Olvidaste tu contraseña?</Link>
							</p>*/}
							<Snackbar open={apiError} autoHideDuration={10000} onClose={handleClose}>
								<Alert onClose={handleClose} severity="warning">
									<p className={classes.paragraphError}>
										Lo sentimos, estamos teniendo problemas para conectarte a nuestro sitio en este momento.
									Por favor contáctenos en <Link className={classes.emailLink} href="mailto:info@siatss.com" target="_top">info@siatss.com </Link> para asegurarnos de avisarle cuando las cosas vuelvan a funcionar normalmente.
									</p>
								</Alert>
							</Snackbar>
							<Snackbar open={snackError.show} autoHideDuration={3000} onClose={handleClose}>
								<Alert onClose={handleClose} severity="warning">
									<p className={classes.paragraphError}>
										{snackError.message}
									</p>
								</Alert>
							</Snackbar>
						</form>
					</div>
				</div>
			</div>
		);
};

export default SignIn;

import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: '#41d03f6e'
    },
    noSelected: {
        backgroundColor: '#ff05055e'
    },
    reserve: {
        backgroundColor: '#eff30c73'
    },
    undefined: {
        backgroundColor: '#ff050500'
    }
}));

export const SelectResult = ({ requirement, option, onChange }) => {
    const classes = useStyles();
    const [result, setResult] = useState(option || 0);

    const handleChange = (event) => {
        const res = event.target.value;
        setResult(res);
        onChange(requirement, res);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="result">Resultado</InputLabel>
            <Select
                labelId="label-result"
                id="id-label-result"
                value={result}
                onChange={handleChange}
                className={clsx([{
                    [classes.undefined]: result === 0,
                    [classes.selected]: result === 1,
                    [classes.noSelected]: result === 2,
                    [classes.reserve]: result === 3,
                }])}
            >
                <MenuItem value={0}>Sin resultado aun </MenuItem>
                <MenuItem class={classes.selected} value={1}>Seleccionado</MenuItem>
                <MenuItem class={classes.noSelected} value={2}>No Seleccinado</MenuItem>
                <MenuItem class={classes.reserve} value={3}>Reserva</MenuItem>
            </Select>
        </FormControl>
    )
}

export default SelectResult
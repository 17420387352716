import actions from './actions';

const form =  {
	enrollment: '',
	first_name: '',
	last_name_f: '',
	last_name_m: '',
	birthday: null,
	region: 0,
	unconditional: false,
	relationship: 0,
	other_relationship: '',
	level_of_study: 0,
	documents: 0,
	is_studying: false,
	career: '',
	cell_phone: '',
	category: '',
	has_been_evaluated: false,
	evaluation_date: null,
	category_requested: null,
	result: null,
	observations: null
};

export const initialState = {
	hasDifferences: false,
	differences: [],
	isUpdate: false,
	showLoader: false,
	showModalLoader: false,
	form: form,
	originalData: form,
};

const reducer = (state, action) => {
	switch (action.type) {
	case actions.showLoader: {
		return {
			...state,
			showLoader: action.payload
		}
	}
	case actions.setFormField: {
		return {
			...state,
			form: {
				...state.form,
				[action.payload.key]: action.payload.value
			}
		}
	}
	case actions.setForm: {
		return {
			...state,
			form: action.payload
		}
	}
	case actions.setIsUpdate: {
		return {
			...state,
			isUpdate: action.payload
		}
	}
	case actions.clearFormData: {
		return {
			...state,
			form: form,
		};
	}
	case actions.setHasDifferences: {
		return {
			...state,
			hasDifferences: action.payload
		};
	}
	case actions.setDifferences: {
		return {
			...state,
			differences: action.payload
		};
	}
	case actions.showModalLoader: {
		return {
			...state,
			showModalLoader: action.payload
		};
	}
	case actions.setOriginalData: {
		return {
			...state,
			originalData: action.payload
		};
	}

	default: {
		return {
			...state
		}
	}
	}
};

export default reducer;

import { get, post, put, destroy } from 'config/api'

export const EmployeeService = {
    index: (status, page) => get(`/employees?status=${status}&page=${page}`),
    search: (params) => get(`/employees${params}`),
    fetch: (id) => get(`/employees/${id}`),
    create: (data) => post('/employees', data),
    update: (id, data) => put(`/employees/${id}`, data),
    remove: (id) => destroy(`/employees/${id}`),
    getRegions: () => get('/regions'),
    getAffiliations: () => get('/affiliations'),
    getCategories: () => get('/categories'),
    getPages: (status) => get(`/employees/pages?status=${status}`)
}
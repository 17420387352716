export default {
	setPageReady: 'SET_PAGE_READY',
	setFilters: 'SET_FILTERS',
	setPage: 'SET_PAGES',
	setCurrentPage: 'SET_CURRENT_PAGE',
	setSearchBox: 'SET_SEARCH_BOX',
	setFormat: 'SET_FORMAT',
	setIsFetching: 'SET_IS_FETCHING',
	setIsUpdating: 'SET_IS_UPDATING',
	setForm: 'SET_FORM',
	setFormItem: 'SET_FORM_ITEM',
	setValidations: 'SET_VALIDATIONS',
	setAffiliations: 'SET_AFFILIATIONS',
	setCandidates: 'SET_CANDIDATES',
	setCandidateId: 'SET_CANDIDATE_ID',
	setCategories: 'SET_CATEGORIES',
	setDocuments: 'SET_DOCUMENTS',
	setRegions: 'SET_REGIONS',
	setRequirements: 'SET_REQUIREMENTS',
	setScholarships: 'SET_SCHOLARSHIPS',
	setRelationships: 'SET_RELATIONSHIPS',
	setRelationshipOrder: 'SET_RELATIONSHIP_ORDER',
	setResults: 'SET_RESULTS',
	showForm: 'SHOW_FORM',
	showLoader: 'SHOW_LOADER',
	showModalLoader: 'SHOW_MODAL_LOADER',
	clearTerm: 'CLEAR_TERM',
	resetFilters: 'RESET_FILTERS',
	setAlertProps: 'SET_ALERT_PROPS',
	showModalDelete: 'SHOW_MODAL_DELETE',
	showModalRequirement: 'SHOW_MODAL_REQUIREMENT',
	setCandidateToRemove: 'SET_CANDIDATE_TO_REMOVE',
	setCandidateToAssoc: 'SET_CANDIDATE_TO_ASSOC',
	setRequirementToAdd: 'SET_REQUIREMENT_TO_ADD',
	setSearchType: 'SET_SEARCH_TYPE'
};

import lodash from 'lodash';

function deepDiffMapper(a = {}, b = {}, exclude = []) {
	let aProps = Object.getOwnPropertyNames(a);
	let bProps = Object.getOwnPropertyNames(b);

	aProps = aProps.filter(key => ! exclude.includes(key));
	bProps = bProps.filter(key => ! exclude.includes(key));

	let fieldsUpdated = [];

	if (aProps.length !== bProps.length) {
		let missingFields = aProps.filter(prop => !bProps.includes(prop));

		if (missingFields.length > 0) {
			fieldsUpdated.concat(missingFields);
		} else {
			return fieldsUpdated;
		}
	}

	for (let i = 0; i < aProps.length; i++) {
		let propName = aProps[i];

		if (typeof a[propName] === "object") {
			if(!lodash.isEqual(a[propName], b[propName])) {
				fieldsUpdated.push(propName);
			}
		}else {
			if (a[propName] !== b[propName]) {
				fieldsUpdated.push(propName);
			}
		}
	}

	return fieldsUpdated;
}

export default deepDiffMapper;

export default {
	storeCredentials: 'STORE_CREDENTIALS',
	clearCredentials: 'ClEAR_CREDENTIALS',
	emailNotFound: 'USER_NOT_FOUND',
	wrongPassword: 'BAD_CREDENTIALS',
	showInvalidEmail: 'SHOW_INVALID_EMAIL',
	showApiError: 'SHOW_API_ERROR',
	clearErrors: 'CLEAR_ERRORS',
	disableFormSubmit: 'DISABLE_FORM_SUBMIT',
	showLoader: 'SHOW_LOADER',
	changeInputType: 'CHANGE_INPUT_TYPE'
}

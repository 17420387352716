module.exports = {
	VARIABLES: {
		EMPLOYEES: 'Trabajadores ',
		EMPLOYEE: 'Trabajador',
		CANDIDATES: 'Aspirantes ',
		USERS: 'Usuarios',
		REGION: 'Región',
		CATEGORY: 'Categoría',
		RELATIONSHIP: 'Parentesco',
		RELATIONSHIP_TYPE: 'Tipo de parentesco',
		IS_STUDYING: 'Estudia actualmente?',
		CAREER: 'Curso / Carrera',
		HAS_BEEN_EVALUATED: 'Ya se ha evaluado anteriormente?',
		EVALUATION_DATE: 'Fecha de evaluación',
		AFFILIATION: 'Adscripción',
		SENIORITY: 'Antigüedad',
		ORDER_BY: 'Ordenar por',
		ENROLLMENT: 'Matrícula',
		ID: 'ID',
		NAME: 'Nombre',
		LAST_NAMES: 'Apellidos',
		LAST_NAME_F: 'Apellido Paterno',
		LAST_NAME_M: 'Apellido Materno',
		PASSWORD: 'Contraseña',
		BIRTHDAY: 'Fecha de nacimiento',
		MEMBERSHIP: 'Membresía',
		EMAIL: 'Email',
		TELEPHONE: 'Teléfono',
		CELL_PHONE: 'Celular',
		CATEGORY_REQUESTED: 'Categoría',
		OBSERVATIONS: 'Antecedentes',
		YEARS: 'Años',
		FORTNIGHTS: 'Quincenas',
		DAYS: 'Dias',
		OPTIONS: 'Opciones',
		STATUS: 'Status',
		ROLE: 'Rol',
		DOCUMENTS: 'Documento de acreditación',
		LEVEL_OF_STUDY: 'Escolaridad',
		RESULT: 'Resultado'
	},
	MESSAGES: {
		FILL_FIELD: 'Por favor llenar este campo',
		RESULTS_NOT_FOUND: 'No se encontraron resultados.',
		CANCEL: 'Cancelar',
		CREATE: 'Crear',
		CREATING: 'Creando...',
		UPDATE: 'Guardar',
		UPDATING: 'Guardando...',
		LIST_OF_REQUIREMENTS: 'Lista de requerimientos asociados',
		PASSWORD_UPDATE_TOOLTIP: 'Al actualizar información la contraseña no es requerida, pero si se ingresa una contraseña nueva esta se actualizará!'
	}
};

import React, {createContext, useContext} from 'react';
import useFetch from '../hooks/useFetch';
import {useAuthDataContext} from './AuthContext';

export const UserDataContext = createContext(null);

const UserDataProvider = (props) => {
	const {token} = useAuthDataContext();
	const userApi = useFetch(
		process.env.REACT_APP_API_URL,
		token
	);

	/**
	 *
	 * @return {Promise<{message: string, status: string}>}
	 * @param {object} payload
	 */
	async function retrieveUserList(payload) {
		try {
			return await userApi.get('/user/list');
		} catch (e) {
			return {
				status: 'error',
				message: 'Lo sentimos, estamos teniendo problemas para conectarte a nuestro sitio en este momento. Por favor contáctenos en info@siatss.com para asegurarnos de avisarle cuando las cosas vuelvan a funcionar normalmente.'
			};
		}
	}

	/**
	 *
	 * @return {Promise<{message: string, status: string}>}
	 * @param {object} id
	 */
	async function retrieveUserData(id) {
		try {
			return await userApi.get(`/user/fetch/${id}`);
		} catch (e) {
			return {
				status: 'error',
				message: 'Lo sentimos, estamos teniendo problemas para conectarte a nuestro sitio en este momento. Por favor contáctenos en info@siatss.com para asegurarnos de avisarle cuando las cosas vuelvan a funcionar normalmente.'
			};
		}
	}

	/**
	 *
	 * @param {object} payload
	 * @return {Promise<{message: string, status: string}>}
	 */
	async function createUser(payload) {
		try {
			return await userApi.post('/user', payload);
		} catch (e) {
			return {
				status: 'error',
				message: 'Lo sentimos, estamos teniendo problemas para conectarte a nuestro sitio en este momento. Por favor contáctenos en info@siatss.com para asegurarnos de avisarle cuando las cosas vuelvan a funcionar normalmente.'
			};
		}
	}

	async function updateUser(id, form) {
		try {
			return await userApi.put(`/user/${id}`, form);
		} catch (e) {
			return {
				status: 'error',
				message: 'Lo sentimos, estamos teniendo problemas para conectarte a nuestro sitio en este momento. Por favor contáctenos en info@siatss.com para asegurarnos de avisarle cuando las cosas vuelvan a funcionar normalmente.'
			};
		}
	}
	async function deleteUser(id) {
		try {
			return await userApi.remove(`/user/${id}`);
		} catch (e) {
			return {
				status: 'error',
				message: 'Lo sentimos, estamos teniendo problemas para conectarte a nuestro sitio en este momento. Por favor contáctenos en info@siatss.com para asegurarnos de avisarle cuando las cosas vuelvan a funcionar normalmente.'
			};
		}
	}

	return <UserDataContext.Provider
		value={{
			retrieveUserList,
			retrieveUserData,
			createUser,
			updateUser,
			deleteUser
		}} {...props} />;
};

export const useUserDataContext = () => useContext(UserDataContext);

export default UserDataProvider;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	filtersRoot: {
		'& .MuiTextField-root': {
			marginRight: theme.spacing(1),
			width: '30ch',
			textTransform: 'uppercase'
		}
	},
	filters: {
		marginTop: 10,
		marginBottom: 10,
	},
	margin: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	title: {
		color: '#1a1c20ab',
		fontWeight: 'bold'
	},
	autocomplete: {
		display: 'contents',
		'& .MuiTextField-root': {
			width: '27ch',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				margin: 5
			},
		},
		'& .MuiInputBase-input': {
			textTransform: 'uppercase',
		},
	},
	pagination: {
		textAlign: 'end',
		marginTop: 10,
		marginBottom: 10,
		'& .MuiPagination-root': {
			display: 'inline-grid'
		}
	},
	employeeName: {
		textTransform: 'capitalize',
		fontWeight: 'bold',
		fontStyle: 'italic'
	},
	uppercase: {
		textTransform: 'uppercase'
	},
	inRequirement: {
		borderLeft: '25px solid #00b44f',
	},
	notInRequirement: {
		borderLeft: '25px solid white'
	},
	categoryLimited: {
		borderRight: '25px solid red',
	},
	uppercaseText: {
		textTransform: 'uppercase'
	},
	hide: {
		display: 'none'
	},
	categoryLimitedText: {
		color: '#fc2003 !important'
	}
}));

export default useStyles;

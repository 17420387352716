import React, { createContext, useState, useEffect, useContext } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import * as Cookies from 'js-cookie';
//import socketClient from 'socket.io-client';
import Loader from '../components/CustomLoader';

export const AuthDataContext = createContext(null);

const AuthDataProvider = (props) => {
	const [userData, setUserData] = useLocalStorage('session', null, useState);
	const [isFirstAttempt, setFirstAttempt] = useState(true);
	const [authData, setAuthData] = useState({});
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	//const [socket, setSocket] = useState(null);
	/* The first time the component is rendered, it tries to
	 * fetch the auth data from a source, like a cookie or
	 * the localStorage.
	 */
	useEffect(() => {
		//let sock = socketClient(process.env.REACT_APP_API_URL);
		//setSocket(sock);
		setFirstAttempt(false);
		if (userData && userData.hasOwnProperty('tokenData')) {
			window.localStorage.removeItem('session');
		}
		if (userData && userData.accessToken) {
			setIsAuthenticated(true);
			setAuthData(userData);
		} else {
			setIsAuthenticated(false);
			setAuthData({});
		}

		if (userData) {
			//sock.emit('initialize', userData.tokenData);
		}

		return () => setFirstAttempt(true);
	}, [userData]);

	const onLogout = () => {
		Cookies.remove('session');
		setUserData(null);
	};

	async function saveUser(data) {
		setUserData(data);
		Cookies.set('session', data, { expires: 14 });
	}

	if (isFirstAttempt) {
		return (
			<div className="loader">
				<Loader />
			</div>
		);
	}

	return <AuthDataContext.Provider
		value={{
			...authData,
			isAuthenticated,
			onLogout,
			saveUser,
			//socket
		}} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
